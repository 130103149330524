import React from 'react';
import styled from 'styled-components';
import * as FontAwesome from 'react-icons/fa';

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    background: #eee;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  margin: 0;
  margin-left: 20px;

`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

interface Props {
  icon: string;
  text: string;
  onClick: () => void;
}

const IconButton: React.FC<Props> = ({
  icon,
  text,
  onClick
}) => {
  const fa = FontAwesome as any;
  const FAIcon = fa[icon] || fa['FaRegQuestionCircle'];

  return (
    <Container onClick={onClick}>
      <Icon><FAIcon/></Icon>
      <Text>{ text }</Text>
    </Container>
  )
}

export default IconButton;