import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Credit } from "../../Types/credits";
import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";
import toDateString from "../../helpers/toDateString";
import "../../Styles/Tables.css";
import TitleBox from "../../Components/Containers/TitleBox";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage"
import { getCreditsWhere } from "../../Firebase/credits";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Text = styled.p``;

// User Information - 
    // email, name, create date, 
    // Receipts - name, date, credit - (played/limit)
    // then balance
    // add a game (creates receipt)

// Email
//   Authenticate on app

interface User {
  id: string;
  email: string;
  created: number;
}

const UserScreen: React.FC = () => {
  const { state } = useLocation<User>();
  const flash = useFlashMessage();
  const [credits, setCredits] = useState<null|Credit[]>(null);
  const [loadingCredits, setLoadingCredits] = useState(true);

  useEffect(() => {
    async function getCredits(){
      const res = await getCreditsWhere("user", state.id);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        setCredits(res.data);
      }
      setLoadingCredits(false);
    }

    getCredits();
  }, []);

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Header title="User" />
      <Body>
        <TitleBox title="User Information">
          <FormSection>
            <FormExplanation title="Email" text="" />
            <Text>{ state.email }</Text>
          </FormSection>
          <FormSection>
          <FormExplanation title="Created" text="" />
            <Text>{ toDateString(state.created) }</Text>
          </FormSection>
        </TitleBox>
        <TitleBox title="Games">
          
        </TitleBox>
        <TitleBox title="">

        </TitleBox>
      </Body>
    </Container>
  )
}

export default UserScreen;