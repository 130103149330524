import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Game } from "../Types/games";

import EditInfoScreen from "../Screens/Games/EditInfoScreen";
import EditThemeScreen from "../Screens/Games/EditThemeScreen";
import EditCluesScreen from "../Screens/Games/EditCluesScreen";
import EditEndScreen from "../Screens/Games/EditEndScreen";
import EditSettingsScreen from "../Screens/Games/EditSettingsScreen";
import EditAppStoreScreen from '../Screens/Games/EditAppStoreScreen';

interface Props {
  dispatch: React.Dispatch<any>;
  game: Game;
}

const GameEditNavigator: React.FC<Props> = ({ game, dispatch }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact 
        path={`${path}/main-information`}
        render={() => <EditInfoScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/theme`}
        render={() => <EditThemeScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/puzzles/:clueId`} 
        render={() => <EditCluesScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/puzzles`} 
        render={() => <EditCluesScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/conclusion`} 
        render={() => <EditEndScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/settings`} 
        render={() => <EditSettingsScreen dispatch={dispatch} game={game} />}
      />
      <Route 
        path={`${path}/appstore`} 
        render={() => <EditAppStoreScreen dispatch={dispatch} game={game} />}
      />
    </Switch>
  )
}

export default GameEditNavigator;
