import React from "react";
import styled, { keyframes } from "styled-components";
import Icon from "../Icons/Icon";

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`

`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  animation: ${rotate} 1s linear infinite;
`;

interface Props {

}

const Loading:React.FC<Props> = () => {
  return (
    <Container>
      <IconWrapper>
        <Icon name="loading" size={24} color="white"/>
      </IconWrapper>
    </Container>
  )
}

export default Loading;