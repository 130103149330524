import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Clue } from "../../../Types/games";
import { clue as defaultClue } from "../../../config/defaultGame";
import { addToStorage, addToStorage2 as addToStorageLocation } from "../../../Firebase/helpers";
import { updateClue } from "../../../Firebase/games";

import TitleBox from "../../Containers/TitleBox";
import GadgetConfig from "../GadgetConfig/GadgetConfig";
import Select from "../Select";
import Input from "../Input";
import FormSection from "../FormSection";
import FormExplanation from "../FormExplanation";
import FileDrop from "../FileDrop";
import VideoDrop from "../VideoDrop";
import LocationPicker from "../../Maps/LocationPicker";
import TextArea from "../TextArea";
import MultiInput from "../MultiInput";
import SolutionsInput from "../SolutionsInput";
import CheckBox from "../CheckBox";
import RadioInput from "../RadioInput";
import BottomBar from "../BottomBar";
import DeleteItem from "../DeleteItem";
import Warning from "../../Containers/Warning";

import FullWidthMessage from '../../Containers/FullWidthMessage';

const gadgetOptions = [
  { value:"input", name: "input"}, 
  { value:"keypad", name: "keypad"},
  { value:"padlock", name: "padlock"},
  { value: "safelock", name: "safelock" },
  { value: "switch", name: "switch" },
  // { value: "compass", name: "compass" },
  // { value: "decoder", name: "decoder" },
  // { value: "morsecode", name: "morsecode" },
  // { value: "telephone", name: "telephone" },
]

const slideIn = keyframes`
  from { tranform: translateX(-100px); }
  to { transform: transateX(0px); }
`;

const Container = styled.div`
  display: ${props => props.hidden ? "none" : "block"};
  animation: slideIn 0.3s forwards;
`;

interface LooseObject {
  [key: string]: any
}

interface Props {
  showFlashMessage: (text: string, error: boolean) => void;
  dispatch: React.Dispatch<any>,
  gameId: string;
  hidden: boolean;
  clue: Clue;
  deleteClue: () => void;
  clueNames: {
    value: string;
    name: string;
  }[]
}

const EditClueForm: React.FC<Props> = ({
  showFlashMessage,
  gameId,
  hidden,
  dispatch,
  clue,
  deleteClue,
  clueNames
}) => {
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState(clue);

  const [showOnStart, setShowOnStart] = useState(clue.showOnStart);
  const [winCondition, setWinCondition] = useState(clue.winCondition);
  const [title, setTitle] = useState(clue.title);
  const [text, setText] = useState(clue.text);
  const [directions, setDirections] = useState(clue.directions);
  const [location, setLocation] = useState(clue.location);
  const [coords, setCoords] = useState(clue.coords);

  const [imageType, setImageType] = useState(clue.audio ? "audio" : (clue.video ? "video" : "image"));
  const [image, setImage] = useState(clue.img);
  const [imageSmall, setImageSmall] = useState(clue.imageSmall || null);
  const [imageFile, setImageFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [video, setVideo] = useState(clue.videoUrl);
  const [audioFile, setAudioFile] = useState<File>();
  const [audio, setAudio] = useState(clue.audio);
  
  const [component, setComponent] = useState(clue.component);
  const [solutions, setSolutions] = useState(clue.solutions);
  const [hints, setHints] = useState(clue.hints || []);
  const [explanation, setExplanation] = useState(clue.explanation);

  const [useCutScene, setUseCutScene] = useState(clue.cutSceneUrl != null);
  const [cutScene, setCutScene] = useState(clue.cutSceneUrl);
  const [cutSceneFile, setCutSceneFile] = useState<File>();

  const unfinished = clue.solutions.filter((solution: any) => solution.next.length === 0).length > 0 && !clue.winCondition;

  async function addFilesToStorage(){
    const data: LooseObject = {
      showOnStart,
      title,
      text,
      directions,
      location,
      coords,
      component,
      solutions,
      hints,
      explanation,
      winCondition
    }

    if(imageType === "image"){
      data.audio = null;
      data.video = null;
    }

    if((imageType === "image" || imageType === "audio") && imageFile){
      const url = `${gameId}/clues/${clue.id}/image`;
      const res = await addToStorageLocation(imageFile, url);
      if(!res.error){
        data.image = url;
        data.img = res;
        data.imageSmall = imageSmall;
        data.video = null;
      }
    }
    
    if(imageType === "audio" && audioFile){
      const url = `${gameId}/clues/${clue.id}/audio`;
      const res = await addToStorageLocation(audioFile, url);
      if(!res.error){
        data.audioUrl = res;
        data.audio = url;
        data.video = null;
      }
    }
    
    if(imageType === "video" && videoFile){
      const url = `${gameId}/clues/${clue.id}/video`;
      const res = await addToStorageLocation(videoFile, url);
      if(!res.error){
        data.videoUrl = res; // downloadUrl
        data.video = url;
        data.audio = null;
        data.image = null;
      }
    }
    
    if(useCutScene){
      if(cutSceneFile){
        const url = `${gameId}/clues/${clue.id}/cutscene`;
        const res = await addToStorageLocation(cutSceneFile, url);
        if(!res.error){
          data.cutScene = url;
          data.cutSceneUrl = res;
        }
      } 
    } else {
      data.cutScene = null;
      data.cutSceneUrl = null;
    }

    return data;
  }

  function handleCancel(){
    setShowOnStart(initialState.showOnStart);
    setWinCondition(initialState.winCondition);
    setTitle(initialState.title);
    setText(initialState.text);
    setDirections(initialState.directions);
    setLocation(initialState.location);
    setCoords(initialState.coords);
    setImageType(initialState.audio ? "audio" : (initialState.video ? "video" : "image"));
    setImage(initialState.image);
    setImageFile(undefined);
    setVideoFile(undefined);
    setVideo(initialState.video);
    setAudioFile(undefined);
    setAudio(initialState.audio);
    setComponent(initialState.component);
    setSolutions(initialState.solutions);
    setHints(initialState.hints);
    setExplanation(initialState.explanation);
    setUseCutScene(initialState.cutSceneUrl != null);
    setCutScene(initialState.cutSceneUrl);
    setCutSceneFile(undefined);
  }

  async function _submit(){
    setLoading(true);
    const data = await addFilesToStorage();
    const res = await updateClue(gameId, clue.id, data);
    if(res.error){
      showFlashMessage(res.message, true);
    } else {
      showFlashMessage("Clue successfully updated", false);
      dispatch({ type: "UPDATE_CLUE", payload: res.payload });
    }
    setLoading(false);
  }

  return (
    <Container hidden={hidden}>
      <TitleBox title="Puzzle Information" >
        <FormSection>
          <FormExplanation title="Name" text="Enter the puzzle name" />
          <Input value={title} onChange={setTitle} />
        </FormSection>
      </TitleBox>

      <TitleBox title="Location Information">
        <FormSection>
          <FormExplanation title="Location Name" text="Enter the location of the puzzle such as a building or road name" />
          <Input value={location} onChange={setLocation} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Directions" text="Describe how to get to the puzzle and/or orient the player at the location." />
          <TextArea rows={3} value={directions} onChange={setDirections} />
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Coordinates" text="Choose the location of the puzzle by clicking on the map." />
          <LocationPicker coords={coords} setCoords={setCoords} />
        </FormSection>
      </TitleBox>

      <TitleBox title="Puzzle Content">
        <>
        <FormSection>
          <FormExplanation title="Type" text="Choose what you want to show the player. This will be shown at the top of the puzzle page." />
          <RadioInput value={imageType} onChange={setImageType} options={{ image: "image", video: "video", "audio & image": "audio" }} />
        </FormSection>
        {
          imageType === "video"
          ? <FormSection>
              <FormExplanation title="Video" text="Drop the video for the puzzle here." />
              <FileDrop value={video} file={videoFile} setFile={setVideoFile} type="video" />
            </FormSection>
          : <FormSection>
              <FormExplanation title="Image" text="Drop the image for the puzzle here." />
              <FileDrop value={image} file={imageFile} setFile={setImageFile} setBase64={setImageSmall}/>
            </FormSection>
        }
        {
          imageType === "audio" &&
          <FormSection>
            <FormExplanation title="Audio" text="Drop the audio for the puzzle here." />
            <FileDrop value={audio} file={audioFile} setFile={setAudioFile} type='audio'/>
          </FormSection>
        }
        <FormSection>
          <FormExplanation title="Text" text="Enter the text for the puzzle. You can use simple HTML to format the text." />
          <TextArea rows={3} value={text} onChange={setText} />
        </FormSection>
        </>
      </TitleBox>

      <TitleBox title="Gadget Picker">
        <Warning text="When changing the gadget you will need to reset your solutions. Always test the game after making changes that could introduce game-breaking logic." />
        <FormSection>
          <FormExplanation title="Gadget" text="Choose the gadget type here" />
          <Select 
            value={component.type} 
            options={gadgetOptions}
            onChange={e => { // change this
              setComponent({ ...component, type: e, config: null })
              setSolutions([]);
            }}
          />
        </FormSection>
          {/* Image of the gadget here */}
        <GadgetConfig 
          type={component.type} 
          value={component.config} 
          setValue={(config: string) => {
            setComponent({ ...component, config })
            // setSolutions([]);
          }}
        />
        {
          unfinished &&
          <Warning 
            type='error'
            text="This puzzle has solutions that do not lead anywhere, make sure you select at least one puzzle to unlock after this one." 
          />
        }
        <FormSection border={false}>
          <FormExplanation title="Solutions" text="Enter the solutions for the puzzle here" />
          <SolutionsInput
            component={component}
            clueNames={clueNames} 
            value={solutions} 
            onChange={setSolutions} 
            clueId={clue.id}
          />
        </FormSection>
      </TitleBox>

      <TitleBox title="Puzzle Help">
        <FormSection>
          <FormExplanation title="Hints" text="Enter the hints for the puzzle here." />
          <MultiInput value={hints} onChange={setHints} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Explanation" text="Enter the explanation for the solution here. You can use simple HTML to format the text." />
          <TextArea rows={3} value={explanation} onChange={setExplanation} />
        </FormSection>
      </TitleBox>

      <TitleBox title="Cutscene">
        <FormSection border={false}>
          <FormExplanation title="Use Cutscene" text="Check this box if you wish to add a cutscene at the end of this puzzle." />
          <CheckBox checked={useCutScene} onChange={setUseCutScene}/>
        </FormSection>
        <>
          {
            useCutScene &&
            <FormSection border={false}>
              <FormExplanation title="Cutscene" text="Drag and drop the cutscene video here. It needs to be in .mp4 format." />
              <FileDrop value={cutScene} file={cutSceneFile} setFile={setCutSceneFile} maxSize={104857600} type="video"/>
            </FormSection>
          }
        </>
      </TitleBox>

      <TitleBox title="Puzzle Configuration">
        <FormSection>
          <FormExplanation title="Show on start" text="Check this box if you would like the puzzle to be visible at the beginning of the game." />
          <CheckBox checked={showOnStart} onChange={setShowOnStart}/>
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Win Condition" text="Check this box if the game should finish when this puzzle is completed." />
          <CheckBox checked={winCondition} onChange={setWinCondition}/>
        </FormSection>
      </TitleBox>

      <TitleBox title="Delete Puzzle">
        <FormSection>
          <FormExplanation title="Delete Puzzle" text={`If you wish to delete the puzzle and all its information write "${clue.title}" in the input and press delete. This cannot be undone.`}/>
          <DeleteItem item={clue.title} onClick={deleteClue} />
        </FormSection>
      </TitleBox>

      <BottomBar loading={loading} onCancel={handleCancel} onClick={_submit} />
    </Container>
  )
}

export default EditClueForm;