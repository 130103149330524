import React from "react";
import styled from "styled-components";
import { Button, CircularProgress } from "@material-ui/core";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  height: 64px;
  width: 100%;
  background: white;
  bottom: 0;
  right: 0;
  padding: 20px;
  border-top: 1px solid rgb(198, 190, 207);
  box-shadow: rgb(37 11 54 / 4%) 0px -2px 0px;
  z-index: 2;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

interface Props {
  onClick: () => void;
  onCancel?: () => void;
  loading: boolean;
}

const BottomBar: React.FC<Props> = ({ onClick, loading, onCancel }) => {
  return (
    <Container>
      {
        onCancel && 
        <ButtonWrapper>
          <Button
            onClick={onCancel}
            style={{ color: "#555" }}
          >
            Cancel
          </Button>
        </ButtonWrapper>
      } 
      <ButtonWrapper>
        <Button 
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          {
            loading 
            ? <CircularProgress style={{ color: "white" }} color="secondary" size={20} />
            : "Submit"
          }
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default BottomBar;