import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const Container = styled.div<{error: boolean}>`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 20px;
  background: white;
  color: ${props => props.error ? "#ed553b" : "#3caea3"};
  border-radius: 5px;
  border-bottom: 4px solid ${props => props.error ? "#ed553b" : "#3caea3"};
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
`;

const Text = styled.p`
  margin: 0;
  margin-left: 20px;
  font-weight: bold;
`;

interface Props {
  setFlashVisible: (bool: boolean) => void;
  error: boolean;
  text: string;
}

const FlashMessage: React.FC<Props> = ({
  setFlashVisible,
  error,
  text
}) => {

  useEffect(() => {
    let mounted = true;
    const timeout = setTimeout(() => {
      if(mounted) setFlashVisible(false);
    }, 3000);

    return () => {
      mounted = false;
      clearTimeout(timeout);
    }
  }, []);

  return (
    <Container error={error}>
      {
        error 
        ? <CancelIcon />
        : <CheckCircleIcon />
      }
      <Text>{ text }</Text>
    </Container>
  )
}

interface LocationState {
  flashMessage: {
    error: boolean;
    text: string;
  }
}

export const useFlashMessage = () => {
  const { state } = useLocation<LocationState>();
  const [flashVisible, setFlashVisible] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if(state && state.flashMessage){
      const { text, error } = state.flashMessage;
      showFlashMessage(text, error);
    }
  }, []);
  
  const showFlashMessage = (text: string, error: boolean): void => {
    setError(error);
    setText(text);
    setFlashVisible(true);
  }

  return {
    flashVisible, 
    text, 
    error,
    setFlashVisible, 
    showFlashMessage
  }

  // return [flashVisible, flashText, flashError, setFlashVisible, showFlashMessage] as const;

}

export default FlashMessage;