import React from 'react';
import { MarkerContainer, MarkerImage, MarkerWrapper, Triangle } from './Marker.styled';

interface MarkerProps {
  lat: number;
  lng: number;
}

const Marker: React.FC<MarkerProps> = () => (
  <MarkerWrapper>
    <MarkerContainer>
      <MarkerImage src="/assets/images/icon-white.png" />
      <Triangle />
    </MarkerContainer>
  </MarkerWrapper>
);

export default Marker;
