const getBase64Image = (file: File) => {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      const url = URL.createObjectURL(file);   
      
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        context?.drawImage(image,0,0);
        const dataUrl = canvas?.toDataURL("image/jpeg", 0.01);
        if(dataUrl){
          resolve(dataUrl);
        } else {
          reject("error");
        }
        
      }

      image.src = url;
      image.onerror = reject;
    } catch(err){
      reject(err);
    }
  })
}

export default getBase64Image;
