import React from "react";
import styled from "styled-components";
import SolutionSnapshot from "./Solutions/SolutionSnapshot";
import Input from "./Input";
import Icon from "../Icons/Icon";
import Select from "./Select";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";


const Container = styled.div``;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: white;
  border-radius: 20px;
  border: 1px solid #ddd;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Remove = styled.div`
  color: red;
  padding: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

interface Props {
  value: any[];
  clueNames: {
    value: string;
    name: string;
  }[];
  component: {
    type: string;
    config: string|null;
  };
  clueId: string;
  onChange: (value: any) => void;
}

const MultiInput: React.FC<Props> = ({
  value,
  clueNames,
  component,
  clueId,
  onChange
}) => {

  const getDefaultSolution = () => {
    const config = component.config ? JSON.parse(component.config) : null;
    if(config){
      switch(component.type){
        case "padlock":
          return [...Array(config.number).fill(0)];
        case "switch": 
          return [...Array(config.rows*config.cols).fill(false)];
        default:
          return [];
      }
    }
    return null;

  }

  const handleChange = (index: number, val: any): void => {
    onChange([
      ...value.slice(0, index),
      val,
      ...value.slice(index+1)
    ]);
  }

  const remove = (index: number): void => {
    onChange([
      ...value.slice(0, index),
      ...value.slice(index+1)
    ]);
  }

  const addNew = () => {
    const solution = getDefaultSolution();
    onChange([...value, {
      solution,
      next: []
    }]);
  }

  return (
    <Container>
      {
        value && value.map((solution,i) => {
          return (
            <InputRow>
              <SolutionSnapshot 
                key={i}
                gadget={component}
                solution={solution} 
                clueId={clueId}
                clueNames={clueNames}
                setSolutions={(val) => handleChange(i, val)}
              />
              <RemoveButton onClick={() => remove(i)} />
            </InputRow>
          )
        })
      }
      <AddButton onClick={addNew} />
    </Container>
  )
}

export default MultiInput;