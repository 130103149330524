import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ background: string }>`
  padding: 2px 8px;
  background: ${props => props.background};
  border-radius: 30px;
`;

const Text = styled.p<{ color: string }>`
  margin: 0;
  color: ${props => props.color};
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;
`;

interface Props {
  text: string|number;
  background?: string;
  color?: string;
}

const Pill: React.FC<Props> = ({ text, background='#ddd', color='#555' }) => {
  return (
    <Container background={background}>
      <Text color={color}>{ text }</Text>
    </Container>
  )
}

export default Pill;