import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

const Container = styled.div`
  display: flex;
`;

const Item = styled.div<{ active: any }>`
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1px;
  padding: 10px;
  border-radius: 5px;
  color: ${props => props.active ? '#333' : '#888' };
  white-space: nowrap;

  &:hover {
    background: #eee;
    color: #333;
    cursor: pointer;
  }
`;

const Separator = styled.div`
  color: #888;
  padding: 10px;
`;

const Breadcrumbs: React.FC = () => {
  // get business info
  // get game info
  // get play info
   

  // const values = [
  //   'businesses',
  //   'games',
  //   'plays',
  //   'statistics'
  // ];

  // ie -> City Escape Games => Mayor Murder Mystery => Puzzles
  //       City Escape Games => Stats
  //       City Escape Games => Plays => Crazy Team Name
  const history = useHistory();
  const location = useLocation();

  let paths = location.pathname.split('/');
  paths = paths[paths.length - 1] === '' ? paths.slice(0, paths.length - 1) : paths;
  paths = paths[1] === '' ? paths.slice(1) : paths;

  const breadcrumb = paths.map((path: string, i) => {
    if(i === 0){
      return <Item active={paths.length === 1} key={path} onClick={() => history.push('/')}>Home</Item>
    }

    const url = paths.slice(0, i + 1).join('/');
    return <Item key={path} onClick={() => history.push(url)} active={i === paths.length - 1}>{ path[0].toLocaleUpperCase() + path.slice(1) }</Item>
  }).reduce((acc: any[]|null, x, j) => acc === null ? [x] : [...acc, <Separator key={j}><FaChevronRight /></Separator>, x], null);

  return (
    <Container>
      { breadcrumb }
    </Container>
  )
}

export default Breadcrumbs;