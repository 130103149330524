import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getAllPlays } from "../../../Firebase/plays";
import { useHistory } from "react-router-dom";
import { Game } from "../../../Types/games";
import { createNewUser } from "../../../Firebase/auth";
import { getAllUsers } from "../../../Firebase/users";

import Header from "../../../Components/Header/Header";
import Body from "../../../Components/Body/Body";
import FormSection from "../../../Components/Forms/FormSection";
import FormExplanation from "../../../Components/Forms/FormExplanation";
import TitleBox from "../../../Components/Containers/TitleBox";
import Input from "../../../Components/Forms/Input";
import LoadingButton from "../../../Components/Buttons/LoadingButton";
import toDateString from "../../../helpers/toDateString";
import FlashMessage, { useFlashMessage } from "../../../Components/FlashMessage/FlashMessage";

import { Paper } from "@material-ui/core";
import { DataGrid, GridColDef, GridValueFormatterParams, GridRowParams } from '@material-ui/data-grid';
import "../../../Styles/Tables.css";
import AddGameModal from "./AddGameModal";
import { MEDIA_QUERIES } from "../../../config/constants";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

interface User {
  id: string;
  start: number;
  teamname: string;
  gameData: Game;
}

// Create a user - email

// List of users that have bought one of their games
//   email, created date, played/bought

const UsersScreen: React.FC = () => {
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState<null | User[]>(null);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelecterUser] = useState<string>();
  const flash = useFlashMessage();

  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", width: 200 },
    { field: 'created', headerName: 'Created', width: 200, valueFormatter: (params: GridValueFormatterParams) => toDateString(params.value) },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const res = await getAllUsers();
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      setUsers(res);
    }
  }

  const createUser = async () => {
    if(email !== ""){ // need to check email
      const res = await createNewUser(email);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        flash.showFlashMessage(res.message, false);
        setEmail("");
      }
    }
    setLoading(false);
  }

  const handleClick = (param: GridRowParams) => {
    const { id } = param.row;
    setSelecterUser(id as string);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash }/> }
      <Header title="Users" />
      <Body>
        {/* <TitleBox title="Create new user">
          <FormSection>
            <FormExplanation title="Create new User" text="Create a new user, this will email them their temporary password which they can change. " />
            <FormRow>
              <Input value={email} onChange={setEmail} placeholder="Email" />
              <ButtonWrapper>
                <LoadingButton 
                  style={{ height: 50.4 }}
                  loading={loading} 
                  setLoading={setLoading} 
                  onClick={createUser} 
                  text="Create"
                />
              </ButtonWrapper>
            </FormRow>
          </FormSection>  
        </TitleBox> */}
        <Paper>
          { 
            users && 
            <DataGrid 
              autoHeight
              onRowClick={(param: GridRowParams) => handleClick(param)}
              rows={users} 
              columns={columns} 
              pageSize={10} 
            />
          }
        </Paper>
        <AddGameModal
          showFlashMessage={flash.showFlashMessage}
          handleClose={() => setSelecterUser(undefined)}
          userId={selectedUser}
        />
      </Body>
    </Container>
  )
}

export default UsersScreen;