import React, { useContext } from "react";
import styled from "styled-components";
import ProfileIcon from "./ProfileIcon";
import { useHistory } from "react-router-dom";
import { logout } from "../../Firebase/auth";
import { Context as AuthContext, ContextType } from "../../Context/authContext";
import Breadcrumbs from "../Navigation/Breadcrumbs";
import { MEDIA_QUERIES } from "../../config/constants";

const drawerWidth = 256;
const Container = styled.header`
  display: none;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - ${drawerWidth}px);
  margin-left: ${drawerWidth}px;
  padding: 0 16px;
  height: 64px;
  background: white;
  border-bottom: 1px solid rgb(198, 190, 207);
  box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
  }
`;

const TitleWrapper = styled.div`
  max-width: calc(100% - 60px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const Title = styled.div`
  display: flex;  
  justify-content: space-between;
  font-weight: bold;
  color: #9586a5;
  padding: 0;
  letter-spacing: 1px;
  text-transform: uppercase;  
  flex: 1;
  margin: 0;
`;

interface Props {
  title: string;
  buttonPress?: () => void;
  children?: React.ReactNode;
}

const Header: React.FC<Props> = ({
  title,
  buttonPress,
  children
}) => {
  const history = useHistory();
  const { removeUser, state } = useContext(AuthContext) as ContextType;
  const { user } = state;

  const logoutUser = async () => {
    const res = await logout();
    history.push('/');
    // if(res.error){
    //   console.log(res.message);
    // } else {
    //   removeUser();
    //   history.push("/");
    // }
  }

  return (
    <Container>
      <TitleWrapper>
        <Breadcrumbs />
      </TitleWrapper>
      <ProfileIcon 
        email={user.email} 
        business={user.business.name}
        onClick={logoutUser} 
      />
    </Container>
  )

}

export default Header