import firebase from "firebase/app";
import "firebase/firestore";

export async function getAnalyticsByPlay(id: string): Promise<any>{

  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("analytics")
    .where("play", "==", id)
    .orderBy("date")
    .get();

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => ({
      _id: doc.id,
      ...doc.data()
    }))

  } catch (err) {
    console.log(err)
  }
}

export async function getAnalyticsByGame(id: string, date: Date[]): Promise<any> {
  try {
    const db = firebase.firestore();
    const analyticRef = db.collection('analytics')
    .where('game', '==', id)
    .where('date', '<=', new Date(date[1]).getTime())
    .where('date', '>=', new Date(date[0]).getTime());

    const snapshot = await analyticRef.get();

    if(!snapshot.empty){
      return snapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
    }

    return []
  } catch(err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}