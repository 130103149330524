import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface Props {
  onClick: () => void;
}

const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} color="primary" component="span">
      <AddCircleIcon />
    </IconButton>
  )
}

export default AddButton;