import React, { useEffect, useContext, useState } from 'react';
import TabsNavigator from "./Navigators/TabsNavigator";
import SideNav from "./Components/Navigation/SideNav/SideNav";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyles from "./config/globalStyles";
import { defaultTheme } from "./config/theme";

import firebase from "firebase/app";
import "firebase/auth";
import apiKeys from './config/firebase';
import { Provider as AuthProvider, ContextType } from "./Context/authContext";
import SignInNavigator from "./Navigators/SignInNavigator";
import { getUserById } from "./Firebase/users";
import { Context as AuthContext } from "./Context/authContext";
import AcceptTermsScreen from './Screens/Auth/AcceptTermsScreen';
import { getVerificationLink } from './Firebase/auth';

const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const STATUS = {
  unauthenticated: 1,
  notAcceptedTerms: 2,
  acceptedTerms: 3
};

function AppContent(){
  const [status, setStatus] = useState(STATUS.unauthenticated);
  const { addUser, addUserId, addBusiness, state } = useContext(AuthContext) as ContextType;
  const { userId, user } = state;

  useEffect(() => {
    if(user && user.role > 0){
      setStatus(user.acceptedTerms ? STATUS.acceptedTerms : STATUS.notAcceptedTerms);
    } else {
      setStatus(STATUS.unauthenticated);
    }
  }, [user]);

  useEffect(() => {
    async function getUser(){
      const res = await getUserById(userId);
      if(!res.error){
        addUser(res);
      }
    }

    if(userId){
      getUser();
    } else {
      setStatus(STATUS.unauthenticated);
    }
  }, [userId]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if(user){
        addUserId(user.uid);
      } else {
        addUserId(null);
      }
    })
  }, []);

  const getScreen = () => {
    switch(status){
      case STATUS.unauthenticated:
        return <SignInNavigator />
      case STATUS.acceptedTerms:
        return <Container>
          <SideNav />
          <TabsNavigator />
        </Container>
      case STATUS.notAcceptedTerms:
        return <AcceptTermsScreen user={userId} setStatus={setStatus} />
      default:
        return <SignInNavigator />
    }
  }

  return (
    <>
    {
      getScreen()
    }
    </>
  )

}


function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(apiKeys.firebaseConfig);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <AuthProvider>
        <GlobalStyles />
        <AppContent />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;