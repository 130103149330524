import React from 'react';
import styled from 'styled-components';
import { MEDIA_QUERIES } from '../../config/constants';
import useDimensions from '../../Hooks/useDimensions';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 250px;
  padding: 20px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 500px;
    margin-right: 10px;
  }
`;

const ChartWrapper = styled.div<{ size: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.size*24}px;
  height: ${props => props.size*7}px;
`;

const Square = styled.div<{ opacity: number, color: string, size: number }>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: ${props => props.color};
  opacity: ${props => props.opacity};
  text-align: center;
  font-size: ${props => props.size - 4}px;
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
`;

const SubTitle = styled.p`
  color: #4b4b4b;
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
`;

interface Props {
  data: number[];
  color: string;
  title: string;
  subtitle: string;
}

const HeatMap: React.FC<Props> = ({
  data,
  color,
  title,
  subtitle
}) => {
  const [ref, { width }] = useDimensions<HTMLDivElement>();
  const maxSize = 16;
  const size = Math.min(maxSize, width / 30);
  let m = Math.max(...data);

  return (
    <Container ref={ref}>
      <div>
        <Title>{ title }</Title>
        <SubTitle>{ subtitle }</SubTitle>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
      <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
        <ChartWrapper size={size}>
          {
            data.map((val, i) => {
              const x = i % 24;
              const y = Math.floor(i / 24);
              const opacity = val/m;
              return <Square 
                key={i}
                size={size}
                opacity={opacity}
                color={color}
              />
            })
          }
        </ChartWrapper>
        <div style={{ display: 'flex', flexWrap: 'wrap', position: 'absolute', top: -size, left: 0, width: size*25, height: size*8 }}>
          {
            Array.from(Array(24).keys()).map((_,i) => {
              return <Square key={i} size={size} opacity={1} color='white'>{i}</Square>
            })
          }
        </div>
        <div style={{ position: 'absolute', top: 0, left: -size, width: size*25, height: size*8 }}>
          {
            [...Array(7)].map((_,i) => {
              let days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
              return <Square key={i} size={size} opacity={1} color='white'>{days[i]}</Square>
            })
          }
        </div>
      </div>
      </div>
    </Container>
  )
}

export default HeatMap;