import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
// import LoadingButton from "../../Buttons/LoadingButton";
import Button from "@material-ui/core/Button";
import { Clue } from "../../../Types/games";
import { Checkbox } from "@material-ui/core";

import InputSolution from "./InputSolution";
import NumbersSolution from "./NumbersSolution";
import BooleanSolution from "./BooleanSolution";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 15px 20px;

  &:hover {
    cursor: pointer;
    border: 2px solid #776589;
    padding: 13px 18px;
  }
`;

const Solution = styled.p`
  margin: 0;
  margin-right: 16px;
`;

const Next = styled.p`
  margin: 0;
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  width: 600px;
  height: 400px;
  background: white;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

const SolutionInput = styled.div`
  flex: 1;
  padding: 16px;
  padding-right: 8px;
`;

const NextClues = styled.div`
  height: 100%;
  flex: 1;
  padding: 16px;
  padding-left: 8px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
`;

const SubTitle = styled.p`
  color: #555;
  font-size: 14px;
`;

const ClueList = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  padding-bottom: 50px;
  overflow: auto;
`;

const ClueCheck = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  gadget: {
    type: string;
    config: string|null;
  }
  solution: {
    solution: any[];
    next: string[];
  };
  clueNames?: any[];
  clueId: string;
  setSolutions: (value: any) => void;
}

interface LooseObject {
  [key: string]: any
}

const InputMapping: LooseObject = {
  "switch": BooleanSolution,
  "input": InputSolution,
  "safelock": NumbersSolution,
  "keypad": NumbersSolution,
  "padlock": NumbersSolution,
  "decoder": NumbersSolution,
  "telephone": NumbersSolution,
  "morsecode": InputSolution,
  "compass": InputSolution
}

const SolutionSnapshot: React.FC<Props> = ({ gadget, solution, clueNames, clueId, setSolutions }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentSolution, setCurrentSolution] = useState(solution); // { solution: [], next: [] } 

  useEffect(() => {
    if(modalVisible){
      // console.log(solution.next);
    }
  }, [modalVisible])

  const submit = () => {
    setSolutions(currentSolution);
    setModalVisible(false);
  };

  const handleSolutionChange = (newSolution: any) => {
    setCurrentSolution({ ...currentSolution, solution: newSolution });
  }; 

  const handleNextChange = (id: string, checked: boolean) => {
    const newNext = checked 
    ? [...currentSolution.next, id]
    : currentSolution.next.filter(val => val !== id);
    setCurrentSolution({ ...currentSolution, next: newNext });
  };

  const SolutionComponent = InputMapping[gadget.type];
  const config = gadget.config ? JSON.parse(gadget.config) : null;

  return (
    <>
      <Container onClick={() => setModalVisible(true)}>
        <Solution>{ solution.solution && solution.solution.toString() }</Solution>
        {
          solution.next && 
          <Next>Unlocks { solution.next.length } puzzles</Next>
        }
      </Container>
      <Modal
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <ModalContainer>
          <SolutionInput>
            <Title>Solution</Title>
            <SubTitle>Add the solution here</SubTitle>
            <SolutionComponent
              solution={currentSolution.solution} 
              setSolution={handleSolutionChange}
              config={config}
              type={gadget.type}
            />
          </SolutionInput>
          <NextClues>
            <Title>Next Puzzles</Title>
            <SubTitle>This solution unlocks the following puzzles:</SubTitle>
            <ClueList>
              {
                clueNames && clueNames.map(clue => {
                  if(clue.value != clueId){
                    return (
                      <ClueCheck>
                        <Checkbox 
                          checked={currentSolution.next.indexOf(clue.value) > -1}
                          onChange={e => handleNextChange(clue.value, e.currentTarget.checked)}
                        />
                        <p>{ clue.name }</p>
                      </ClueCheck>
                    )
                  }
                })
              }
            </ClueList>
          </NextClues>
          <ButtonWrapper>
            <Button
              onClick={submit}
              variant="contained"
              color="primary"
            >Add</Button>
            {/* <LoadingButton 
              onClick={submit} 
              loading={loading} 
              setLoading={setLoading} 
              text="Add"
            /> */}
          </ButtonWrapper>
        </ModalContainer> 
      </Modal>
    </>
  )
}

export default SolutionSnapshot;