import React, { useState } from "react";
import styled from "styled-components";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import Header from "../../Components/Header/Header";
import TitleBox from "../../Components/Containers/TitleBox";
import Body from '../../Components/Body/Body';
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import TextArea from "../../Components/Forms/TextArea2";
import { Button } from "@material-ui/core";

const Container = styled.div`
  height: 100vh;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const GameScreen: React.FC = () => {
  const flash = useFlashMessage();
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    // send the message
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Header title="Contact" />
      <Body>
        <TitleBox title='Contact Us'>
          <FormSection border={false}>
            <FormExplanation title='Message' text='Let use know if you have any questions and we will get back to you as soon as possible.'/>
            <TextArea value={message} onChange={setMessage} rows={5}/>
          </FormSection>
          <FormSection border={false}>
            <ButtonWrapper>
              <Button
                variant='contained'
                onClick={handleSubmit}
                color='primary'
              >Submit</Button>
            </ButtonWrapper>
          </FormSection>
        </TitleBox>
      </Body>
    </Container>
  )
}

export default GameScreen;