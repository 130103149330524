import React, { useMemo } from "react";
import styled from "styled-components";
// import toTimeString from '../../helpers/toTimeString';

import BarChartInfoBox from '../../Components/Statistics/BarChartInfoBox';
import DoughnutInfoBox from '../../Components/Statistics/DoughnutInfoBox';
import HeatMap from '../../Components/Statistics/HeatMap';
import configureAnalytics from "../../helpers/configureAnalytics";

const toTimeString = (millis: number) => {
  const minutes = Math.floor((millis / (60 * 1000)) % 60);
  const hours = Math.floor(millis / (60 * 60 * 1000));
  const minuteString = minutes < 10 ? "0" + minutes : minutes;

  return hours.toString() + ":" + minuteString;
}

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
`;

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Text = styled.p``;


interface Props {
  data: any[];// Stats Prop type;
  game: any;
}

const StatisticsScreen: React.FC<Props> = ({ 
  game,
  data
}) => {

  const sortedData = useMemo(() => {
    return configureAnalytics(data);
  }, [data]);

  const guesses = Math.round(sortedData.guesses.count / sortedData.guesses.total * 100);
  const averageRatings = sortedData.rating.total > 0 ?  Math.round(sortedData.rating.count / sortedData.rating.total * 10) / 10 : 0;
  const averageTime = Math.round(sortedData.time.count / sortedData.time.total * 10) / 10;
  const averageHints = Math.round(sortedData.hints.count / sortedData.complete.count * 10) / 10;

  const time = {
    averageTime,
    diff: averageTime - game.duration
  }

  return (
    <Container>
      <StatsRow>
        <DoughnutInfoBox 
          title={sortedData.complete.count.toString()}
          subtitle='Finished Plays'
          data={[sortedData.complete.count,sortedData.complete.total - sortedData.complete.count]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={toTimeString(time.averageTime)}
          subtitle='Average Time'
          data={[time.diff,1-time.diff]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={averageRatings.toString()}
          subtitle='Average Rating'
          data={[averageRatings, 5-averageRatings]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={guesses.toString()}
          subtitle='Percentage Correct'
          data={[guesses, 100-guesses]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={averageHints.toString()}
          subtitle='Average Hints per Game'
          data={[averageHints, 3 - averageHints]}
          colors={['#35ac8c','#ddd']}
        />
      </StatsRow>
      <StatsRow>
        <BarChartInfoBox
          title='Time to Complete'
          subtitle='This graph shows the times taken to complete your game, this includes all completed plays up to 2 hours.'
          colors={['#35ac8c']}
          data={sortedData.time.values}
        />
        <BarChartInfoBox
          title='Ratings'
          subtitle='This graph shows the ratings for all puzzles within completed plays.'
          colors={['#35ac8c']}
          data={sortedData.rating.values}
        />
        <HeatMap
          data={sortedData.time.starts}
          color='#35ac8c'
          title='Heat Map of Play Times'
          subtitle='This chart shows when during the week your customers are playing your game'
        />
      </StatsRow>
    </Container>
  )
}

export default StatisticsScreen;