import React from "react";
import styled from "styled-components";

const Container = styled.button<{ background: string }>`
  border: none;
  background: ${props => props.background};
  outline: none;
  padding: 0 16px;
  border-radius: 10px;
  border: 1px solid #ccc;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
`;

interface ButtonProps {
  children: React.ReactNode|null;
  onClick: () => void;
  background?: string;
}

const Button: React.FC<ButtonProps> = ({ 
  children, 
  onClick, 
  background='#fff'
}) => {
  return (
    <Container 
      background={background}
      onClick={onClick}
    >
      { children }
    </Container>
  )
}

export default Button;