import React, { useEffect, useState } from "react";
import FormSection from "../FormSection";
import FormExplanation from "../FormExplanation";
import Input from "../Input";
import CheckBox from "../CheckBox";

interface SwitchProps {
  value: string | null;
  setValue: (e: string) => void;
}

const SwitchConfig: React.FC<SwitchProps> = ({ value, setValue }) => {

  const [config, setConfig] = useState(value && JSON.parse(value));
  
  useEffect(() => {
    const defaultConfig = {
      start: [false, false, false, false, false, false],
      rows: 2,
      cols: 3,
      text: true
    }

    if(value === null){
      setConfig(defaultConfig)
    }
  }, [value]);

  useEffect(() => {
    if(config && config.rows && config.cols){
      const start = new Array(config.rows*config.cols).fill(false);
      setValue(JSON.stringify({ ...config, start }));
    }
  }, [config]);

  return (
    <div>
      {
        config && 
        <>
          <FormSection border={false}>
            <FormExplanation title="Configuration"/>
          </FormSection>
          <FormSection border={false}>
            <FormExplanation title="Rows" text="How many rows should there be? min=1, max=3" />
            <Input type="number" value={config.rows} onChange={e => setConfig({ ...config, rows: e })} />
          </FormSection>
          <FormSection border={false}>
            <FormExplanation title="Columns" text="How many columns should there be? min=1, max=4" />
            <Input type="number" value={config.cols} onChange={e => setConfig({ ...config, cols: e })} />
          </FormSection>
          <FormSection>
            <FormExplanation title="Text" text="Check this box if you want the switches to be labelled on and off"/>
            <CheckBox checked={config.text} onChange={e => setConfig({ ...config, text: e })} />
          </FormSection>
        </>
      }
    </div>
  )
}

export default SwitchConfig;