import React from "react";
import { Switch, Route } from "react-router-dom";

import FinishSignUpScreen from "../Screens/Users/FinishSignUpScreen";
import SignInScreen from "../Screens/SignInScreen/SignInScreen";
import RedirectScreen from "../Screens/Auth/RedirectScreen";
import ResetPasswordScreen from "../Screens/Auth/ForgotPasswordScreen";

const SignInNavigator: React.FC = () => {
  return (
    <Switch>
      <Route path="/reset-password">
        <ResetPasswordScreen />
      </Route>
      <Route path="/finishSignUp">
        <FinishSignUpScreen />
      </Route>
      <Route path="/redirectToApp">
        <RedirectScreen />
      </Route>
      <Route path="/">
        <SignInScreen />
      </Route>
    </Switch>
  )
}

export default SignInNavigator;