export const Channel = {
  0: 'shopify',
  1: 'roamr',
  2: 'apple',
  3: 'android'
} as {
  [index: number]: string
};

export interface Credit {
  id: string;
  created: number;
  business: string;
  game: string;
  email: string;
  voucher: string;
  channel: number;
  shouldExpire: boolean;
  emailSent: boolean;
  price: number;

  reference?: string;
  user?: string;
  expired: boolean;
  expiredDate?: number;
  reason?: string;
  redeemed: boolean;
  redeemedDate?: number;
}