import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal as MUIModal, Button, Select, MenuItem } from '@material-ui/core';

import { User } from '../../../Types/users';
import { Game } from '../../../Types/games';
import { getAllGames } from '../../../Firebase/games';
import { getUserById } from '../../../Firebase/users';
import LoadingScreen from '../../Loading/LoadingScreen';
import { createCreditOnUser, createUsableCredit } from '../../../Firebase/credits';
import LoadingButton from '../../../Components/Buttons/LoadingButton';
import { useFlashMessage } from '../../../Components/FlashMessage/FlashMessage';
import { MEDIA_QUERIES } from '../../../config/constants';
import { getVerificationLink } from '../../../Firebase/auth';

const Modal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
  border-radius: 5px;
  margin: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    min-width: 800px;
    margin: 0;
  }
`;

const FormWrapper = styled.div`
  display: flex;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-right: 20px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 250px;
  }
`;

interface Props {
  showFlashMessage: (message: string, error: boolean) => void;
  handleClose: () => void;
  userId?: string;
}

const AddGameModal: React.FC<Props> = ({ 
  showFlashMessage,
  handleClose,
  userId
}) => {
  const [user, setUser] = useState<User>();
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [creatingCredit, setCreatingCredit] = useState(false);
  const [chosenGame, setChosenGame] = useState<string>();
  const [loadingLink, setLoadingLink] = useState(false);
  const [verificationLink, setVerificationLink] = useState<string>();

  useEffect(() => {
    const getGames = async () => {
      const res = await getAllGames();
      if(!res.error){
        setGames(res);
      }
    }

    const getUser = async () => {
      if(userId){
        const res = await getUserById(userId);
        if(!res.error){
          setUser(res);
        }
      }
    }

    const getData = async () => {
      await Promise.all([
        getGames(), 
        getUser()
      ]);
      setLoading(false);
    }
    
    getData();
  }, [userId]);

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setChosenGame(e.target.value as any);
  }

  const onClose = () => {
    setUser(undefined);
    handleClose();
  }

  const onSubmit = async () => {
    setCreatingCredit(true);
    const game = games.find(game => game.id === chosenGame);
    if(game && user){
      const res = await createUsableCredit(game.business, game.id, user.id, user.email);
      showFlashMessage(res.message, res.error);
      handleClose();
    }
    setCreatingCredit(false);
  }

  const onCreateVerificationLink = async () => {
    if (user) {
      const { link } = await getVerificationLink(user.email);
      if (link) {
        setVerificationLink(link);
      }
    }
    setLoadingLink(false);
  };

  const render = () => {
    return (
      <div>
        <h3>Add voucher to { user?.email }</h3>
        <FormWrapper>
          <SelectWrapper>
            <Select
              value={chosenGame}
              onChange={handleChange}
              style={{ width: '100%' }}
            >
              {
                games.map(game => {
                  return (
                    <MenuItem 
                      key={game.id} 
                      value={game.id}
                    >{ game.name }</MenuItem>
                  )
                })
              }
            </Select>
          </SelectWrapper>
          <LoadingButton
            text='Submit'
            loading={creatingCredit}
            setLoading={setCreatingCredit}
            onClick={onSubmit}
          />
        </FormWrapper>
        <br />
        <h3>Create a validation link</h3>
        <LoadingButton
          text='create'
          loading={loadingLink}
          setLoading={setLoadingLink}
          onClick={onCreateVerificationLink}
        />
        {verificationLink && <p>{verificationLink}</p>}
      </div>
    )
  }

  return (
    <Modal
      open={userId !== undefined}
      onClose={onClose}
    >
      <Container>
        {
          loading
          ? <LoadingScreen />
          : render()
        }
      </Container>
    </Modal>
  )
}

export default AddGameModal;