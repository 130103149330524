export enum AUTH_STATUS {
  unauthenticated = 'unauthenticated',
  verified = 'verfied',
  unverified = 'unverified',
  undetermined = 'undetermined',
}

export enum AUTH_ROLE {
  user = 0,
  business = 1,
  admin = 2,
}

export const MEDIA_QUERIES = {
  SMALL: 768,
  MEDIUM: 1024,
  LARGE: 1200,
};

export const SIDE_NAV_WIDTH = 250;
export const HEADER_HEIGHT = 60;

export const PAGINATION_LIMIT = 1;

export const FUNCTIONS = {
  SEND_EMAIL: 'email-sendEmail',
};

export const GAMES_TABLE_HEADERS = [
  'name',
  'location',
];

export const BUSINESS_TABLE_HEADERS = [
  'name',
  'location',
];

export const PAGINATION_LENGTH = 3;
export const PAGE_LENGTH = 10;
