import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import StatisticsOverviewScreen from "../Screens/Statistics/OverviewScreen";
import GamesStatisticsScreen from '../Screens/Statistics/GameStatisticsScreen';
import ClueStatisticsScreen from '../Screens/Statistics/ClueStatisticsScreen';

const GamesNavigator: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={StatisticsOverviewScreen} />
      <Route path={`${path}/games/:gameId`} component={GamesStatisticsScreen} />
      <Route path={`${path}/games/:gameId/clues/:clueId`} component={ClueStatisticsScreen} />
    </Switch>
  )
}

export default GamesNavigator;