import React from "react";
import styled from "styled-components";
import NavigationButton from "../Buttons/NavigationButton";

const Container = styled.div<Pick<Props, "active">>`
  border-bottom: ${props => props.active ? "3px solid #1b3a57" : "none"};
  padding: 10px 0;
  margin-right: 32px;
  cursor: pointer;
  white-space: nowrap;
`;

const Text = styled.p<Pick<Props, "active">>`
  margin: 0;
  color: ${props => props.active ? "#1b3a57" : "#888"};
  font-weight: bold;
`;

interface Props {
  text: string;
  active: boolean;
  to: string;
}

const HeaderTab: React.FC<Props> = ({
  text,
  active=false,
  to
}) => {
  return (
    <NavigationButton to={to}>
      <Container active={active}>
        <Text active={active}>{ text }</Text>
      </Container>
    </NavigationButton>
  )
}

export default HeaderTab;