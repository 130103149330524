import Icon, { IconName } from "../../Icons/Icon";
import { NavigationButtonIconWrapper, NavigationButtonListItem, NavigationButtonText, NavigationButtonWrapper } from "./NavigationButton.styled";

interface NavigationButtonProps {
  icon: IconName;
  title: string;
  onClick(): void;
  isMobile: boolean;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  icon,
  title,
  onClick,
  isMobile,
}) => (
  <NavigationButtonListItem>
    <NavigationButtonWrapper
      isMobile={isMobile}
      onClick={onClick}
    >
      <NavigationButtonIconWrapper>
        <Icon name={icon} size={24} />
      </NavigationButtonIconWrapper>
      <NavigationButtonText>{title}</NavigationButtonText>
    </NavigationButtonWrapper>
  </NavigationButtonListItem>
);

export default NavigationButton;
