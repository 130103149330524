import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import AccountEmailsScreen from "../Screens/Account/AccountEmailsScreen";
import AccountDetailsScreen from '../Screens/Account/AccountDetailsScreen';

const AccountNavigator: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path + '/details'}>
        <AccountDetailsScreen />
      </Route>
      <Route path={`${path}/emails`}>
        <AccountEmailsScreen />
      </Route>
    </Switch>
  )
}

export default AccountNavigator;