import styled from "styled-components";
import { Link } from "react-router-dom";
import { MEDIA_QUERIES } from "../../config/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #34ac8c;
  background: linear-gradient(135deg, #34ac8c, #5ba8a0);

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: white;
  box-shadow: 2px 0 10px rgba(0,0,0,0.2);

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 500px;
  }
`;

export const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 200px;
  height: 200px;
`;

export const LogoTitle = styled.p`
  font-size: 44px;
  margin: 0;
  color: white;
  font-family: ALoveOfThunder;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.2);
`;

export const SignInForm = styled.div`

`;

export const Text = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
  font-style: italic;
`;

export const Title = styled.p`
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const FormRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
