import firebase from "firebase/app";
import "firebase/firestore";
import Business from "../Types/business";

// data

export async function createNewBusiness(data: any): Promise<any>{
  try {
    const db = firebase.firestore();
    const ref = await db.collection("businesses").add({ 
      ...data,
      created: new Date()
     });
    const doc = await ref.get();
    return {
      success: true,
      message: "Successfully added new business",
      payload: {
        id: doc.id,
        ...doc.data()
      }
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getAllBusinesses(){
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("businesses").get();
    if(snapshot.empty){
      console.log("No Data");
    }
    return snapshot.docs.map((doc: any) => {
      return {
        id: doc.id,
        ...doc.data()
      }
    })
  } catch(err: any){
    console.log(err);
    return { error: true, message: err.message }
  }
}

export async function getBusinessById(id: string) {
  try {
    const db = firebase.firestore();
    const doc = await db.collection("businesses").doc(id).get();
    return {
      id: doc.id,
      ...doc.data()
    };
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function updateBusiness(id: string, data: any){
  try {
    const db = firebase.firestore();
    const ref = db.collection("businesses").doc(id);
    await ref.set(data, { merge: true });
    
    return {
      error: false,
      message: "Successfully updated business"
    }
  } catch(err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}