import React, { useEffect, useState } from "react";
import FormSection from "../FormSection";
import FormExplanation from "../FormExplanation";
import Input from "../Input";
import CheckBox from "../CheckBox";
import Select from "../Select";

interface SwitchProps {
  value: string | null;
  setValue: (e: string) => void;
}

const PadlockConfig: React.FC<SwitchProps> = ({ value, setValue }) => {

  const defaultConfig = {
    start: [0,0,0,0],
    type: 'numbers',
    number: 4
  }

  const [config, setConfig] = useState(value && JSON.parse(value));
  
  useEffect(() => {
    if(value === null){
      setConfig(defaultConfig)
    }
  }, [value]);

  useEffect(() => {
    setValue(JSON.stringify(config));
  }, [config]);

  const handleChange = (e: string) => {
    const number = parseInt(e);
    const start = new Array(number).fill(0);
    setConfig({ ...config, number, start });
  }

  return (
    <div>
      {
        config && 
        <>
          <FormSection border={false}>
            <FormExplanation title="Configuration"/>
          </FormSection>
          <FormSection border={false}>
            <FormExplanation title="Number" text="Choose the number of dials on your padlock. Each dial is 0 to 9. min=3, max=6" />
            <Input type="number" value={config.number} onChange={handleChange} />
          </FormSection>
          <FormSection border={false}>
            <FormExplanation title="Type" text="Would you like the decoder to have letters or numbers?" />
            <Select
              value={config.type}
              options={[
                { name: 'numbers', value: 'numbers'},
                { name: 'colours', value: 'colours'},
                { name: 'mix', value: 'mix' }
              ]}
              onChange={e => setConfig({ ...config, type: e })}
            />
          </FormSection>
        </>
      }
    </div>
  )
}

export default PadlockConfig;