import React from "react";
import styled from "styled-components";
import AddButton from "../../Buttons/AddButton";

const Container = styled.div`
  display: flex;
`;

const Input = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  font-family: "roboto";
  margin-right: 5px;
`;

interface Props {
  solution: any;
  config?: {
    length: number;
  }
  setSolution: (value: any) => void;
  type: string;
}

const NumbersSolution: React.FC<Props> = ({ config, solution, setSolution, type }) => {

  const addNew = () => {
    setSolution([...solution, 0]);
  }

  const handleChange = (i: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setSolution([
      ...solution.slice(0, i),
      e.target.value,
      ...solution.slice(i+1)
    ]);
  }

  return (
    <Container>
      {
        solution.map((sol: any, i: number) => <Input maxLength={1} value={sol} onChange={(e) => handleChange(i,e)} />)
      }
      {
        type !== "padlock" && 
        <AddButton onClick={addNew} />
      }
    </Container>
  )
}

export default NumbersSolution;