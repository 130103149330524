import { DefaultTheme } from "styled-components";

interface IPalette {
  main: string;
  tint: string;
  text: string;
}

declare module "styled-components" {
  export interface DefaultTheme {
    colors: {
      common: {
        black: string;
        white: string;
        dark: string;
        light: string;
      }
      primary: IPalette;
      secondary: IPalette;
      error: IPalette;
      warning: IPalette;
      success: IPalette;
      info: IPalette;
    }
  }
}

export const defaultTheme: DefaultTheme = {
  colors: {
    error: {
      main: "#f44336",
      tint: "#fdecea",
      text: "#fff"
    },
    success: {
      main: "#4caf50",
      tint: "#edf7ed",
      text: "#fff"
    },
    info: {
      main: "#2196f3",
      tint: "#e8f4fd",
      text: "#fff"
    },
    warning: {
      main: "#ff9800",
      tint: "#fff4e5",
      text: "#fff"
    },
    common: {
      black: "#000",
      white: "#fff",
      dark: "#333",
      light: "#ddd"
    },
    primary: {
      main: "",
      tint: "",
      text: ""
    },
    secondary: {
      main: "",
      tint: "",
      text: ""
    }
  }
}
