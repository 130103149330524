import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getPlaysWhere } from "../../Firebase/plays";
import { useHistory, useParams } from "react-router-dom";

import { Game } from "../../Types/games";
import toDateString from "../../helpers/toDateString";

import { FormControl, MenuItem, Select } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';
import "../../Styles/Tables.css";
import { getGamesWhere } from "../../Firebase/games";
import useIsMounted from "../../Hooks/useIsMounted";
import LoadingItem from "../../Components/Loading/LoadingItem";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
    height: 100%;
    margin-bottom: 0;
  }
`;

const FilterList = styled.div`  
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 256px;
    height: 100%;
    background: #f8f8f8;
    overflow-y: scroll;
    border-right: 1px solid rgb(198,190,207);
    box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
  }
`;

const Item = styled.div`
  padding: 10px;
`;

const ItemTitle = styled.p`
  font-weight: bold;
  color: #4b4b4b;
`;

const LoadingItemWrapper = styled.div`
  flex: 1;
  height: 100px;
  margin: 10px;
`;

interface Play {
  id: string;
  start: number;
  teamname: string;
  gameData: Game;
}

const PlaysScreen: React.FC = () => {
  const history = useHistory();
  const params = useParams() as { businessId: string };
  const [plays, setPlays] = useState<null | Play[]>(null);
  const [games, setGames] = useState<null|Game[]>(null);
  const [chosenGame, setChosenGame] = useState('');
  const [loading, setLoading] = useState(true);
  const mounted = useIsMounted();

  const formattedPlays = (plays: any) => {
    return plays.map((play: any) => {
      return {
        ...play,
        game: games?.find((game: Game) => game.id === play.game)?.name,
        date: toDateString(play.start),
        start: play.start ? new Date(play.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '-',
        end: play.end ? new Date(play.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '-'
      }
    })
  }

  const columns: GridColDef[] = [
    { 
      field: "game", 
      headerName: "Game", 
      width: 200
    },
    { field: 'teamname', headerName: 'Teamname', width: 200 },
    { field: 'date', headerName: 'Date', width: 200 },
    { field: 'start', headerName: 'Start', width: 200 },
    { field: 'end', headerName: 'End', width: 200 }
  ];

  useEffect(() => {
    async function getPlays(){
      const res = await getPlaysWhere("game", chosenGame);
      if(!res.error && mounted){
        const sorted = res.sort((a: any, b: any) => b.start - a.start);
        setPlays(formattedPlays(sorted));
        setLoading(false);
      }
    }
    if(chosenGame !== ''){
      setLoading(true);
      getPlays();
    }
  }, [chosenGame]);

  useEffect(() => {
    async function getGames(){
      const res = await getGamesWhere('business', params.businessId);
      setLoading(false);
      setGames(res);
    }
    setGames([]);
    setPlays([]);
    getGames();
  }, [params.businessId]);

  const handleClick = (param: GridRowParams) => {
    const play = param.row;
    history.push(`/businesses/${params.businessId}/plays/${play.id}`);
  }

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setChosenGame(e.target.value as any);
  }

  return (
    <Container>
      <FilterList>
        <Item>
          <ItemTitle>Game</ItemTitle>
          {
            games &&
            <FormControl
              variant='outlined'
              style={{ width: '100%', background: 'white' }}
            >
              <Select
                value={chosenGame}
                onChange={handleChange}
              >
                {
                  games.map(game => {
                    return (
                      <MenuItem 
                        key={game.id} 
                        value={game.id}
                      >{ game.name }</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          }
        </Item>
      </FilterList>
      {
        loading
        ? <LoadingItemWrapper>
            <LoadingItem />
          </LoadingItemWrapper>
        : chosenGame && plays
          ? <div style={{ flex: 1, margin: 10 }}>
              <Paper>
                { 
                  plays && 
                  <DataGrid 
                    autoHeight
                    onRowClick={(param: GridRowParams) => handleClick(param)}
                    rows={plays} 
                    columns={columns} 
                    pageSize={10} 
                  />
                }
              </Paper>
            </div>
          : <p style={{ marginLeft: 20 }}>Choose a game</p>

      }
    </Container>
  )
}

export default PlaysScreen;