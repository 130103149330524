import styled from 'styled-components';

export const MarkerWrapper = styled.div`
  position: relative;
`;

export const MarkerContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #34ac8c;
  border-radius: 15px;
  padding: 5px;
`;

export const MarkerImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const Triangle = styled.div`
  position: absolute;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #34ac8c;
  bottom: -8px;
`;
