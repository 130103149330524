import firebase from "firebase/app";
import "firebase/firestore";

export async function getAllPlays(): Promise<any>{
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("plays")
      .orderBy("start", "desc")
      // .where('started', '==', true)
      .get();

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => {
      const data = doc.data();
      // return { id: doc.id, ...data }
      return {
        id: doc.id,
        game: data.gameData ? data.gameData.name : "",
        teamname: data.teamname,
        start: data.start
      }
    })

  } catch (err) {
    console.log(err)
  }
}

export async function getPlaysByGame(id: string): Promise<any>{
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("plays")
      .where('game', '==', id)
      // .orderBy("start", "desc")
      .get();

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => {
      const data = doc.data();
      // return { id: doc.id, ...data }
      return {
        id: doc.id,
        game: data.gameData ? data.gameData.name : "",
        teamname: data.teamname,
        start: data.start
      }
    })

  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}


export async function paginatePlays(page: number): Promise<any>{
  const limit = 10;

  try {
    const db = firebase.firestore();
    let snapshot;
    if(page === 0){
      snapshot = await db.collection("plays")
      .orderBy("start","desc")
      .limit(limit)
      .get();
    } else {
      const first = await db.collection("plays")
      .orderBy("start","desc")
      .limit(limit*page)
      .get();
  
      const last = first.docs[first.docs.length-1];
  
      snapshot = await db.collection("plays")
        .orderBy("start","desc")
        .startAt(last.data().start)
        .limit(limit)
        .get();
    }

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data()
    }))

  } catch (err) {
    console.log(err)
  }
}

export async function getPlaysWhere(key: string, value: any): Promise<any>{

  try {
    const db = firebase.firestore();
    const snapshot = await db
    .collection("plays")
    // .orderBy("start" , "desc")
    .where(key, "==", value)
    .get();

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => ({
      ...doc.data(),
      id: doc.id
    }))

  } catch (err) {
    console.log(err)
  }
}

export async function getPlayById(id: string): Promise<any>{
  try {
    const db = firebase.firestore();
    const doc = await db
    .collection("plays")
    .doc(id)
    .get();

    if(doc.exists){
      return {
        id: doc.id,
        ...doc.data()
      }
    } else {
      return {
        error: true,
        message: "No data found"
      }
    }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getPlaysFromGames(games: string[]): Promise<any>{

  try {
    const db = firebase.firestore();
    // const snapshot = await db
    // .collection("plays")
    // // .orderBy("start" , "desc")
    // .where('game', 'in', games)
    // .get();

    const snapshots = await Promise.all(games.map(async (game: any) => {
      return await db.collection("plays").where("game", "==", game).get();
    }))

    return snapshots.reduce((acc: any[], snapshot: any) => {
      return [...acc, ...snapshot.docs.map((doc: any) => {
        const data = doc.data();
        return {
          id: doc.id,
          game: data.gameData ? data.gameData.name : "",
          teamname: data.teamname,
          start: data.start,
          end: data.end
        }
      })]
    }, []);


    // if(snapshot.empty){
    //   console.log("No Results");
    // }

    // return snapshot.docs.map((doc: any) => {
    //   const data = doc.data();
    //   return {
    //     id: doc.id,
    //     game: data.gameData ? data.gameData.name : "",
    //     teamname: data.teamname,
    //     start: data.start,
    //     end: data.end
    //   }
    // })

  } catch (err) {
    console.log(err)
  }
}

export async function getPlaysByGameAndDate(id: string, date: Date[]): Promise<any>{
  try {
    const db = firebase.firestore();
    const playRef = db.collection('plays')
    .where('game', '==', id)
    // .where('started', '==', true)
    .where('start', '>=', new Date(date[0]).getTime())
    .where('start', '<=', new Date(date[1]).getTime())

    const snapshot = await playRef.get();
    if(!snapshot.empty){
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
    }
    return [];
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getStatistics(id: string, date: Date[]): Promise<any>{
  try {
    const db = firebase.firestore();
    const playRef = db.collection('plays')
    .where('game', '==', id)
    // .where('started', '==', true)
    .where('start', '>=', new Date(date[0]).getTime())
    .where('start', '<=', new Date(date[1]).getTime());

    const snapshot = await playRef.get();
    const data = snapshot.docs.map(async play => {
      const doc = play.data();
      const analyticsSnapshot = await db.collection('analytics').where('play', '==', play.id).get();

      const analytics = analyticsSnapshot.docs.map(a => ({
        id: a.id,
        ...a.data()
      }));

      return {
        id: play.id,
        start: doc.start,
        end: doc.end,
        finished: doc.finished,
        analytics
      }
    })

    return await Promise.all(data);

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}