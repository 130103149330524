import React, { useState } from "react";
import styled from "styled-components";

import { sendEmailLink } from "../../Firebase/auth";

import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import Input from "../../Components/Forms/Input";
import SubmitButton from "../../Components/Buttons/SubmitButton";
import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";
import Select from "../../Components/Forms/Select";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

type Success = {
  success: boolean;
  message: string;
}

const GamesScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<null | string>();
  const [role, setRole] = useState<number>(0);
  const [error, setError] = useState<null | string>();
  const [success, setSuccess] = useState<null | string>(null);

  const roles = [
    { name: "user", value: 0 },
    { name: "business", value: 1 },
    { name: "admin", value: 2 }
  ]

  async function submit(){
    if(email){
      const res = await sendEmailLink(email);
      if(res.success){
        setSuccess(res.message);
      } 
      else if (res.error){
        setError(res.message);
      }
      setLoading(false);
    }
  }

  return (
    <Container>
      <Header title="New User" />
      <Body>
        <FormSection>
          <FormExplanation title="Email" text="Who are we authenticating?" />
          <Input value={email} onChange={setEmail} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Role" text="What permissions are we giving?" />
          <Select value={role} onChange={setRole} options={roles} /> 
        </FormSection>
        {
          error
          ? <p>{ error }</p>
          : null
        }
        {
          success
          ? <FormSection>
              <FormExplanation title="Link" text="Here is the link, email the user so they can sign in" />
              <p>{ success }</p>
            </FormSection>
          : null
        }
        <ButtonWrapper>
          <SubmitButton loading={loading} setLoading={setLoading} onPress={submit}/>
        </ButtonWrapper>
      </Body>
    </Container>
  )
}

export default GamesScreen;