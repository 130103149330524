export default function createVoucherCode(){
  // use business-id?
  const length = 8;
  const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let retVal = "";

  for (var i=0, n=charset.length; i<length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}