import firebase from "firebase/app";
import "firebase/firestore";
import { Game, Clue } from "../Types/games";

export async function createNewGame(data: any): Promise<any>{
  try {
    const db = firebase.firestore();
    const gamesRef = db.collection("games");
    const documentRef = await gamesRef.add(data);
    const doc = await documentRef.get();
    return { success: true, game: { ...doc.data(), id: doc.id } }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function updateGameById(id: string, data: any): Promise<any> {
  try {
    const db = firebase.firestore();
    const gameRef = db.collection("games").doc(id);
    await gameRef.set(data, { merge: true });
    return { success: true, message: "Game has been updated" }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function addNewClue(id: string, data: any): Promise<any> {
  try {
    const db = firebase.firestore();
    const cluesRef = db.collection("games").doc(id).collection("clues");
    const newClueRef = await cluesRef.add(data);
    const doc = await db.collection("games").doc(id).collection("clues").doc(newClueRef.id).get();
    return { success: true, message: "New Clue successfully added", payload: { id: doc.id, ...doc.data() } }
  } catch(err){
    return { error: true, message: "Couldn't create new clue at this time." }
  }
}

export async function updateClue(gameId: string, clueId: string, data: any): Promise<any> {
  try {
    const db = firebase.firestore();
    const cluesRef = db.collection("games").doc(gameId).collection("clues").doc(clueId);
    await cluesRef.set(data, { merge: true });
    const clue = await cluesRef.get();
    return { success: true, message: "Clue successfully updated", payload: { id: clueId, ...clue.data() } }
  } catch(err){
    return { error: true, message: "Couldn't upload at this time." }
  }
}

export async function getCluesFromGameId(id: string): Promise<any> {
  try {
    const db = firebase.firestore();
    const cluesRef = db.collection("games").doc(id).collection("clues");
    const snapshot = await cluesRef.get();
    return snapshot.docs.map((doc: any) => ({ 
      id: doc.id, 
      ...doc.data() 
    }))
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getGameById(id: string): Promise<any>{
  try {
    const db = firebase.firestore();
    const gameRef = await db.collection("games").doc(id);
    const doc = await gameRef.get();
    if(!doc.exists){
      return { error: true, message: "No Matching Documents"}
    }
    return { ...doc.data(), id }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getGamesWhere(key: string, value: string): Promise<any>{
  try {
    const db = firebase.firestore();
    const ref = db.collection("games").where(key, "==", value);
    const snapshot = await ref.get();
    return snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }) as Game);
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getAllGames(): Promise<any> {
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("games").get();
    return snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id, }));
  } catch (err: any) {
    return { error: true, message: err.message }
  }
}

export async function deleteGameById(id: string): Promise<any> {
  try {
    const db = firebase.firestore();
    const doc = db.collection("games").doc(id);
    await doc.delete();
    return { success: true, message: "Game successfully deleted" }
  } catch(err: any){
    return { error: true, message: err.message };
  }
}

export async function deleteClueById(gameId: string, id: string): Promise<any> {
  try {
    const db = firebase.firestore();
    const doc = db.collection("games").doc(gameId).collection("clues").doc(id);
    await doc.delete();
    return { success: true, message: "Clue successfully deleted" }
  } catch(err: any){
    return { error: true, message: err.message };
  }
}

export async function getGameAndCluesById(gameId: string): Promise<any> {
  try {
    const db = firebase.firestore();
    const gameRef = db.collection("games").doc(gameId);

    const doc = await gameRef.get();
    if(doc.exists){
      const cluesSnapshot = await gameRef.collection("clues").get();
      const clues = cluesSnapshot.docs.map(clue => ({
        id: clue.id,
        ...clue.data()
      }));

      return {
        id: doc.id,
        ...doc.data(),
        clues
      }
    }

    return {
      error: true,
      message: "No data found"
    }

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getGamesById(ids: string[]){
  try {
    const db = firebase.firestore();
    const games = await Promise.all(ids.map(async id => {
      const doc = await db.collection("games").doc(id).get();
      return { ...doc.data(), id: doc.id };
    }));
    return { error: false, games };
  } catch(err: any){
    return { error: true, message: err.message }
  }
}