export default function toTimeString(millis: any | null, useHours=false) {
  if (millis === null) return "";

  const seconds = Math.floor((millis / 1000) % 60);
  const minutes = Math.floor((millis / (60 * 1000)) % 60);
  const hours = Math.floor(millis / (60 * 60 * 1000));
  const secondString = seconds < 10 ? "0" + seconds : seconds;
  const minuteString = minutes < 10 ? "0" + minutes : minutes;

  if(useHours) return hours.toString() + ":" + minuteString + ":" + secondString;
  return minuteString + ":" + secondString;
}