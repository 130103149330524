import React, { useRef, useState } from "react";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Popover from '@material-ui/core/Popover';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  min-width: 256px;
`;

const Text = styled.p`
  margin: 0;
  margin-bottom: 16px;
`;

interface Props {
  email: string|null;
  business: string|null;
  onClick: () => void;
}

const ProfileIcon: React.FC<Props> = ({
  email,
  business,
  onClick
}) => {
  const [open, setOpen] = useState(false);
  const avatarRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <div ref={avatarRef}>
        <IconButton 
          onClick={() => setOpen(!open)}>
          <Avatar style={{ background: '#1b3a57' }}/>
        </IconButton>
      </div>
      <Popover 
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={avatarRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container>
          <Text>{ email }</Text>
          <Text style={{ fontWeight: "bold" }}>{ business }</Text>
          <Button variant="outlined" onClick={onClick}>Log Out</Button>
        </Container>
      </Popover>
    </>
  )
}

export default ProfileIcon;