import React, { useState, useRef, useContext } from "react";
import styled from "styled-components";
import { updateGameById } from "../../Firebase/games";
import { Game } from "../../Types/games";
import { game as defaultGame } from "../../config/defaultGame";
import { addToStorage2 as addToStorageLocation } from "../../Firebase/helpers";

import TitleBox from "../../Components/Containers/TitleBox";

import BottomBar from "../../Components/Forms/BottomBar";
import CheckBox from "../../Components/Forms/CheckBox";
import TextArea from "../../Components/Forms/TextArea";

import Input from "../../Components/Forms/Input";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import FileDrop from "../../Components/Forms/FileDrop";
import VideoDrop from "../../Components/Forms/VideoDrop";
import LocationPicker from "../../Components/Maps/LocationPicker";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";

import { Context as AuthContext, ContextType } from '../../Context/authContext';

const Container = styled.div`
  padding: 20px;
`;

interface LooseObject {
  [key: string]: any
}

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditInfoScreen: React.FC<Props> = ({ game, dispatch }) => {
  const flash = useFlashMessage();
  const [loading, setLoading] = useState(false);
  const initialState = useRef(game).current;
  const { state } = useContext(AuthContext) as ContextType;

  const [name, setName] = useState(game.name || defaultGame.name);
  const [imageSmall, setImageSmall] = useState(game.imageSmall);
  const [image, setImage] = useState(game.img || defaultGame.img);
  const [imageFile, setImageFile] = useState<File>();
  const [location, setLocation] =  useState(game.location);
  const [distance, setDistance] = useState(game.distance || defaultGame.distance);
  const [duration, setDuration] = useState(game.duration || defaultGame.duration);
  const [difficulty, setDifficulty] = useState(game.difficulty || defaultGame.difficulty);

  const [useVideo, setUseVideo] = useState(game.video != null);
  const [video, setVideo] = useState(game.video);
  const [videoFile, setVideoFile] = useState<File>();
  const [startLocation, setStartLocation] = useState(game.startLocation || defaultGame.startLocation);
  const [coords, setCoords] = useState(game.coords || defaultGame.coords);
  const [storyStart, setStoryStart] = useState(game.storyStart || defaultGame.storyStart);

  async function addFilesToStorage(){
    const data: LooseObject = {
      name,
      location,
      coords,
      startLocation,
      distance,
      duration,
      difficulty,
      storyStart,
      businessName: state.user?.business?.name
    }

    if(imageFile){
      const url = `${game.id}/image`;
      const res = await addToStorageLocation(imageFile, url);
      // const firebaseUrl = await addToStorage2(imageFile, url);
      if(!res.error){
        data.image = url;
        data.img = res;
        data.imageSmall = imageSmall || null;
      } else {
        flash.showFlashMessage(res.message, true);
      }
    }

    if(useVideo){
      if(videoFile){
        const url = `${game.id}/video`;
        const res = await addToStorageLocation(videoFile, url);
        if(!res.error){
          data.videoUrl = res;
          data.video = url;
        } else {
          flash.showFlashMessage(res.message, true);
        }
      }
    } else {
      data.video = null;
      data.videoUrl = null;
    }
  
    return data;
  }

  async function submit(){
    setLoading(true);
    const data = await addFilesToStorage();
    const res = await updateGameById(game.id, data);
    if(res.error){
      flash.showFlashMessage("Something went wrong, please try again", true);
    } else {
      flash.showFlashMessage("Game successfully updated", false);
      dispatch({ type: "UPDATE_GAME", payload: data })
    }
    setLoading(false);
  }

  const reset = () => {
    setName(initialState.name);
    setImageSmall(initialState.imageSmall);
    setImage(initialState.image);
    setImageFile(undefined);
    setLocation(initialState.location);
    setDistance(initialState.distance);
    setDuration(initialState.duration);
    setDifficulty(initialState.difficulty);
    setUseVideo(initialState.video != null);
    setVideo(initialState.video);
    setVideoFile(undefined);
    setStartLocation(initialState.startLocation);
    setCoords(initialState.coords);
    setStoryStart(initialState.storyStart);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash }/> }
      <TitleBox title="Key Information">
        <FormSection>
          <FormExplanation title="Name" text="The name of your game." />
          <Input value={name} onChange={setName} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Image" text="This is the image that will be shown on 'Your Games' page and the Game Briefing. It needs to be 16:9, we would recommend 1280x720 and no larger than 500kb." />
          <FileDrop value={image} file={imageFile} setFile={setImageFile} setBase64={setImageSmall} maxSize={99999999}/>
        </FormSection>
        <FormSection>
          <FormExplanation title="Location" text="Location where the game is to be played, i.e. Norwich." />
          <Input value={location} onChange={setLocation} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Distance" text="Approximate walking distance in miles." />
          <Input type="number" value={distance} onChange={setDistance} step={0.1}/>
        </FormSection>
        <FormSection>
          <FormExplanation title="Duration" text="Approximate time to complete the game in minutes." />
          <Input type="number" value={duration} onChange={setDuration} />
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Type" text="The type of game, one word i.e. easy, medium, hard. (Limited to 10 characters)" />
          <Input type="text" value={difficulty} onChange={setDifficulty} maxLength={10}/>
        </FormSection>
      </TitleBox> 

      <TitleBox title="Briefing">
        <FormSection border={!useVideo}>
          <FormExplanation title="Video" text="Check this box if you would like to add a video to the game briefing. This will be shown instead of the game image." />
          <CheckBox checked={useVideo} onChange={setUseVideo} />
        </FormSection>
        <>
        {
          useVideo
          ? <FormSection>
              <FormExplanation title="" text="Drag and drop a video here" />
              <VideoDrop value={video} file={videoFile} setFile={setVideoFile} />
            </FormSection>
          : null
        }
        </>
        <FormSection>
          <FormExplanation title="Start Location" text="Location where the game begins, could be a building, road name or postcode, etc." />
          <Input value={startLocation} onChange={setStartLocation} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Location" text="Location where the game begins" />
          <LocationPicker coords={coords} setCoords={setCoords} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Game Briefing" text="Text to be shown before the game begins. Use this part to introduce the scene and begin the story. You can use simple html to break your story into parts if you wish." />
          <TextArea rows={3} value={storyStart} onChange={setStoryStart} />
        </FormSection>
      </TitleBox>
      <div style={{ height: 64 }}></div>
      <BottomBar loading={loading} onClick={submit} onCancel={reset}/>
    </Container>
  )
}

export default EditInfoScreen;
