import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { updateGameById, deleteGameById } from "../../Firebase/games";
import { Game } from "../../Types/games";
import { game as defaultGame } from "../../config/defaultGame";
import { addToStorage } from "../../Firebase/helpers";

import TitleBox from "../../Components/Containers/TitleBox";

import BottomBar from "../../Components/Forms/BottomBar";
import CheckBox from "../../Components/Forms/CheckBox";
import Select from "../../Components/Forms/Select";
import DeleteItem from "../../Components/Forms/DeleteItem";
import TextArea from "../../Components/Forms/TextArea";

import Input from "../../Components/Forms/Input";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import FileDrop from "../../Components/Forms/FileDrop";
import VideoDrop from "../../Components/Forms/VideoDrop";
import LocationPicker from "../../Components/Maps/LocationPicker";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";

const Container = styled.div`
  padding: 20px;
`;

interface LooseObject {
  [key: string]: any
}

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditInfoScreen: React.FC<Props> = ({ game, dispatch }) => {
  const history = useHistory();
  const flash = useFlashMessage();

  const [loading, setLoading] = useState(false);
  const [storyEnd, setStoryEnd] = useState(game.storyEnd);

  async function submit(){
    const data = { storyEnd }
    const res = await updateGameById(game.id, data);
    if(res.error){
      flash.showFlashMessage("Something went wrong, please try again", true);
    } else {
      flash.showFlashMessage("Game successfully updated", false);
      dispatch({ type: "UPDATE_STORYEND", payload: data })
    }
    setLoading(false);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash }/> }
      <TitleBox title="Key Information">
      <FormSection border={false}>
          <FormExplanation title="Story Conclusion" text="Text to be shown at the end of the game when complete." />
          <TextArea rows={3} value={storyEnd} onChange={setStoryEnd} />
        </FormSection>
      </TitleBox>

      <div style={{ height: 64 }}></div>
      <BottomBar loading={loading} onClick={submit} />
    </Container>
  )
}

export default EditInfoScreen;
