import { Analytic, Play } from "../Types/games";


type Rating = {
  total: number;
  count: number;
  values: number[];
}

type Sum = {
  total: number;
  count: number;
}

type Time = {
  total: number;
  count: number;
  values: number[];
  starts: number[];
  min: number;
  max: number;
}

type Stats = {
  // how many games have started?
  // include data for complete games or not?
  // need to be per clue as well
  rating: Rating;
  guesses: Sum;
  hints: Sum;
  time: Time;
  complete: Sum; 
  wrongGuesses: any;
}

const initialState: Stats = {
  rating: {
    total: 0,
    count: 0,
    values: [0,0,0,0,0]
  },
  guesses: {
    count: 0,
    total: 0
  },
  hints: {
    count: 0,
    total: 0
  },
  complete: {
    count: 0,
    total: 0
  },
  wrongGuesses: {},
  time: {
    total: 0,
    count: 0,
    min: Infinity,
    max: 0,
    starts: new Array(168).fill(0),
    values: new Array(120).fill(0)
  }
}


const newRating = (oldRating: Rating, rating: number): Rating => {
  const values = [...oldRating.values];
  values[rating-1]++;

  return {
    total: oldRating.total + 1,
    count: oldRating.count + rating,
    values
  }
}

const handleCorrect = (state: Stats, action: any) => {

  if(action.rating){
    return {
      ...state,
      guesses: {
        count: state.guesses.count + 1,
        total: state.guesses.total + 1
      },
      rating: newRating(state.rating, action.rating)
    }
  }

  return {
    ...state,
    guesses: {
      count: state.guesses.count + 1,
      total: state.guesses.total + 1
    }
  }
}

const handleWrong = (state: Stats, action: any) => {
  const wrongGuesses = {...state.wrongGuesses};
  if (action.guess in wrongGuesses){
    wrongGuesses[action.guess]++;
  } else {
    wrongGuesses[action.guess] = 1;
  }
  return {
    ...state,
    guesses: {
      ...state.guesses,
      total: state.guesses.total + 1
    },
    wrongGuesses
  }
}

const handleHint = (state: Stats, action: any) => {
  return {
    ...state,
    hints: {
      count: state.hints.count + 1,
      total: state.hints.total + 1
    }
  }
}

const handleTime = (state: Stats, play: Data): Stats => {
  if(!play.finished) return {
    ...state,
    complete: {
      ...state.complete,
      total: state.complete.total + 1
    }
  };

  const dt = play.end - play.start;
  const mins = Math.floor(dt/60000);
  const newValues = [...state.time.values];
  if(mins < newValues.length){
    newValues[mins]++;
  }

  const t = new Date(play.start);
  const start = t.getHours() + t.getDay()*24;
  const newStarts = [...state.time.starts];
  newStarts[start]++;

  const newTime = {
    min: dt < state.time.min ? dt : state.time.min,
    max: dt > state.time.max ? dt : state.time.max,
    total: state.time.total + 1,
    count: state.time.count + dt,
    values: newValues,
    starts: newStarts
  }

  return {
    ...state,
    time: newTime,
    complete: {
      count: state.complete.count + 1,
      total: state.complete.total + 1
    }
  }
}

const reducer = (state: Stats, action: any) => {
  switch(action.type){
    case 'correct':
      return handleCorrect(state, action);
    case 'wrong':
      return handleWrong(state, action);
    case 'hint':
      return handleHint(state, action);
    default:
      return state;
  }
}

type Data = {
  analytics: Analytic[];
  start: number;
  end: number;
  finished: boolean;
  id: number;
}

const configureAnalytics = (data: any, clue: string) => {
  
  return data.reduce((stats: any, play: any) => {
    if(play.finished){
      const state = play.analytics
      .filter((analytic: any) => analytic.clue === clue)
      .reduce((state: any, analytic: Analytic) => {
        return reducer(state, analytic);
      }, stats);

      return {
        ...state,
        complete: {
          count: state.complete.count + 1,
          total: state.complete.total + 1
        }
      }
    } return {
      ...stats,
      complete: {
        ...stats.complete,
        total: stats.complete.total + 1
      }
    }
  }, initialState);

  return data.reduce((stats: any, analytic: any) => {
    return reducer(stats, analytic);
  }, initialState);

  // return data.reduce((s: Stats, play: Data) => {
  //   if(play.finished){
  //     let _state = play.analytics.reduce((state: Stats, analytic: Analytic) => {
  //       return reducer(state, analytic)
  //     }, s);
  //     return handleTime(_state, play);
  //   }
  //   return handleTime(s, play);
  // }, initialState);
}

export default configureAnalytics;