import React from "react";
import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config/constants";

const drawerWidth = 256;
const menuHeight = 64;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - ${menuHeight}px);
  overflow: auto;
  margin-top: ${menuHeight}px;
  padding: 20px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: calc(100vw - ${drawerWidth}px);
  }
`;

interface Props {
  children?: React.ReactNode;
}

const Body: React.FC<Props> = ({
  children
}) => {
  return (
    <Container>
      { children }
    </Container>
  )
}

export default Body;