import { RefObject, useEffect, useRef, useState } from "react";

interface Dimensions {
  width: number;
  height: number;
}

type useDimensionsReturnType<T> = [
  ref: RefObject<T>,
  dimensions: Dimensions,
];

function useDimensions<T>(): useDimensionsReturnType<T> {
  const ref = useRef<any>(null); // @TODO - type properly
  const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

  useEffect(() => {
    const width = ref.current?.offsetWidth || 0;
    const height = ref.current?.offsetHeight || 0;
    setDimensions({ width, height });
  }, []);

  return [ref, dimensions];
};

export default useDimensions;
