import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { BsInfoCircleFill } from 'react-icons/bs';
import Popover from '@material-ui/core/Popover';

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const Container = styled.div`
  min-width: 300px;
  max-width: 400px;
  padding: 20px;
`;

interface Props {
  children: React.ReactChild;
}

const InfoPopOver: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const infoRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <IconWrapper onClick={() => setOpen(true)}>
        <BsInfoCircleFill />
      </IconWrapper>
      <Popover 
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={infoRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Container>
          { children }
        </Container>
      </Popover>
    </>  
  )
}

export default InfoPopOver;