import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 5px;
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: bold;
`;

const List = styled.ul`
  padding: 0;
  max-height: 200px;
  overflow: scroll;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Item = styled.p`
  margin: 0;
`;

interface Props {
  data: any;
}

const WrongGuessTable: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <Title>Wrong Guesses</Title>
      <List>
        {
          data
          // .filter((guess: any) => guess[1] > 3)
          .map((guess: any) => {
            return (
              <ListItem key={guess[0]}>
                <Item>{ JSON.parse(guess[0]).map((v: any) => String(v)).join() }</Item>
                <Item>{ guess[1] }</Item>
              </ListItem>
            )
          })
        }
      </List>
    </Container>
  )
}

export default WrongGuessTable;