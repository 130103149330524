import React from "react";
import { Switch, Route } from "react-router-dom";

import HomeScreen from "../Screens/HomeScreen";
import DashboardScreen from "../Screens/Dashboard/DashboardScreen";
import GamesNavigator from "./GamesNavigator";
import PlaysNavigator from "./PlaysNavigator";
import StatisticsNavigator from "./StatisticsNavigator";
import UsersNavigator from "./UsersNavigator";
import BusinessesNavigator from "./BusinessesNavigator";
import AccountScreen from "../Screens/Account/AccountScreen";
import ContactScreen from '../Screens/Contact/ContactScreen';
import ForbiddenScreen from "../Screens/Auth/ForbiddenScreen";

const TabsNavigator = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomeScreen} />
      <Route path="/dashboard" component={DashboardScreen} />
      {/* <Route path="/businesses/:businessId/games" component={GamesNavigator} /> */}
      <Route path="/statistics" component={StatisticsNavigator} />
      <Route path="/plays" component={PlaysNavigator} />
      <Route path="/users" component={UsersNavigator} />
      <Route path="/businesses" component={BusinessesNavigator} />
      <Route path="/account" component={AccountScreen} />
      <Route path="/contact" component={ContactScreen}/>
      <Route path="/forbidden" component={ForbiddenScreen}/>
    </Switch>
  );
}

export default TabsNavigator;