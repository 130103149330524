import React, { useState } from "react";
import styled from "styled-components";

import { Game } from "../../Types/games";
import { theme as defaultTheme } from "../../config/defaultGame";
import { updateGameById } from "../../Firebase/games";
import { addToStorage } from "../../Firebase/helpers";

import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import TitleBox from "../../Components/Containers/TitleBox";
import TextArea from "../../Components/Forms/TextArea";
import Select from "../../Components/Forms/Select";
import ColorInput from "../../Components/Forms/ColorInput";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import CheckBox from "../../Components/Forms/CheckBox";
import FileDrop from "../../Components/Forms/FileDrop";
import BottomBar from "../../Components/Forms/BottomBar";
import Warning from "../../Components/Containers/Warning";

import ThemeBox from "../../Components/Theme/ThemeBox";

const iconOptions = [
  { name: "Map Balloon Question", value: "map-marker-question-outline" },
  { name: "Ship Wheel", value: "ship-wheel" },
  { name: "Shield", value: "shield-cross-outline" },
  { name: "Sci-Fi", value: "death-star-variant" },
  { name: "Map Balloon", value: "map-marker" },
  { name: "Pirate", value: "pirate" },
  { name: "Swords", value: "sword-cross" },
  { name: "Magnifying Glass", value: "magnify" },
  { name: "Crosshairs", value: "crosshairs-gps" },
  { name: "Hat", value: "hat-fedora" }
]

const Container = styled.div`
  padding: 20px;
`;

const IconLink = styled.a`
  padding: 20px;
  text-decoration: none;
`;

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditThemeScreen: React.FC<Props> = ({ game, dispatch }) => {
  const theme = game.theme || defaultTheme;
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState(game.theme);
  const flash = useFlashMessage();
  
  const [primary, setPrimary] = useState(theme.primary || defaultTheme.primary);
  const [secondary, setSecondary] = useState(theme.secondary || defaultTheme.secondary);
  const [tertiary, setTertiary] = useState(theme.tertiary || defaultTheme.tertiary);
  const [map, setMap] = useState(theme.map || defaultTheme.map);
  const [mapIcon, setMapIcon] = useState(theme.mapIcon || defaultTheme.mapIcon); // to remove
  const [icon, setIcon] = useState(theme.icon || defaultTheme.icon);
  const [mapImageFile, setMapImageFile] = useState<File>();
  const [iconColor, setIconColor] = useState(theme.iconColor || defaultTheme.iconColor); //  to remove

  function handleCancel(){
    setPrimary(initialState.primary);
    setSecondary(initialState.secondary);
    setTertiary(initialState.tertiary);
    setMap(initialState.map);
    setIcon(initialState.icon);
    setMapImageFile(undefined);
  }

  async function submitFiles(){
    let imagePath;
    if(mapImageFile && icon.type === "image"){
      imagePath = await addToStorage(mapImageFile, game.id);
      return {
        type: "image",
        name: imagePath
      }
    }

    return icon;
  }

  async function submit(){
    const _icon = await submitFiles();

    const data = {
      theme: {
        primary,
        secondary,
        tertiary,
        map,
        icon: _icon,
        mapIcon, // to remove
        iconColor
      }
    };

    const res = await updateGameById(game.id, data);
    if(res.error){
      flash.showFlashMessage("Something went wrong, please try again", true);
    } else {
      flash.showFlashMessage("Theme successfully updated", false);
      dispatch({ type: "UPDATE_GAME", payload: data });
    }
    setLoading(false);

  }

  function handleMapTheme(googleTheme: string){
    const data = googleTheme.replace(/\s/g, "");
    try {
      const json = JSON.stringify(JSON.parse(data));
      setMap(data);
    } catch(err){
      flash.showFlashMessage("Theme data not recognised", true);
      setMap("");
    }
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <ThemeBox theme={theme}/>

      <TitleBox title="Primary Colour">
        <FormSection>
          <FormExplanation title="Primary Colour" text="Choose the primary colour. This will be used throughout the app" />
          <ColorInput
            value={primary.color} 
            onChange={(color: string) => setPrimary({ color, font: primary.font })} 
          />
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Primary Text Colour" text="Choose the primary colour. This will be used throughout the app" />
          <ColorInput
            value={primary.font} 
            onChange={font => setPrimary({ color: primary.color, font })} 
          />
        </FormSection>
      </TitleBox>

      <TitleBox title="Secondary Colour">
        <FormSection>
          <FormExplanation title="Secondary Colour" text="Choose the secondary colour. This will be used throughout the app" />
          <ColorInput
            value={secondary.color} 
            onChange={color => setSecondary({ color, font: secondary.font })} 
          />
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Secondary Text Colour" text="Choose the secondary colour. This will be used throughout the app" />
          <ColorInput
            value={secondary.font} 
            onChange={font => setSecondary({ color: secondary.color, font })} 
          />
        </FormSection>
      </TitleBox>

      <TitleBox title="Tertiary Colour">
        <FormSection>
          <FormExplanation title="Tertiary Color" text="Choose the tertiary colour. This will be used throughout the app" />
          <ColorInput 
            value={tertiary.color} 
            onChange={color => setTertiary({ color, font: tertiary.font })} 
          />
        </FormSection>
        <FormSection border={false}>
          <FormExplanation title="Tertiary Text Color" text="Choose the tertiary colour. This will be used throughout the app" />
          <ColorInput 
            value={tertiary.font} 
            onChange={font => setTertiary({ color: tertiary.color, font })} 
          />
        </FormSection>
      </TitleBox>

      <TitleBox title="Map Theme">
        <FormSection>
          <FormExplanation title="Map Icon Color" text="Choose the map icon colour. This will be used for the markers on the map." />
          <ColorInput
            value={icon.color} 
            onChange={e => setIcon({ ...icon, color: e })} 
          />
        </FormSection>
        {/* <FormSection border={false}>
          <FormExplanation title="Icon" text="Check this box if you would like to use an icon from the library as a map marker."/>
          <CheckBox checked={icon.type === "icon"} onChange={e => setIcon({ ...icon, type: e ? "icon" : "image" })} />
        </FormSection> */}
        <>
        {
          icon.type === "icon"
          ? <FormSection>
              <FormExplanation title="Map Icon" text="Choose the icon that will be shown in the markers on the map." />
              <Select
                value={icon.name}
                onChange={e => setIcon({ ...icon, name: e })}
                options={iconOptions}
              />
              {
                icon.name &&
                <IconLink 
                  href={`https://icons.expo.fyi/MaterialCommunityIcons/${icon.name}`} 
                  target='_blank'  
                >View</IconLink>
              }
            </FormSection>
          : <FormSection>
              <FormExplanation title="Map Image" text="Select a small image to use as a map icon, it needs to have a transparent background" />
              <FileDrop value={icon.name} file={mapImageFile} setFile={setMapImageFile} />
            </FormSection>
        }
        </>      
        <Warning text="Visit <a style='color: inherit; text-decoration: none; font-weight: bold;' href='https://snazzymaps.com/' target='_blank'>snazzymaps</a> to find and edit google maps styles" type="info" />
        <FormSection border={false}>
          <FormExplanation title="Map Style" text="Paste google maps styles in here. You can find some examples on snazzy maps." />
          <TextArea 
            rows={3}
            value={map} 
            onChange={handleMapTheme} 
          />
        </FormSection>
      </TitleBox>
      <div style={{ height: 64 }}></div>
      <BottomBar loading={loading} onCancel={handleCancel} onClick={submit} />
    </Container>
  )
}

export default EditThemeScreen;

