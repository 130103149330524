import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: space-between;
  height: 64px;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid rgb(198, 190, 207);
  box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
  overflow-x: scroll;
`;

interface Props {
  children?: React.ReactNode
}

const HeaderTabs: React.FC<Props> = ({
  children
}) => {
  return (
    <Container>
      { children }
    </Container>
  )
}

export default HeaderTabs;