import React, { useReducer } from "react";

interface LooseObject {
  [key: string]: any
}

export default (reducer: any, actions: any, defaultValue: any) => { // fix
	const Context = React.createContext({});

	const Provider: React.FC<{ children: React.ReactNode}> = ({ children }) => {
		const [state, dispatch] = useReducer(reducer, defaultValue);

		const boundActions: LooseObject = {};
		for(let key in actions){
			boundActions[key] = actions[key](dispatch);
		}

		return (
			<Context.Provider value={{ state, ...boundActions }}>
				{ children }
			</Context.Provider>
		);
	};
	
	return { Context, Provider };
}
