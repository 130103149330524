import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getGamesWhere } from "../../../Firebase/games";
import { createMultipleVouchers } from "../../../Firebase/credits";
import { CSVLink } from "react-csv";

import FormSection from "../../Forms/FormSection";
import FormExplanation from "../../Forms/FormExplanation";
import TitleBox from "../../Containers/TitleBox";
import Input from "../../Forms/Input";
import LoadingButton from "../../Buttons/LoadingButton";
import Select from "../../Forms/Select";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";

const FormRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface Props {
  business: string;
  showFlashMessage?: (val: string, error: boolean) => void;
}

const CreateVoucherCode: React.FC<Props> = ({
  business,
  showFlashMessage
}) => {
  const [reference, setReference] = useState("");
  const [amount, setAmount] = useState(0);
  const [games, setGames] = useState<any|null>();
  const [game, setGame] = useState("");

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<null|any[]>();

  const create = async () => {
    setLoading(true);
    const res = await createMultipleVouchers(business, game, reference, amount);
    if(res.error){
      // showFlashMessage(res.message, true);
    } else {
      setCsvData(res.payload);
      // showFlashMessage(res.message, false);
    }
    setLoading(false);
  }

  useEffect(() => {
    const getGames = async () => {
      const res = await getGamesWhere("business", business);
      if(res.error){
        console.log(res.message);
      } else {
        setGames(res);
      }
    } 

    getGames();
  }, []);

  return (
    <TitleBox title="Create multiple vouchers">
      <FormSection>
        <FormExplanation title="Create multiple vouchers" text="Use this form to create more than one voucher" />
        <FormRow>
          <div>
            <div style={{ marginBottom: 10 }}>
              <Input value={reference} onChange={setReference} placeholder="Reference" />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input value={amount} type="number" onChange={setAmount} placeholder="Amount" />
            </div>
            { 
              games && 
              <Select 
                value={game} 
                onChange={setGame} 
                options={games.map((g: any) => ({ name: g.name, value: g.id }))} 
              /> 
            }
          </div>
          <ButtonWrapper>
            {
              csvData &&
              <CSVLink data={csvData}>
                <IconButton color="primary">
                  <DownloadIcon />
                </IconButton>
              </CSVLink>
            }
            <LoadingButton 
              style={{ height: 50.4, marginLeft: 20 }}
              loading={loading} 
              setLoading={setLoading} 
              onClick={create} 
              text="Create"
            />
          </ButtonWrapper>
        </FormRow>
      </FormSection>  
    </TitleBox>
  )
}

export default CreateVoucherCode;