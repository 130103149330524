import React, { useContext, useState } from "react";
import styled from "styled-components";

import { Context as AuthContext, ContextType } from "../../Context/authContext";
import TitleBox from "../../Components/Containers/TitleBox";
import IconList from "../../Components/Lists/IconList";
import Templates from "../../Components/Forms/Emails/Templates";
import defaultBusiness from '../../config/defaultBusiness';
import FlashMessage, { useFlashMessage } from '../../Components/FlashMessage/FlashMessage';
import { updateBusiness } from '../../Firebase/businesses';

const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 1000px;
  margin: 20px;
`;

const SideBar = styled.div`
  width: 300px;
  background-color: #fafafa;
  border-right: 1px solid rgb(198,190,207);
`;

const Body = styled.div`
  display: flex;
  min-height: 400px;
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
`;

const TemplateWrapper = styled.div<{active:boolean}>`
  display: ${props => props.active ? "flex" : "none"}
`;


interface Template {
  title: string;
  data: {
    sender: string;
    replyTo: string;  
    subject: string;
    message: string;
  }
}

const AccountEmailScreen = () => {
  const { state } = useContext(AuthContext) as ContextType;
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const flash = useFlashMessage();

  const { business } = state.user;
  const templateNames = business.templates.map((temp: Template) => temp.title);

  const emails = defaultBusiness.templates.map(template => {
    return templateNames.indexOf(template.title) > -1
    ? business.templates.find((temp: any) => temp.title === template.title)
    : template
  });

  const handleSubmit = async (title: string, data: any) => {

    const templates = emails.map((temp: Template) => {
      return temp.title === title
      ? { title, data }
      : temp
    })

    const res = await updateBusiness(business.id, { 
      ...business,
      templates
    });

    // need to setUser as new business value

    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      flash.showFlashMessage(res.message, false);
    }

    setLoading(false);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <TitleBox title="Emails">
        <Body>
          <SideBar>
            {
              emails.map((email: any, i: number) => {
                return <IconList 
                  key={email.title}
                  title={email.title} 
                  onClick={() => setActive(i)} 
                  active={i === active} 
                />
              })
            }
          </SideBar>
          <Content>
            {
              emails.map((email: any, i: number) => {
                return (
                  <TemplateWrapper 
                    key={email.title}
                    active={i === active}
                  >
                    <Templates 
                      loading={loading}
                      setLoading={setLoading}
                      data={email.data} 
                      onSubmit={data => handleSubmit(email.title, data)} 
                    />
                  </TemplateWrapper>
                )
              })
            }
          </Content>
        </Body>
      </TitleBox>
    </Container>
  )
}

export default AccountEmailScreen;