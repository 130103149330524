import React, { useState } from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";

const Container = styled.div`
  
`;

const Row = styled.div`
  display: flex;
`;

interface Props {
  solution: any;
  config: {
    rows: number;
    cols: number;
  };
  setSolution: (value: any) => void;
}

const BooleanSolution: React.FC<Props> = ({ solution, config, setSolution }) => {

  const handleChange = (num: number, event: any) => {
    const newSolution = solution.map((val: boolean, i: number) => {
      return i !== num ? val : event.currentTarget.checked;
    })
    setSolution(newSolution);
  }

  return (
    <Container>
      {
        [...Array(config.rows)].map((_,i) => {
          return (
            <Row>
              {
                [...Array(config.cols)].map((_,j) => {
                  let val = j+i*config.cols;
                  return <Checkbox checked={solution[val]} onChange={(e) => handleChange(val,e)}/>
                })
              }
            </Row>
          )
        })
      }
    </Container>
  )
}

export default BooleanSolution;