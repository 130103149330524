import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LooseObject } from '../../Types/common';
import { getFromStorage } from "../../Firebase/helpers";
import getBase64Image from "../../Hooks/useGetBase64Image";
import WarningIcon from "@material-ui/icons/Warning";

const Wrapper = styled.div``;

const Error = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Container = styled.div<{ focused: boolean}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  max-height: 225px;
  border: ${props => props.focused ? "2px solid #1b3a57 " : "1px solid #ddd"};
  border-radius: 5px;
  background: white;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

interface FileDropProps {
  value?: string; // Current file url
  file?: File; // file Object
  setFile: (file: File) => void; // set file object
  setBase64?: (image: string) => void; // base64 file url
  type?: 'image' | 'audio' | 'video';
  maxSize?: number;
}

const FileDrop: React.FC<FileDropProps> = ({
  value,
  file,
  setFile,
  setBase64,
  type='image',
  maxSize=10485760 // 5mb
}) => {
  const [errorMessage, setErrorMessage] = useState<null|string>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(); // used to show the image in preview
  const [focused, setFocused] = useState(false);

  console.log(value, '<<< value');

  // const setupImage = async (path: string) => {
  //   const url = await getFromStorage(path);
  //   setFileUrl(url);
  // }

  // useEffect(() => {
  //   if(value && !file){
  //     setupImage(value);
  //   }
  // }, []);

  useEffect(() => {
    if(file){
      const url = URL.createObjectURL(file);
      setFileUrl(url)
    }
  }, [file]);

  const handleFiles = async (file: File): Promise<void> => {
    setErrorMessage(null);
    if(validateFile(file)){
      setFile(file);
      if(setBase64){
        try{
          const dataurl = await getBase64Image(file) as any;
          setBase64(dataurl);
        } catch(err){
          console.log(err);
        }
      }
    }
  }

  const validateFile = (file: File): boolean => {
    const validTypes: LooseObject = {
      'image': ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
      'audio': ['audio/mpeg', 'video/mp4'],
      'video': ['video/mp4']
    }

    if (validTypes[type].indexOf(file.type) === -1){
      setErrorMessage("Invalid file type");
      return false;
    }
    if (file.size > maxSize) { 
      setErrorMessage("File size too large")
      return false 
    };
    return true;
  }

  const handleDrop = (event: React.DragEvent): void => {
    event.preventDefault();
    const data = event.dataTransfer.files;
    if(data.length){
      handleFiles(data[0]);
    }
    setFocused(false);
  }

  const handleDragOver = (event: React.DragEvent): void => {
    event.preventDefault();
    setFocused(true);
  }

  const handleDragLeave = (event: React.DragEvent): void => {
    event.preventDefault();
    setFocused(false);
  }

  return (
    <Wrapper>
      { errorMessage && <Error><WarningIcon color="error" />{ errorMessage }</Error> }
      <Container 
        focused={focused}
        onDrop={handleDrop}
        onDragOver={handleDragOver}  
        onDragLeave={handleDragLeave}
      >
        {
          fileUrl || value
          ? (type==='image' 
            ? <Image src={fileUrl || value} />
            : fileUrl !== null && <Video key={fileUrl || value} controls>
                <source 
                  src={fileUrl || value}
                  type="video/mp4"
                >
                </source>
              </Video>)
          : <p>Drag and drop files here</p>
        }
      </Container>
    </Wrapper>
  )
}

export default FileDrop;
