import React, { useEffect, useRef, useState } from "react";
import Map from 'google-map-react';
import Marker from './Marker';
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
`;

const defaultCoords = {
  latitude: 51.476852,
  longitude: -0.000500,
};

type Coords = {
  latitude: number;
  longitude: number;
}

const mapCoords = (coords: Coords) =>({
  lat: coords.latitude,
  lng: coords.longitude
});

interface Props {
  coords?: Coords;
  setCoords: (coords: Coords) => void;
}

const LocationPicker: React.FC<Props> = ({
  setCoords,
  coords,
}) => {
  
  const [userLocation, setUserLocation] = useState<Coords>();
  const defaultCenter = useRef(mapCoords(coords || userLocation || defaultCoords));

  useEffect(() => {
    navigator.geolocation?.getCurrentPosition((location) => {
      setUserLocation(location.coords);
    });
  }, []);

  const handleClick = (e: any) => {
    setCoords({
      latitude: e.lat,
      longitude: e.lng,
    });
  }

  return (
    <Container>
      <Map
        bootstrapURLKeys={{ key: 'AIzaSyABcfoTPNxkd6byi9jwwU-XSq0E6tfE8y4' }}
        defaultCenter={defaultCenter.current}
        defaultZoom={14}
        onClick={handleClick}
      >
         { coords && <Marker lat={coords.latitude} lng={coords.longitude} /> }
      </Map>
    </Container>
  )
}

export default LocationPicker;
