import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { Analytic, Game, Play } from '../../Types/games';
import { getAnalyticsByPlay } from '../../Firebase/analytics';

import Header from '../../Components/Header/Header';

import ClueBox from '../../Components/TimeLine/ClueBox';
import { getGameAndCluesById } from '../../Firebase/games';
import { getPlayById, getPlaysWhere } from '../../Firebase/plays';

import configureTimelineData from '../../helpers/configureTimelineData';
import TitleBox from '../../Components/Containers/TitleBox';
import TimeLineOverview from '../../Components/TimeLine/TimeLineOverview';

const Container = styled.div`
  /* padding: 20px; */
`;

const Body = styled.div`
  box-sizing: border-box;
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow: auto;
`;

const TimelineContainer= styled.div`
  position: relative;
  padding: 20px;
`;

const TimelineBar = styled.div<{ color: string }>`
  position: absolute;
  z-index: 1;
  left: 49px;
  top: -17px;
  width: 4px;
  height: calc(100% + 20px);
  background: ${props =>props.color};
`;

const Overview = styled.div`
  padding: 20px;
  padding-bottom: 0;
  max-width: 1000px;
`;

const Text = styled.p``;

interface StateProps {
  teamname: string;
}

const Timeline = () => {
  const history = useHistory();
  const location = useLocation<StateProps>();
  const params = useParams<{ playId: string }>();

  const [game, setGame] = useState<null | Game>(null);
  const [play, setPlay] = useState<null | Play>(null);
  const [analytics, setAnalytics] = useState<null | Analytic[]>(null);
  const [data, setData] = useState<null | any>();

  useEffect(() => {
    if(game && play && analytics){
      setData(configureTimelineData(game, play, analytics));
    }
  }, [game, play, analytics]);

  useEffect(() => {
    async function getGame(){
      if(play){
        const res = await getGameAndCluesById(play.game);
        setGame(res);
      }
    }
    
    getGame();
    
  }, [play]);

  useEffect(() => {
    async function getPlay(){
      const res = await getPlayById(params.playId);
      setPlay(res);
    }

    async function getAnalytics(){
      const res = await getAnalyticsByPlay(params.playId);
      setAnalytics(res);
    }

    getPlay();
    getAnalytics();
  }, [params.playId]);

  // console.log(game);
  // console.log(play);
  // console.log(analytics);

  if(!data) return <Container>
    <Header title='Play'/>
  </Container>

  return (
    <Container>

        <Overview>
          <TitleBox title='Overview'>
            <TimeLineOverview 
              play={play} 
              game={game}
              data={data}
            />
          </TitleBox>
        </Overview>
        <TimelineContainer>
          <TimelineBar color='#1b3a57'/>
          {
            data.map((clue: any) => {
              return (
                <ClueBox {...clue} />
              )
            })
          }
        </TimelineContainer>
    </Container>
  )
}

export default Timeline;