import React, { useContext } from "react";
import styled from "styled-components";
import Body from "../Components/Body/Body";
import TitleBox from "../Components/Containers/TitleBox";
import Header from "../Components/Header/Header";
import { Context as AuthContext, ContextType } from '../Context/authContext';

const Container = styled.div`
  height: 100vh;
  width: 100%;
`;

// Time of play
// Number of live games - count total active games
// Plays per month over year - count all plays where business == user.business.id
// Plays per week over last quarter - count all plays where business == user.business.id
// Star rating per month over year - sum all analytics where game in business.games
// Star rating per week over last quarter - sum all analytics where game in business.games
// Total steps tally - sum product of plays with game.distance

const HomeScreen: React.FC = () => {

  const { state } = useContext(AuthContext) as ContextType;

  return (
    <Container>
      <Header title="Home" />
      <Body>
        <TitleBox title={state.user.business?.name}>

        </TitleBox>
      </Body>
    </Container>
  )
}

export default HomeScreen;