import React, { useEffect, useState } from "react";
import FormSection from "../FormSection";
import FormExplanation from "../FormExplanation";
import Input from "../Input";
import CheckBox from "../CheckBox";
import Select from "../Select";

interface DecoderConfigProps {
  value: string | null;
  setValue: (e: string) => void;
}

const DecoderConfig: React.FC<DecoderConfigProps> = ({ value, setValue }) => {

  const defaultConfig = {
    start: [0,0],
    segments: 26,
    type: 'letters'
  }

  const [config, setConfig] = useState(value && JSON.parse(value));
  
  useEffect(() => {
    if(value === null){
      setConfig(defaultConfig)
    }
  }, [value]);

  useEffect(() => {
    setValue(JSON.stringify(config));
  }, [config]);

  return (
    <div>
      {
        config && 
        <>
          <FormSection border={false}>
            <FormExplanation title="Configuration"/>
          </FormSection>
          <FormSection border={false}>
            <FormExplanation title="Type" text="Would you like the decoder to have letters or numbers?" />
            <Select
              value={config.type}
              options={[
                { name: 'letters', value: 'letters' }, 
                { name: 'numbers', value: 'numbers '}
              ]}
              onChange={e => setConfig({ ...config, type: e })}
            />
          </FormSection>
        </>
      }
    </div>
  )
}

export default DecoderConfig;