import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation, useParams } from 'react-router';
import { getCluesFromGameId, getGamesWhere } from "../../Firebase/games";
import { Game, Clue } from "../../Types/games";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';

import Header from "../../Components/Header/Header";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { getAnalyticsByGame } from "../../Firebase/analytics";
import { getPlaysByGameAndDate, getStatistics } from "../../Firebase/plays";
import StatsBox from '../../Components/Statistics/StatsBox';
import SmallStatsRectangle from "../../Components/Statistics/SmallStatsRectangle";
import ChoiceButton from '../../Components/Statistics/ChoiceButton';
import Warning from "../../Components/Containers/Warning";

import GameStatisticsScreen from './GameStatisticsScreen';
import ClueStatisticsScreen from './ClueStatisticsScreen';
import Breadcrumbs from "../../Components/Navigation/Breadcrumbs";
import { DateAdapter } from "chart.js";
import useIsMobile from '../../Hooks/useIsMobile';
import { MEDIA_QUERIES } from "../../config/constants";
import Icon from "../../Components/Icons/Icon";

const Title = styled.p<{ size: number }>`
  font-weight: bold;
  font-size: ${props => props.size}px;
  color: #4b4b4b;
  margin: 5px;
`;

const Name = styled.span`
  color: black;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

const IconWrapper = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
  width: 40px;
  height: 40px;
  border: none;
  background: none;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-top: 64px;
  height: calc(100vh - 64px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
`;

const TitleBox = styled.div`
  width: 100%;
`;

const slideIn = (x: number) => keyframes`
  0% { transform: translateX(0px); }
  100% { transform: translateX(${x}px); }
`;

const FilterList = styled.div<{ showing: boolean }>`
  display: ${props => props.showing ? 'initial' : 'none'};
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  background: #f8f8f8;
  width: 100%;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    position: static;
    display: block;
    width: 256px;
    border-right: 1px solid rgb(198,190,207);
    box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
  }
`;

const Item = styled.div`
  padding: 10px;
`;

const ItemTitle = styled.p`
  font-weight: bold;
  color: #4b4b4b;
`;

const Stats = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: calc(100vw - 512px);
  }
`;

const StatisticsScreen: React.FC = () => {
  const params = useParams() as { businessId: string };

  const isMobile = useIsMobile();
  const [filterShowing, setFilterShowing] = useState(true);
  const [games, setGames] = useState<Game[]>([]);
  const [plays, setPlays] = useState([]);
  const [analytics, setAnalytics] = useState<object[]>([]);

  const [data, setData] = useState<Object[]>([]);
  const [choice, setChoice] = useState('Game');
  const [chosenGame, setChosenGame] = useState('');
  const [chosenClue, setChosenClue] = useState('');
  const [date, setDate] = useState<Date|[Date,Date]>(() => {
    let s = new Date();
    let e = new Date();
    s.setHours(0,0,0,0);
    e.setHours(23,59,59,999);
    return [s,e];
  });

  const game = games.length > 0 && games.find(game => game.id === chosenGame);

  useEffect(() => {
    async function getData(){
      if(chosenGame !== '' && Array.isArray(date)){
        const res = await getStatistics(chosenGame, date);
        if(!res.error){
          setData(res);
        }
      }
    }

    getData();

  }, [chosenGame, date]);

  useEffect(() => {
    if(game && game.clues.length > 0)
      setChosenClue(game.clues.sort((a,b) => a.order - b.order)[0].id);
  }, [chosenGame, game]);

  useEffect(() => {
    async function getGames(){
      const res = await getGamesWhere('business', params.businessId);
      if(!res.error){
        const games = await Promise.all(res.map(async (game: any) => {
          const clues = await getCluesFromGameId(game.id) as Clue[];
          return {
            ...game,
            clues
          }
        })) as Game[];
        setGames(games);
      }
    }

    getGames();
  }, [params.businessId]);

  const getName = (id: string) => {
    if (games.length){
      let game = games.find(game => game.id === id);
      return game?.name;
    }
    return;
  }

  const getDateString = () => {
    if(Array.isArray(date)){
      const start = new Date(date[0]);
      const end = new Date(date[1]);
      return `${start.toLocaleDateString()} to ${end.toLocaleDateString()}`;
    } 
    const d = new Date(date);
    return d.toLocaleDateString();
  }

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setChosenGame(e.target.value as any);
  }

  const renderData = () => {
    return data.length > 0
      ? (choice === 'Game'
        ? <GameStatisticsScreen 
            game={game}
            data={data}
          />
        : chosenClue !== ''
          ? <ClueStatisticsScreen
            game={games.find(game => game.id === chosenGame)}
            clue={chosenClue}
            data={data}
          />
          : null
        )
      : <Warning
          type='info'
          text='Select a game and a timeframe from the left'
        />;
  }

  return (
    <Container>
      {
        isMobile &&
        <IconWrapper onClick={() => setFilterShowing(!filterShowing)}>
          <Icon name={filterShowing ? "cross" : "filter"} size={24} />
        </IconWrapper>
      }
      <FilterList showing={filterShowing}>
        <Item>
          <ItemTitle>Game</ItemTitle>
          {
            games.length > 0 &&
            <FormControl
              variant='outlined'
              style={{ width: '100%', background: 'white' }}
            >
              <Select
                value={chosenGame}
                onChange={handleChange}
              >
                {
                  games.map(game => {
                    return (
                      <MenuItem 
                        key={game.id} 
                        value={game.id}
                      >{ game.name }</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          }
        </Item>
        <Item>
          <ItemTitle>Date</ItemTitle>
          <Calendar 
            value={date} 
            onChange={setDate}
            selectRange={true}
          />
        </Item>
        <Item>
          <ItemTitle>Type</ItemTitle>
          <ChoiceButton 
            value={choice} 
            onChange={setChoice} 
            choices={['Game', 'Puzzles']}
          />
            {
              choice === 'Puzzles' && 
              <FormControl
                variant='outlined'
                style={{ width: '100%', background: 'white' }}
              >
                <Select
                  value={chosenClue}
                  onChange={(e: any) => setChosenClue(e.target.value)}
                >
                  {
                    game && game.clues.sort((a,b) => a.order - b.order).map(clue => {
                      return <MenuItem key={clue.id} value={clue.id}>{ clue.title }</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            }
        </Item>
      </FilterList>
      <Stats>
        { renderData() }
      </Stats>
    </Container>    
  )
}

export default StatisticsScreen;