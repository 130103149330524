import { Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Btn = styled(Button)`
  font-weight: bold;
  background: ${props => props.theme.colors.primary.main};
`

const Text = styled.p``;

interface Props {
  
}

const ForbiddenScreen: React.FC<Props> = () => {
  const history = useHistory();

  return (
    <Container>
      <Text>Sorry this page is protected</Text>
      <Btn
        style={{ background: '#35AC8C', fontWeight: 'bold', color: 'white' }}
        variant='contained'
        onClick={history.goBack}
      >Go Back</Btn>
    </Container>
  )
}

export default ForbiddenScreen;