import React from "react";
import styled from "styled-components";
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div<{ color: string, backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  border-bottom: 1px solid #ddd;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Bold = styled.p`
  text-transform: capitalize;
  font-weight: bold;
  margin: 0 20px;
`;

const Text = styled.div`
  margin: 0;
`;

interface Props {
  text: string;
  type?: string;
}

// green/yellow/blue/red
const Warning: React.FC<Props> = ({ text, type="warning" }) => {

  function getStyle(type: string){
    switch(type){
      case "warning":
        return { backgroundColor: "#fff4e5", color: "#ff9800", icon: <WarningIcon/> }
      case "info":
        return { backgroundColor: "#e8f4fd", color: "#2196f3", icon: <InfoIcon/> }
      case "error":
        return { backgroundColor: "#fdecea", color: "#f44336", icon: <ErrorIcon/> }
      case "success":
        return { backgroundColor: "#edf7ed", color: "#4caf50", icon: <SuccessIcon/> }
      default:
        return { backgroundColor: "#edf7ed", color: "#4caf50", icon: <SuccessIcon/> }
    }
  }

  const style = getStyle(type);

  return (
    <Container
      backgroundColor={style.backgroundColor}
      color={style.color}
    >
      <IconWrapper>
        { style.icon }
        <Bold>{ type }:</Bold>
      </IconWrapper>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Container>
  )
}

export default Warning;