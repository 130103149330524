import React from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { MEDIA_QUERIES } from '../../config/constants';
import useDimensions from '../../Hooks/useDimensions';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 280px;
  background: #fcfcfc;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 500px;
    margin-right: 10px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  color: #4b4b4b;
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
`;

interface Props {
  data: number[];
  title: string;
  subtitle: string;
  colors: string[];
}

const BarChartInfoBox: React.FC<Props> = ({
  data,
  title,
  subtitle,
  colors
}) => {
  const [ref, { width }] = useDimensions<HTMLDivElement>();

  let max = 0;
  for (let i=0; i<data.length; i++){
    if (data[i] !== 0){
      max = i;
    }
  }

  const configuredData = {
    labels: [...new Array(max+1)].map((_,i) => i+1),
    datasets: [
      {
        data,
        backgroundColor: colors,
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const chartWidth = width < 540 ? width - 60 : 480;

  return (
    <Container ref={ref}>
      <Title>{ title }</Title>
      <SubTitle>{ subtitle }</SubTitle>
      <ChartWrapper>
        {
          width &&
          <Bar 
            // type='bar' 
            data={configuredData} 
            width={chartWidth}
            height={180}
            // options={options}
          />    
        }
        
      </ChartWrapper>
    </Container>
  )
}

export default BarChartInfoBox;