import React from 'react';
import styled from 'styled-components';
import { Modal as MUIModal, Button } from '@material-ui/core';
import { Credit } from '../../Types/credits';
import CreateMultipleVouchers from '../Forms/Businesses/CreateMultipleVouchers';
import CreateVoucherCode from '../Forms/Businesses/CreateVoucherCode';

const Modal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  min-width: 800px;
  flex-direction: column;
  padding: 20px;
  background: white;
  border-radius: 5px;
`;


interface Props {
  modalActive: boolean;
  handleClose: () => void;
  business: string;
}

const AddVoucherModal: React.FC<Props> = ({ 
  modalActive, 
  handleClose, 
  business
}) => {
  if (!modalActive) return null;

  return (
    <Modal
      open={modalActive}
      onClose={handleClose}
    >
      <Container>
        <CreateVoucherCode
          business={business}
        />
        <CreateMultipleVouchers
          business={business}
        />
      </Container>
    </Modal>
  )
}

export default AddVoucherModal;