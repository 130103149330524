import React from "react";
import styled from 'styled-components';
import { Switch, Route, useRouteMatch, Redirect, useParams } from "react-router-dom";
import Header from "../Components/Header/Header";
import useProtectedRoute from '../Hooks/useProtectedRoute';
import BusinessCreditScreen from "../Screens/Businesses/BusinessCreditScreen";
import BusinessUsersScreen from '../Screens/Businesses/BusinessUsersScreen';
import BusinessesScreen from "../Screens/Businesses/BusinessesScreen";
import DashboardScreen from "../Screens/Dashboard/DashboardScreen";
import PlaysScreen from "../Screens/Plays/PlaysScreen";
import PlayScreen from '../Screens/Plays/Timeline';

import StatisticsNavigator from './StatisticsNavigator';
import GamesNavigator from "./GamesNavigator";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 64px;
  height: calc(100vh - 64px);
  width: 100%;
  overflow: scroll;
`;

const BusinessNavigator = () => {
  const { path } = useRouteMatch();
  const authenticated = useProtectedRoute();

  if(!authenticated){
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route exact path={path} component={DashboardScreen} />
      <Route path={`${path}/statistics`} component={StatisticsNavigator} />
      <Route path={`${path}/games`} component={GamesNavigator} />
      <Route exact path={`${path}/plays`} component={PlaysScreen} />
      <Route path={`${path}/plays/:playId`} component={PlayScreen} />
      <Route path={`${path}/vouchers`} component={BusinessCreditScreen} />
      <Route path={`${path}/users`} component={BusinessUsersScreen} />
    </Switch>
  )
}

const BusinessesNavigator: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Header title=''/>
      <Body>
        <Switch>
          <Route exact path={path} component={BusinessesScreen} />
          <Route path={`${path}/:businessId`} component={BusinessNavigator} />
        </Switch>
      </Body>
    </>
  )
}

export default BusinessesNavigator;