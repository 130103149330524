import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ClueDataPoint } from '../../Types/timeline';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import toTimeString from '../../helpers/toTimeString';
import { MEDIA_QUERIES } from '../../config/constants';

const Container = styled.div`
  margin-bottom: 20px;
  max-width: 1000px;
`;

const InfoBar = styled.div<{ open: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 10px 60px;
  border-radius: ${props => props.open ? '10px 10px 0 0' : '10px'};
  cursor: pointer;
`;

const Blob = styled.div`
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: white;
  border: 4px solid #1b3a57;
  box-sizing: border-box;
  z-index: 1;
  
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    top: 15px;
  }
`;

const SmallBlob = styled.div<{ color: string }>`
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 7px;
  left: 3px;
  width: 14px;
  height: 14px;
  border: 3px solid ${props => props.color};
  border-radius: 7px;
  background: white;
`;

const Title = styled.p`
  flex: 1;
  margin: 0;
`;

const Data = styled.div<{ border?: boolean; hideOnSmall?: boolean }>`
  display: ${props => props.hideOnSmall ? 'none' : 'flex'};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-left: ${props => props.border && '1px solid #ccc'};

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
  }
`;

const RowTitle = styled.p`
  font-weight: bold;
  color: #4b4b4b;
  font-size: 0.7rem;
  margin: 0;
`;

const RowData = styled.p`
  margin: 0;
`;

const DataRow = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 10px;
  padding-left: 40px;
`;

const OpenIcon = styled.div`
  position: absolute;
  right: 20px;
  height: 24px;
`;

const expand = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 500px;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  background: white;
  padding: 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  animation: ${expand} 1s forwards;
  overflow: auto;
`;

const ClueBox: React.FC<ClueDataPoint> = ({
  id,
  name,
  rating,
  time,
  dataPoints
}) => {
  const [open, setOpen] = useState(false);
  const data: any[] = dataPoints;

  return (
    <Container>
      <InfoBar 
        open={open}
        onClick={() => setOpen(!open)}
      >
        <Blob />
        <Title>{ name }</Title> 
        <Data border={true} hideOnSmall>
          <RowTitle>Interactions</RowTitle>
          <RowData>{ dataPoints.length || '-' }</RowData>
        </Data>
        <Data border={true} hideOnSmall>
          <RowTitle>Rating</RowTitle>
          <RowData>{ rating || '-' }</RowData>
        </Data>
        <Data border={true} hideOnSmall>
          <RowTitle>Time</RowTitle>
          <RowData>{ time ? new Date(time).toLocaleTimeString() : '-' }</RowData>
        </Data>
        <OpenIcon>
          {
            open 
            ? <KeyboardArrowDown />
            : <KeyboardArrowUp />
          }
        </OpenIcon>
      </InfoBar>
      {
        open &&
        <Content>
          {
            data.map((d: any) => {
              return (
                <DataRow>
                  <SmallBlob color={d.color} />
                  <Data border={false}>
                    <RowTitle>Type</RowTitle>
                    <RowData>{ d.type }</RowData>
                  </Data>
                  {
                    d.type === 'wrong'
                    ? <Data border={false} style={{ flex: 1, alignSelf: 'flex-start' }}>
                      <RowTitle>Guess</RowTitle>
                      <RowData>{ JSON.parse(d.guess) }</RowData>
                    </Data>
                    : <Data border={false} style={{ flex: 1 }}></Data>
                  }
                  <Data border={false}>
                    <RowTitle>Date</RowTitle>
                    <RowData>{ new Date(d.date).toLocaleTimeString() }</RowData>
                  </Data>
                </DataRow>
              )
            })
          }
        </Content>
      }
    </Container>
  )
}

export default ClueBox;