import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getGameById, getCluesFromGameId } from "../../Firebase/games";
import gameReducer from "../../Reducers/gameReducer";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";

import { Tab, Tabs } from "@material-ui/core";

import Header from "../../Components/Header/Header";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import HeaderTab from "../../Components/Header/HeaderTab";
import AccountNavigator from "../../Navigators/AccountNavigator";
import LoadingScreen from "../Loading/LoadingScreen";

const Container = styled.div`
  height: 100vh;
`;

const drawerWidth = 256;
const menuHeight = 64;

const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  width: calc(100vw - ${drawerWidth}px);
  height: calc(100vh - ${2*menuHeight}px);
  overflow: auto;
`;

const AccountScreen: React.FC = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[2];

  return (
    <Container>
      <Header title="Account" />
      <HeaderTabs>
        <HeaderTab 
          text="Details" 
          active={path === "details"} 
          to={"/account/details"} 
        />
        <HeaderTab 
          text="Emails" 
          active={path === "emails"} 
          to={"/account/emails"} 
        />
      </HeaderTabs>
      <Body>
        <AccountNavigator />
      </Body>
    </Container>
  )
}

export default AccountScreen;