import React, { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Clue, Game } from "../../Types/games";
import { Button, IconButton } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { addNewClue } from "../../Firebase/games";
import { clue as defaultClue } from "../../config/defaultGame";
import useTheme from "../../Hooks/useTheme";
import useIsMobile from "../../Hooks/useIsMobile";
import Select from "../Forms/Select";

const Container = styled.ul`
  width: 256px;
  height: calc(100vh - 140px);
  border-right: 1px solid #aaa;
  background: white;
  margin: 0;
  padding: 0;
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`;

const ClueItem = styled.li<{ active: boolean; }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: ${props => props.active ? "#ddd" : "white"};
  color: ${props => props.active ? "#333" : "#776589"};
  height: 32px;
  padding: 0 10px;

  &:hover {
    background: #eee;
  }
`;

const Text = styled.p<{ color?: string; }>`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  color: ${props => props.color ? props.color : "inherit" };
`;

const Copy = styled(CopyIcon)`
  color: ${props => props.theme.colors.success.main};
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

interface ItemProps {
  order: number;
  title: string;
}

const Item: React.FC<ItemProps> = ({ order, title }) => {
  const divRef = useRef<HTMLDivElement|null>(null);
  const [mouseButtonDown, setMouseButtonDown] = useState(false);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    divRef.current?.addEventListener('mousedown', handleMouseDown);
    divRef.current?.addEventListener('mousemove', handleMouseMove);
    divRef.current?.addEventListener('mouseup', handleMouseUp);
  }, []);

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    setMouseButtonDown(true);
  }

  const handleMouseMove = (e: MouseEvent) => {
    if(mouseButtonDown){
      e.preventDefault();
      setPosition(e.offsetY);
    }
  }

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    setMouseButtonDown(false);
  }

  return (
    <div draggable={false} ref={divRef} style={{ transform: `translateY(${position}px)`}}>
      <ClueItem 
        active={false}
      >
        <Text>{ title }</Text>
      </ClueItem>
    </div>
  )
}

interface Props {
  gameId: string;
  clues?: Clue[];
  addClue: () => void;
  copyClue: () => void;
  currentClue: string;
}

const ClueList: React.FC<Props> = ({ 
  gameId,
  addClue,
  copyClue,
  clues, 
  currentClue
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { params } = useRouteMatch() as any;
  const history = useHistory();
  const url = `/businesses/${params.businessId}/games/${params.id}/puzzles/`;
  
  // const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // const id = event.target.value;
  const onChange = (id: any) => {
    history.push(url + id);
  };

  if (isMobile && clues) return (
    <SelectWrapper>
      <Select
        value={params.clueId}
        onChange={onChange}
        options={clues?.map(clue => ({
          name: clue.title,
          value: clue.id,
        }))}
      />
      <IconButton onClick={addClue}>
        <AddIcon />
      </IconButton>
      <IconButton onClick={addClue}>
        <CopyIcon />
      </IconButton>
    </SelectWrapper>
    // <select onChange={onChange}>
    //   {
    //     clues?.map((clue, i) => (
    //       <option value={clue.id}>
    //         { clue.title }
    //       </option>
    //     ))
    //   }
    // </select>
  );

  return (
    <Container>
      <ButtonWrapper>
        <Button 
          style={{ marginRight: 10 }}
          variant="contained"
          color="primary"
          onClick={addClue}
        >
          <AddIcon />Puzzle 
        </Button>
        {
          currentClue && 
          <IconButton onClick={copyClue}>
            <Copy />
          </IconButton>
        }
      </ButtonWrapper>
      {
        clues?.map((clue, i) => {
          const unfinished = clue.solutions.filter((solution: any) => solution.next.length === 0).length > 0 && !clue.winCondition;
          return (
            <Link 
              key={clue.id}
              to={url + clue.id} 
              style={{ textDecoration: "none" }}
            >
              <ClueItem 
                key={i}
                active={currentClue === clue.id}
              >
                <Text>{ clue.title }</Text>
                {
                  unfinished &&
                  <ErrorOutlineIcon style={{ color: theme.colors.error.main }}/>
                }
              </ClueItem>
            </Link>
          )
        })
      }
    </Container>
  )
}

export default ClueList;