import React from "react";
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  options: any;
  value: any;
  onChange: (value: any) => void;
}

const RadioInput: React.FC<Props> = ({ options, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <Container>
        {
          Object.keys(options).map((option: any) => <FormControlLabel key={option} color="primary" value={options[option]} control={<Radio />} label={option} />)
        }
      </Container>
    </RadioGroup>
  )
}

export default RadioInput;