import { useEffect, useState } from "react";
import { MEDIA_QUERIES } from "../config/constants";

const handleSize = (value: number) => {
  return value < MEDIA_QUERIES.SMALL;
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(handleSize(window.innerWidth));

  const onSizeChange = (event: Event) => {
    const target = event.target as Window;
    setIsMobile(handleSize(target.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', onSizeChange);
    
    return () => {
      window.removeEventListener('resize', onSizeChange);
    }
  });

  return isMobile;
};

export default useIsMobile;
