import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams, useLocation, useRouteMatch, Switch, Route, Link, useHistory } from "react-router-dom";

import Header from "../../Components/Header/Header";
import HeaderTabs from "../../Components/Header/HeaderTabs";
import HeaderTab from "../../Components/Header/HeaderTab";
import BusinessUsersScreen from "../Businesses/BusinessUsersScreen";
import BusinessCreditScreen from "../Businesses/BusinessCreditScreen";
import DashboardOverviewScreen from "./DashboardOverviewScreen";

import Breadcrumbs from '../../Components/Navigation/Breadcrumbs';
import IconButton from "../../Components/Buttons/IconButton";
import useCurrentUser from "../../Hooks/useCurrentUser";
import { MEDIA_QUERIES } from "../../config/constants";
import Business from "../../Types/business";
import { getBusinessById } from "../../Firebase/businesses";

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
    height: 100vh;
  }
`;

const Links = styled.div``;

const drawerWidth = 256;
const menuHeight = 64;

const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: ${menuHeight}px;
  width: calc(100vw - ${drawerWidth}px);
  height: calc(100vh - ${2*menuHeight}px);
  overflow: auto;
  padding: 20px;
`;

const FilterList = styled.div`
  width: 100%;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 256px;
    height: 100%;
    border-right: 1px solid rgb(198,190,207);
    box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
    overflow-y: scroll;
    background: #f8f8f8;
  }
`;

const ContentWrapper = styled.div`
  padding: 10px;
`;

const DashboardScreen: React.FC = () => {
  const history = useHistory();
  const currentUser = useCurrentUser();
  const params = useParams<{ businessId: string }>();
  const [business, setBusiness] = useState<Business>();

  useEffect(() => {
    const get = async () => {
      const res = await getBusinessById(params.businessId);
      if (!res.error) {
        setBusiness(res as Business);
      }
    }

    get();
  }, []);

  return (
    <Container>
      <FilterList>
        <Links>
          <IconButton 
            icon='FaWrench'
            text='Game Builder'
            onClick={() => history.push(`/businesses/${params.businessId}/games`)}
          />
          <IconButton 
            icon='FaChartLine'
            text='Statistics'
            onClick={() => history.push(`/businesses/${params.businessId}/statistics`)}
          />
          <IconButton 
            icon='FaGamepad'
            text='Plays'
            onClick={() => history.push(`/businesses/${params.businessId}/plays`)}
          />
          <IconButton 
            icon='FaMoneyBillWave'
            text='Vouchers'
            onClick={() => history.push(`/businesses/${params.businessId}/vouchers`)}
          />
          {
            currentUser.role > 1 &&
            <>
              
              <IconButton 
                icon='FaUser'
                text='Admin Users'
                onClick={() => history.push(`/businesses/${params.businessId}/users`)}
              />
            </>
          }
        </Links>
      </FilterList>
      {
        business &&
        <ContentWrapper>{ business.name }</ContentWrapper>
      }
    </Container>
  )
};

export default DashboardScreen;
