import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  background: white;
  margin-bottom: 10px;
  border-radius: 10px;
`;

const Title = styled.p`
  margin: 0;
`;

const Statistic = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: end;
`;

interface Props {
  title: string;
  statistic: string|number;
}

const StatBox: React.FC<Props> = ({ title, statistic }) => {
  return (
    <Container>
      <Title>{ title }</Title>
      <Statistic>{ statistic }</Statistic>
    </Container>
  )
}

export default StatBox;