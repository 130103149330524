import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  height: 50px;
  width: 290px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Color  = styled.div<{ color: string}>`
  width: 50px;
  height: 100%;
  background: ${props => props.color};
  border-radius: 0 5px 5px 0;
  border: none;
`;

const TextInput = styled.input`
  padding: 15px 20px;
  border: none;
  background: #fff;
  font-size: 16px;
  border-radius: 5px;
  flex: 1;

  &:focus {
    outline: none;
  }
`;

interface InputTypes {
  value: any;
  onChange: (value: any) => void;
  type?: string;
}

const Input: React.FC<InputTypes> = ({ value, onChange, type="text" }) => {

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange(event.currentTarget.value);
  }

  return (
    <Container>
      <TextInput 
        value={value} 
        onChange={handleChange} 
        type={type} 
      />
      <Color color={value}/>
    </Container>
  )
}

export default Input;