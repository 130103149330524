import React, { useState } from 'react';
import styled from 'styled-components';
import TitleBox from '../../Components/Containers/TitleBox';
import FormExplanation from '../../Components/Forms/FormExplanation';
import FormSection from '../../Components/Forms/FormSection';
import CheckBox from '../../Components/Forms/CheckBox';
import TextArea from '../../Components/Forms/TextArea';
import BottomBar from '../../Components/Forms/BottomBar';
import { Game } from '../../Types/games';
import { updateGameById } from '../../Firebase/games';
import FlashMessage, { useFlashMessage } from '../../Components/FlashMessage/FlashMessage';
import Warning from '../../Components/Containers/Warning';

const Container = styled.div`
  padding: 20px;
`;

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditAppStoreScreen: React.FC<Props> = ({ game, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(game.active);
  const [description, setDescription] = useState(game.description);
  const [additionalInfo, setAdditionalInfo] = useState(game.additionalInfo);
  const flash = useFlashMessage();

  async function submit(){
    const data = { active, description, additionalInfo };
    const res = await updateGameById(game.id, data);
    if(res.error){
      // flash.showFlashMessage("Something went wrong, please try again", true);
      flash.showFlashMessage(res.message, true);
    } else {
      flash.showFlashMessage("Game settings successfully updated", false);
      dispatch({ type: "UPDATE_GAME", payload: data });
    }
    setLoading(false);
  }

  const handleActiveChange = (e: boolean) => {
    if(e && window.confirm('This game will become available within the app, are you sure?')){
      setActive(true);
    } else {
      setActive(false);
    }
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <TitleBox title='App Store Configuration'>
        <Warning 
          text='Games can only become active when they have been reviewed and added to the In App Purchases on iOS and Android.'
        />
        <FormSection>
          <FormExplanation title="Active" text="Check this box when the game is ready to be put on the app store. This needs to be configured by a Roamr admin."/>
          <CheckBox checked={active} onChange={handleActiveChange} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Description" text="Enter the description for your game, this will be what the user will read when purchasing your game."/>
          <TextArea rows={5} value={description} onChange={setDescription} />
        </FormSection>
        <FormSection>
          <FormExplanation title="Additional Information" text="Add additional information here, such as closing times of particular areas or suitable things to bring with you."/>
          <TextArea rows={5} value={additionalInfo} onChange={setAdditionalInfo} />
        </FormSection>
      </TitleBox>
      <div style={{ height: 64 }}></div>
      <BottomBar loading={loading} onClick={submit} />
    </Container>
  )
}

export default EditAppStoreScreen;