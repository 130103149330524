import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getCreditsWhere, createNewCredit } from "../../../Firebase/credits";
import { getGamesWhere } from "../../../Firebase/games";
import { sendEmail } from "../../../Firebase/helpers";

import FormSection from "../../Forms/FormSection";
import FormExplanation from "../../Forms/FormExplanation";
import TitleBox from "../../Containers/TitleBox";
import Input from "../../Forms/Input";
import LoadingButton from "../../Buttons/LoadingButton";
import Select from "../../Forms/Select";


const FormRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

interface Props {
  business: string;
}

const CreateVoucherCode: React.FC<Props> = ({
  business
}) => {
  const [reference, setReference] = useState("");
  const [email, setEmail] = useState("");
  const [games, setGames] = useState<any|null>();
  const [game, setGame] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getGames = async () => {
      const res = await getGamesWhere("business", business);
      if(res.error){
        console.log(res.message);
      } else {
        setGames(res);
      }
    } 

    getGames();
  }, []);

  const createVoucherCode = async () => {
    const res = await createNewCredit(business, game, email, reference);
  }

  return (
    <TitleBox title="Create a voucher code">
      <FormSection>
        <FormExplanation title="Create new voucher code" text="Whenever you sell a game you will want to create a voucher. Input the email and game that you are creating a voucher for and click submit. This will create a voucher and email the customer with the specific information." />
        <FormRow>
          <div>
            <div style={{ marginBottom: 10 }}>
              <Input value={reference} onChange={setReference} placeholder="Reference" />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input value={email} onChange={setEmail} placeholder="Email" />
            </div>
            { 
              games && 
              <Select 
                value={game} 
                onChange={setGame} 
                options={games.map((g: any) => ({ name: g.name, value: g.id }))} 
              /> 
            }
          </div>
          <ButtonWrapper>
            <LoadingButton 
              style={{ height: 50.4, marginLeft: 20 }}
              loading={loading} 
              setLoading={setLoading} 
              onClick={createVoucherCode} 
              text="Create"
            />
          </ButtonWrapper>
        </FormRow>
      </FormSection>  
    </TitleBox>
  )
}

export default CreateVoucherCode;