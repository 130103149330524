// import React from "react";
// import { 
//   FaHome,
//   FaGamepad,
//   FaChevronUp,
//   FaUsers,
//   FaTools,
//   FaSignOutAlt,
//   FaCheck,
//   FaSpinner,
//   FaTimes,
//   FaPlus
// } from "react-icons/fa";

// const iconMap = new Map([
//   ["home", FaHome],
//   ["game", FaGamepad],
//   ["chevron-up", FaChevronUp],
//   ["users", FaUsers],
//   ["tools", FaTools],
//   ["signout", FaSignOutAlt],
//   ["check", FaCheck],
//   ["loading", FaSpinner],
//   ["close", FaTimes],
//   ["plus", FaPlus]
// ]);

// interface IconProps {
//   name: keyof typeof iconMap;
//   size: number;
//   color: string;
//   style?: any;
// }

// const Icon: React.FC<IconProps> = ({ style, name, size, color }) => {
//   const Component = iconMap.get(name);
//   // const component = iconMap.get(name);
//   return <Component size={size} color={color} />;

//   return (
//     <>
//       { 
//         component
//         ? component({ size, color })
//         : null
//       }
//     </>
//   )
// }

// export default Icon;
import { IconBaseProps } from 'react-icons';
import {
  FiArrowDown,
  FiArrowUp,
  FiAward,
  FiBarChart2,
  FiBookmark,
  FiBriefcase,
  FiHome,
  FiLogIn,
  FiLogOut,
  FiMapPin,
  FiMail,
  FiMenu,
  FiPlus,
  FiSearch,
  FiSliders,
  FiUsers,
  FiX,
  FiLoader,
  FiChevronUp,
  FiChevronDown,
  FiFilter,
  FiUserCheck,
} from 'react-icons/fi';

const iconMap = {
  arrowUp: FiArrowUp,
  arrowDown: FiArrowDown,
  award: FiAward,
  barChart: FiBarChart2,
  bookmark: FiBookmark,
  briefcase: FiBriefcase,
  chevronUp: FiChevronUp,
  chevronDown: FiChevronDown,
  cross: FiX,
  filter: FiFilter,
  home: FiHome,
  loading: FiLoader,
  login: FiLogIn,
  logout: FiLogOut,
  mail: FiMail,
  mapPin: FiMapPin,
  menu: FiMenu,
  plus: FiPlus,
  search: FiSearch,
  sliders: FiSliders,
  users: FiUsers,
  userBusiness: FiUserCheck,
}

export type IconName = keyof typeof iconMap;

interface IconProps extends IconBaseProps {
  name: keyof typeof iconMap;
}

const Icon: React.FC<IconProps> = ({ name, ...props }) => {
  const Component = iconMap[name];
  return <Component {...props} />;
};

export default Icon;
