import styled, { css } from 'styled-components';

export const NavigationButtonListItem = styled.li`
  list-style: none;
  margin: 0 8px 8px;
`;

export const NavigationButtonWrapper = styled.a<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s;

  /* ${props => props.isMobile && css`
    justify-content: center;
  `} */

  &:hover,
  &:focus,
  &:active {
    background: #d8f3ec;
    color: ${props => props.theme.colors.primary.main};
  }
`;

export const NavigationButtonText = styled.p`
  font-family: roboto;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
`;

export const NavigationButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
`;
