import React from "react";
import styled from "styled-components";
import Input from "../Input";

const Container = styled.div``;

interface Props {
  solution: any;
  setSolution: (value: any) => void;
}

const InputSolution: React.FC<Props> = ({ solution, setSolution }) => {

  const handleChange = (e: string) => {
    setSolution([e]);
  }

  return (
    <Container>
      <Input value={solution} onChange={handleChange} />
    </Container>
  )
}

export default InputSolution;