import { Button } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { updateUserById } from '../../Firebase/users';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p``;

const Link = styled.a`
  font-weight: bold;
  color: #333;
  font-size: 1.2rem;
  text-decoration: none;
`;

const STATUS = {
  unauthenticated: 1,
  notAcceptedTerms: 2,
  acceptedTerms: 3
};
interface Props {
  user: string;
  setStatus: (val: number) => void;
}

const AcceptTermsScreen: React.FC<Props> = ({ user, setStatus }) => {

  const handleAccept = async () => {
    const res = await updateUserById(user, { acceptedTerms: true });
    if(!res.error){
      setStatus(STATUS.acceptedTerms);
    }
  }

  return (
    <Container>
      <Text>Please accept our <Link target='_blank' href='https://roamr.co.uk/pages/terms-and-conditions'>terms and conditions</Link> to continue to Roamr</Text>
      <Button variant='contained' onClick={handleAccept}>
        Accept
      </Button>
    </Container>
  )
}

export default AcceptTermsScreen;