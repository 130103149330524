import React from "react";
import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

interface Props {
  rows: number;
  value: string;
  onChange: (value: string) => void;
}

const Container = styled(TextareaAutosize)`
  box-sizing: border-box;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;  
  flex: 1;
  min-height: 200px;

  &:focus {
    outline: none;
    border: 2px solid #776589;
  }
`;

const TextArea: React.FC<Props> = ({ rows, value, onChange }) => {

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(event.target.value);
  }

  return (
    <Container 
      minRows={rows}
      maxRows={10}
      value={value} 
      onChange={handleChange} 
    />
  )
}

export default TextArea;