import React, { useRef, useState } from "react";
import styled from "styled-components";
import Input from "../Input";
import TextArea from "../TextArea2";
import LoadingButton from "../../Buttons/LoadingButton";
import { Button } from "@material-ui/core";
import InfoPopOver from "../../PopOvers/InfoPopOver";

const Container = styled.div`
  position: relative;
  flex: 1;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  color: #333;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Key = styled.p`
  margin: 0;
  margin-right: 20px;
`;

const Explanation = styled.p`
  color: #888;
  margin: 0;
  text-align: right;
`;

const ButtonRow = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
`;

const Label = styled.label`
  display: inline-block;  
  color: #000000aa;
  font-size: 14px;
  margin-bottom: 5px;
`;

interface Props {
  data: {
    sender: string;
    replyTo: string;
    subject: string;
    message: string;
  };
  onSubmit: (data: any) => void;
  loading: boolean;
  setLoading: (v: boolean) => void;
}

const Templates: React.FC<Props> = ({
  data,
  onSubmit,
  loading,
  setLoading
}) => {
  const initialState = useRef(data).current;
  const [sender, setSender] = useState(data.sender);
  const [from, setFrom] = useState("noreply@roamr.co.uk");
  const [replyTo, setReplyTo] = useState(data.replyTo);
  const [subject, setSubject] = useState(data.subject);
  const [message, setMessage] = useState(data.message);

  const cancel = () => {
    setSender(initialState.sender);
    setReplyTo(initialState.replyTo);
    setSubject(initialState.subject);
    setMessage(initialState.message);
  }

  const handleSubmit = () => {
    onSubmit({
      sender,
      replyTo,
      subject,
      message
    })
  }

  return (
    <Container>
      <InfoPopOver>
        <>
          <Title>Wildcards</Title>
          <p>You can add the following wild cards to your email:</p>
          <InfoRow>
            <Key>%NAME%</Key>
            <Explanation>The name of the user</Explanation>
          </InfoRow>
          <InfoRow>
            <Key>%GAME%</Key>
            <Explanation>The current game</Explanation>
          </InfoRow>
          <InfoRow>
            <Key>%VOUCHERS%</Key>
            <Explanation>A table with voucher name, start location and voucher code of any vouchers purchased</Explanation>
          </InfoRow>
          <InfoRow>
            <Key>%VOUCHER%</Key>
            <Explanation>The first voucher in the list</Explanation>
          </InfoRow>
        </>
      </InfoPopOver>
      <Row>
        <InputWrapper>
          <Label>Sender</Label>
          <Input value={sender} onChange={setSender} />
        </InputWrapper>
        <InputWrapper>
          <Label>From</Label>
          <Input value={from} onChange={setFrom} disabled/>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <Label>Reply To</Label>
          <Input value={replyTo} onChange={setReplyTo} />
        </InputWrapper>
        <InputWrapper>
          <Label>Subject</Label>
          <Input value={subject} onChange={setSubject} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <Label>Message</Label>
          <TextArea 
            value={message} 
            onChange={setMessage} 
            rows={8}
          />
        </InputWrapper>
      </Row>
      <ButtonRow>
        <div style={{ marginRight: 10 }}>
          <Button onClick={cancel}>CANCEL</Button>
        </div>
        <LoadingButton 
          loading={loading} 
          setLoading={setLoading} 
          text="Submit"
          onClick={handleSubmit}  
        />
      </ButtonRow>
    </Container>
  )
}

export default Templates;


