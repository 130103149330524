import firebase from "firebase";
import "firebase/functions";

function randomId() {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

export async function getFromStorage(url: string){
  try {
    const storageRef = firebase.storage().ref();
    const pathRef = storageRef.child(url);
    return await pathRef.getDownloadURL();
  } catch(err){
    console.log(err);
  }
}

export async function addToStorageLocation(file: File, url: string){
  try {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(url);
    await imageRef.put(file);
    return {
      success: true,
      message: "Successfully added file"
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function addToStorage(file: File, url: string){
  try {
    const path = url + "/" + randomId();
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(path);
    await imageRef.put(file);
    return path;
  } catch(err){
    console.log(err);
  }
}

// RETURNS THE DOWNLOADURL
export async function addToStorage2(file: File, url: string){
  try {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(url);
    await imageRef.put(file);
    const loc = await imageRef.getDownloadURL();
    return loc;
  } catch(err: any){
    console.log(err);
    return {
      error: true,
      message: err.message
    }
  }
}

export async function sendEmail(
  { dest, subject, content } : 
  { dest: string, subject: string, content: string }
){
  try {
    const sendMail = firebase.functions().httpsCallable('helpers-sendMail');
    await sendMail({
      dest,
      subject,
      content
    })
    return {
      success: true,
      message: `Email send to ${dest}`
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}