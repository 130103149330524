import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { getGamesById } from '../../Firebase/games';
import { getCreditsByBusiness, createNewCredit } from "../../Firebase/credits";
import { sendVoucherEmail } from "../../Firebase/emails";

import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import TitleBox from "../../Components/Containers/TitleBox";
import CreateVoucherCode from "../../Components/Forms/Businesses/CreateVoucherCode";
import SendVoucherEmail from "../../Components/Buttons/SendVoucherEmail";
import RefundVoucherButton from "../../Components/Credits/RefundVoucherButton";
import VoucherStatus from "../../Components/Icons/VoucherStatus";
import CreateMultipleVouchers from "../../Components/Forms/Businesses/CreateMultipleVouchers";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import toDateString from "../../helpers/toDateString";
import ChoiceButton from '../../Components/Statistics/ChoiceButton';
import LoadingItem from "../../Components/Loading/LoadingItem";
import useAdminOnlyRoute from "../../Hooks/useAdminOnlyRoute";
import VoucherTable from "../../Components/Tables/VoucherTable";
import StatBox from '../../Components/Containers/StatBox';
import Button from '../../Components/Buttons/Button';

import { Button as MUIButton, IconButton } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import AddVoucherModal from "../../Components/Modals/AddVoucherModal";
import { MEDIA_QUERIES } from "../../config/constants";
import Icon from "../../Components/Icons/Icon";
import useIsMobile from "../../Hooks/useIsMobile";


const Container = styled.div`
  display: flex;
  height: 100%;
`;

const FilterList = styled.div<{ showing: boolean }>`
  position: fixed;
  display: ${props => props.showing ? 'block' : 'none'};
  width: 100%;
  background: #f8f8f8;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    position: static;
    display: block;
    width: 256px;
    height: 100%;
    border-right: 1px solid rgb(198,190,207);
    box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
    overflow-y: scroll;
  }
`;

const Item = styled.div`
  padding: 10px;
`;

const ItemTitle = styled.p`
  font-weight: bold;
  color: #4b4b4b;
`;

const VoucherTableWrapper = styled.div`
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    height: calc(100vh - 64px);
    width: calc(100vw - 512px);
    overflow: scroll;
  }
`;

const Btn = styled(MUIButton)`
  background: ${props => props.theme.colors.success.main};
  text-decoration: none;
`;

const IconWrapper = styled.button`
  position: absolute;
  right: 10px;
  top: 75px;
  z-index: 5;
  width: 40px;
  height: 40px;
  border: none;
  background: none;
`;


const BusinessCreditScreen: React.FC = () => {
  const params = useParams<{ businessId: string }>(); 
  const flash = useFlashMessage();

  // useAdminOnlyRoute();

  // const [games, setGames] = useState<null | any>();
  const isMobile = useIsMobile();
  const [filterShowing, setFilterShowing] = useState(false);
  const [credits, setCredits] = useState<null | any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingVoucher, setLoadingVoucher] = useState(false);
  const [showingAccount, setShowingAccount] = useState(true);
  const [showingData, setShowingData] = useState('Vouchers');

  const [addVoucherModalOpen, setAddVoucherModalOpen] = useState(false);

  // Map over credits and add in the game names
  // Create the new data that will be exported


  useEffect(() => {
    // get all credits by business
    // map over credits by game
    // get all games from these
    // reduce over to create export data

    async function getData(){
      let credits = await getCreditsByBusiness(params.businessId) as any;
      // const credits = res.sort((a: any, b: any) => b.created - a.created);
      const gameIds = credits.map((credit: any) => credit.game);
      const { games } = await getGamesById(gameIds);
      if(games){
        credits = credits.map((credit: any) => {
          const game = games.find((game: any) => game.id === credit.game) as any;
          return {
            ...credit,
            game: game?.name || '-',
            business: game.businessName,
            created: new Date(credit.created)
          }
        })
      }

      setCredits(credits.sort((a: any, b: any) => b.created - a.created));
      setLoading(false);
    }

    getData();

  }, []);

  const totals = useMemo(() => {
    const data = { week: 0, month: 0, year: 0 };
    if(credits){
      const today = Date.now();
      const year = credits.filter((credit: any) => today - credit.created < 1000 * 60 * 60 * 24 * 365);
      const month = year.filter((credit: any) => today - credit.created < 1000 * 60 * 60 * 24 * 30);
      const week = month.filter((credit: any) => today - credit.created < 1000 * 60 * 60 * 24 * 7);
      data.week = week.length;
      data.month = month.length;
      data.year = year.length;
    }

    return data
  }, [credits]);

  // useEffect(() => {
  //   async function getGames(){
  //     const gamesToGet = credits.map((credit: any) => credit.game);
  //     const res = await getGamesById(gamesToGet);
  //     if(!res.error){
  //       setGames(res.games);
  //     }
  //   }

  //   if(credits){
  //     getGames();
  //   }

  // }, [credits]);

  // useEffect(() => {
  //   async function getCredits(){
  //     const res = await getCreditsByBusiness(params.businessId) as any;
  //     setCredits(res.sort((a: any, b: any) => b.created - a.created));
  //     setLoading(false);
  //   }

  //   getCredits();
  // }, []);

  const createVoucherCode = async (game: string, email: string, reference: string) => {
    if(game){
      const res = await createNewCredit(params.businessId, game, email, reference);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        flash.showFlashMessage(res.message, false);
        setCredits([res.payload, ...credits]);
        // await sendVoucherEmail(res.payload);
      }
      setLoadingVoucher(false);
    }
  }

  if(loading) return <div style={{ width: 'calc(100% - 40px)', height: 100, margin: 20 }}><LoadingItem /></div>

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      {/* <CreateVoucherCode 
        business={params.businessId} 
        createVoucherCode={createVoucherCode}
        loadingVoucher={loadingVoucher}
        setLoadingVoucher={setLoadingVoucher}  
      />
      <CreateMultipleVouchers
        business={params.businessId}
        showFlashMessage={flash.showFlashMessage}
      /> */}
      {
        isMobile &&
        <IconWrapper onClick={() => setFilterShowing(!filterShowing)}>
          <Icon name={filterShowing ? "cross" : "filter"} size={24} />
        </IconWrapper>
      }
      <FilterList showing={filterShowing}>
        <Item>
          <ItemTitle>My Business</ItemTitle>
          <ChoiceButton 
            value={showingData} 
            onChange={setShowingData} 
            choices={['Vouchers', 'Sales']}
          />
        </Item>
        <Item>
          <Button onClick={() => setAddVoucherModalOpen(true)} background='#34ac8c'>
            <p style={{ color: 'white', fontWeight: 'bold', fontSize: '1rem', margin: '10px 0' }}>Add Vouchers</p>
          </Button>
          <AddVoucherModal 
            modalActive={addVoucherModalOpen}
            handleClose={() => setAddVoucherModalOpen(false)}
            business={params.businessId}
          />
        </Item>
        <Item>
          <ItemTitle>New Vouchers</ItemTitle>
          <StatBox title='Week' statistic={totals.week} />
          <StatBox title='Month' statistic={totals.month} />
          <StatBox title='Year' statistic={totals.year} />
        </Item>
        <Item style={{ float: 'right' }}>
          <CSVLink 
            style={{ textDecoration: 'none' }} 
            filename={'Roamr_Vouchers_' + new Date().toLocaleDateString()} 
            data={credits}
          >
            <MUIButton
              startIcon={<DownloadIcon />}
            >
              <p style={{ fontWeight: 'bold', margin: 0 }}>Export</p>
            </MUIButton>
          </CSVLink>
        </Item>
      </FilterList>
      <VoucherTableWrapper>
        <VoucherTable 
          vouchers={credits}
          showingData={showingData}
        />
      </VoucherTableWrapper>
    </Container>
  )
}

export default BusinessCreditScreen;