import React from "react";
import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div<{ border: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-bottom: ${props => props.border ? "1px solid #ddd" : "none"};

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
  }
`;

interface Props {
  children: React.ReactNode;
  border?: boolean;
}

const FormSection: React.FC<Props> = ({ children, border=true }) => {
  return (
    <Container border={border}>
      { children }
    </Container>
  )
}

export default FormSection;