import React from "react";
import styled from "styled-components";

import { Game } from "../../Types/games";
import { addNewClue, deleteClueById } from "../../Firebase/games";
import ClueList from "../../Components/Lists/ClueList";
import EditClueForm from "../../Components/Forms/Clues/EditClueForm";
import { clue as defaultClue } from "../../config/defaultGame";
import { useHistory, useRouteMatch } from "react-router-dom";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import { MEDIA_QUERIES } from "../../config/constants";

const Body = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 128px);
  overflow: auto;
  padding: 20px;
`;

const Container = styled.div`
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
  }
`;

const EmptyMessage = styled.div`
  padding: 40px;
  border-radius: 20px;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border: 1px solid rgb(198,190,207);
  box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditCluesScreen: React.FC<Props> = ({ game, dispatch }) => {
  const flash = useFlashMessage();
  const history = useHistory();
  const { params } = useRouteMatch() as any;

  const clueFilter = game.clues.filter(clue => clue.id === params.clueId); //  could use find
  const currentClue = clueFilter.length ? clueFilter[0] : null;

  const clueNames = game.clues.map(clue => ({
    value: clue.id,
    name: clue.title
  }))

  const addClue = async () => {
    const res = await addNewClue(game.id, { ...defaultClue, order: game.clues.length });
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      dispatch({ type: "ADD_CLUE", payload: res.payload });
      history.push(`/businesses/${params.businessId}/games/${params.id}/puzzles/${res.payload.id}`);
      // history.push(`/games/${game.id}/puzzles/${res.id}`, { flashMessage: { text: "Yay!", error: false } });
    }
  }

  const copyCurrentClue = async () => {
    if(currentClue){
      const data = {
        ...currentClue,
        title: currentClue.title + ' - copy',
        order: game.clues.length
      } as any;
      delete data.id;

      const res = await addNewClue(game.id, data);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        dispatch({ type: "ADD_CLUE", payload: res.payload });
        // history.push(`/games/${game.id}/puzzles/${res.id}`, { flashMessage: { text: "Yay!", error: false } });
      }
    }
  }

  const deleteClue = async (id: string): Promise<void> => {
    const res = await deleteClueById(game.id, id);
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      flash.showFlashMessage("Clue successfully deleted", false);
      dispatch({ type: "DELETE_CLUE", payload: id });
    }
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <ClueList 
        addClue={addClue}
        copyClue={copyCurrentClue}
        gameId={game.id}
        currentClue={params.clueId}
        clues={game.clues.sort((a,b) => (a.order || 0) - (b.order || 0))} 
      />
      <Body>
        {
          !currentClue &&
          <EmptyMessage>
            {
              game.clues.length 
              ? <p>Select a puzzle from the menu to the left to begin editing.</p>
              : <p>Create your first puzzle by clicking '+Puzzle' on the left.</p>
            }
          </EmptyMessage>
        }
        {
          game.clues.map((clue, i) => {
            return (
              <EditClueForm 
                key={i}
                showFlashMessage={flash.showFlashMessage}
                dispatch={dispatch}
                gameId={game.id}
                clue={clue}
                deleteClue={() => deleteClue(clue.id)}
                clueNames={clueNames}
                hidden={currentClue?.id !== clue.id} 
              />
            )
          })
        }
        <div style={{ height: 64 }}></div>
      </Body>
    </Container>
  )
}

export default EditCluesScreen;
