import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ccc;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Choice = styled.p<{ active: boolean }>`
  flex: 1;
  background: ${props => props.active ? '#35AC8C' : 'white'};
  color: ${props => props.active ? 'white' : '#4b4b4b'};
  cursor: pointer;
  padding: 10px;
  margin: 0;
  text-align: center;
  font-weight: bold;

  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  value: string;
  choices: string[];
  onChange: (newValue: string) => void;
}

const ChoiceButton: React.FC<Props> = ({
  value,
  choices,
  onChange
}) => {
  return (
    <Container>
      {
        choices.map(choice => {
          return <Choice 
            active={choice === value}
            onClick={() => onChange(choice)}
          >
            { choice }
          </Choice>
        })
      }
    </Container>
  )
}

export default ChoiceButton;