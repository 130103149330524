import { stringify } from "querystring";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getFromStorage } from "../../Firebase/helpers";
import WarningIcon from "@material-ui/icons/Warning";

const Wrapper = styled.div``;

const Error = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: red;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Container = styled.div<{ focused: boolean}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 225px;
  border: ${props => props.focused ? "2px solid #1b3a57 " : "1px solid #ddd"};
  border-radius: 5px;
  background: white;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
`;

interface Props {
  value?: string;
  file?: File;
  setFile: (file: File) => void;
  maxSize?: number;
}

const FileDrop: React.FC<Props> = ({
  value,
  file,
  setFile,
  maxSize=104857600 // 100mb
}) => {
  const [errorMessage, setErrorMessage] = useState<string|null>(null);
  const [fileUrl, setFileUrl] = useState<string|null>(null);
  const [focused, setFocused] = useState(false);

  const setupImage = async (path: string) => {
    const url = await getFromStorage(path);
    setFileUrl(url);
  }

  useEffect(() => {
    if(value && !file){
      setupImage(value);
    }
  }, []);

  useEffect(() => {
    if(file){
      const url = URL.createObjectURL(file);
      setFileUrl(url);
    }
  }, [file]);

  const handleFiles = (file: File): void => {
    setErrorMessage(null);
    const res = validateFile(file);
    if(res.error){
      setErrorMessage(res.message);
    } else {
      setFile(file);
    }
  }

  const validateFile = (file: File): { error: boolean, message: string } => {
    const validTypes = ['video/mp4','audio/mpeg'];
    if (validTypes.indexOf(file.type) === -1){
      return {
        error: true,
        message: "Invalid File Type"
      }
    };

    if (file.size > maxSize){
      return {
        error: true,
        message: "File size too large"
      }
    }
    return {
      error: false,
      message: "Valid file"
    }
  }

  const handleDrop = (event: React.DragEvent): void => {
    event.preventDefault();
    const data = event.dataTransfer.files;
    if(data.length){
      handleFiles(data[0]);
    }
    setFocused(false);
  }

  const handleDragOver = (event: React.DragEvent): void => {
    event.preventDefault();
    setFocused(true);
  }

  const handleDragLeave = (event: React.DragEvent): void => {
    event.preventDefault();
    setFocused(false);
  }

  return (
    <Wrapper>
      { errorMessage && <Error><WarningIcon color="error" />{ errorMessage }</Error> }
      <Container 
        focused={focused}
        onDrop={handleDrop}
        onDragOver={handleDragOver}  
        onDragLeave={handleDragLeave}
      >
        {
          fileUrl
          ? <Video key={fileUrl} controls>
              <source 
                src={fileUrl}
                type="video/mp4"
              >
              </source>
            </Video>
          : <p>Drag and drop files here</p>
        }
      </Container>
    </Wrapper>
  )
}

export default FileDrop;