import React from "react";
import styled from "styled-components";

const Container = styled.div``;

const TextInput = styled.input`
  box-sizing: border-box;
  height: 50.4px;
  padding: 15px 20px;
  border: none;
  background: #fff;
  width: 100%;
  min-width: 250px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;

  &:focus {
    outline: none;
    border: 2px solid #776589;
  }
`;

interface InputTypes {
  value: any;
  onChange: (value: any) => void;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  step?: number;
}

const Input: React.FC<InputTypes> = ({ 
  value, 
  onChange, 
  disabled, 
  type="text", 
  placeholder="", 
  step=1, 
  maxLength=999 
}) => {

  // const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    onChange(type === "number" ? parseFloat(value) : value);
  }

  return (
    <Container>
      <TextInput
        value={value}
        placeholder={placeholder} 
        onChange={handleChange} 
        type={type} 
        maxLength={maxLength}
        disabled={disabled}
        step={step}
      />
    </Container>
  )
}

export default Input;