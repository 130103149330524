import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { useParams, useLocation, useRouteMatch } from "react-router-dom";
import { getGameById, getCluesFromGameId } from "../../Firebase/games";
import gameReducer from "../../Reducers/gameReducer";
import LoadingItem from '../../Components/Loading/LoadingItem';

import HeaderTabs from "../../Components/Header/HeaderTabs";
import HeaderTab from "../../Components/Header/HeaderTab";
import GameEditNavigator from "../../Navigators/GameEditNavigator";
import useCurrentUser from "../../Hooks/useCurrentUser";
import CopyGame from "../../Components/Buttons/CopyGame";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  height: 100vh;
`;

const drawerWidth = 256;
const menuHeight = 64;

const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: auto;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: calc(100vw - ${drawerWidth}px);
    height: calc(100vh - ${2*menuHeight}px);
  }
`;

const GameScreen: React.FC = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const params = useParams<{ businessId: string, id: string }>();
  const [loading, setLoading] = useState(true);
  const [game, dispatch] = useReducer(gameReducer, null);
  const path = location.pathname.split("/")[5];
  const user = useCurrentUser();

  useEffect(() => {
    async function getGame(){
      const game = await getGameById(params.id);
      const clues = await getCluesFromGameId(params.id);
      dispatch({ type: "SET_GAME", payload: { ...game, clues } });
      setLoading(false);
    }

    getGame();
  }, []);

  return (
    <Container>
      <HeaderTabs>
        <div style={{ display: 'flex' }}>
        <HeaderTab 
          text="Main Info" 
          active={path === "main-information"} 
          to={match.url + "/main-information"} 
        />
        <HeaderTab 
          text="Puzzles" 
          active={path === "puzzles"} 
          to={match.url + "/puzzles"} 
        />
        <HeaderTab 
          text="Conclusion" 
          active={path === "conclusion"} 
          to={match.url + "/conclusion"} 
        />
        <HeaderTab 
          text="Theme" 
          active={path === "theme"} 
          to={match.url + "/theme"} 
        />
        <HeaderTab 
          text="Settings" 
          active={path === "settings"} 
          to={match.url + "/settings"} 
        />
        <HeaderTab 
          text="App Store" 
          active={path === "appstore"} 
          to={match.url + "/appstore"} 
        />
        </div>
        {
          user.role > 1 &&
          <CopyGame game={game} />
        }
      </HeaderTabs>
      <Body>  
        {
          loading
          ? <div style={{ width: '100%', height: 100, padding: 20 }}><LoadingItem /></div>
          : <GameEditNavigator 
              dispatch={dispatch}    
              game={game}
            />
        }
      </Body>
    </Container>
  )
}

export default GameScreen;