const defaultBusiness = {
  name: '',
  templates: [
    {
      title: "Voucher Code",
      data: {
        sender: "Roamr Admin",
        replyTo: "",
        subject: "Your New Game",
        message: 
`<div style='margin: auto; width: 100%; max-width: 560px; background: #f8f8f8; padding: 20px;'>
  <h1 style='color: #666;'>Here are your vouchers</h1>
  <p>Hi %NAME%,</p>
  <p>Here is your voucher for <strong>%GAME%</strong>. This is transferable should you want to give it as a gift or to another team member then just forward this email.</p>
  <div style='margin-top: 20px; margin-bottom: 20px;'>
    <p style='font-size: 1.2rem; color: #666; padding-bottom: 20px;'>Voucher Codes</p>
    %VOUCHERS%
  </div>
  <p>To get started, just create an account and redeem the voucher.</p>
  %BUTTON%
  <p>Thanks,</p>
  <p>Team Roamr</p>
</div>`
      }
    },
    {
      title: "Game Complete",
      data: {
        sender: "Roamr Admin",
        replyTo: "",
        subject: "You've completed a game!",
        message: 
`<h3>Congratulations on completing %GAME%!</h3>
<p>We hope you enjoyed the game.</p>`
      }
    },
    {
      title: 'Test Email',
      data: {
        sender: "Roamr Admin",
        replyTo: "",
        subject: "Test Email",
        message: 
`<p>This is a test</p>`
      }
    }
  ]
}

export default defaultBusiness;