// export const defaultButtons = [
//   // {
//   //   text: "Dashboard",
//   //   icon: <ImportantDevicesIcon />,
//   //   to: "stats"
//   // }, 
//   {
//     text: "Game Builder",
//     icon: <BuildIcon />,
//     to: "games"
//   },
//   {
//     text: "Business Users",
//     icon: <PeopleIcon />,
//     to: "users"
//   },
//   {
//     text: "Statistics",
//     icon: <TrendingUpIcon />,
//     to: "statistics"
//   },
//   {
//     text: "Plays",
//     icon: <SportsEsportsRoundedIcon />,
//     to: "plays"
//   },
//   {
//     text: "Vouchers",
//     icon: <VoucherIcon />,
//     to: "vouchers"
//   }
// ];

import { IconName } from "../../Icons/Icon"

type ButtonType = {
  text: string;
  icon: IconName;
  to: string;
}

export const defaultButtons: ButtonType[] = [
  {
    text: "Game Builder",
    icon: 'mapPin',
    to: "games"
  },
  {
    text: "Statistics",
    icon: 'barChart',
    to: "statistics"
  },
  {
    text: "Plays",
    icon: 'award',
    to: "plays"
  },
  {
    text: "Vouchers",
    icon: 'bookmark',
    to: "vouchers"
  }
];

export const adminButtons: ButtonType[] = [
  {
    text: "Businesses",
    icon: 'mapPin',
    to: "/businesses"
  },
  {
    text: "Business Users",
    icon: 'userBusiness',
    to: "/businesses/{id}/users"
  },
  {
    text: "Users",
    icon: 'users',
    to: "/users"
  },
];
