import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getGamesWhere, createNewGame } from "../../Firebase/games";
import { game as defaultGame } from "../../config/defaultGame";
import { Game } from "../../Types/games";
import { useRouteMatch } from 'react-router';

import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';
import { Paper } from "@material-ui/core";
import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";
import { useHistory } from "react-router-dom";
import TitleBox from "../../Components/Containers/TitleBox";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import LoadingButton from "../../Components/Buttons/LoadingButton";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import LoadingItem from "../../Components/Loading/LoadingItem";
import { getBusinessById } from "../../Firebase/businesses";

const Container = styled.div`
  padding: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 60px;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
`;

const Text = styled.p`
  margin: 0;
  color: black;
`;

const GamesScreen: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch() as any;

  const [games, setGames] = useState([] as Game[]);
  const [loading, setLoading] = useState(false);
  const [loadingGames, setLoadingGames] = useState(true);
  const flash = useFlashMessage();

  const columns: GridColDef[] = [
    { field: "name", headerName: "Game", width: 200 },
    { field: 'location', headerName: 'Location', width: 200 }
  ];

  useEffect(() => {
    const getGames = async () => {
      const data = await getGamesWhere('business', params.businessId);
      setGames(data);
      setLoadingGames(false);
    }

    getGames();
  }, [])

  const createGame = async () => {
    const business = await getBusinessById(params.businessId) as any;
    const res = await createNewGame({
      ...defaultGame,
      business: params.businessId,
      businessName: business.name
    });

    setLoading(false);
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      const flashMessage = { text: "Game successfully created", error: false };
      history.push(`games/${res.game.id}/main-information`, { flashMessage });
    }
  }

  const handleClick = (game: any): void => {
    history.push(`games/${game.id}/main-information`);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash }/> }
        <TitleBox title="Add new Game">
          <FormSection>
            <FormExplanation title="Add new Game" text="Add a new game, we will create one with default rules that you can edit" />
            <div><LoadingButton loading={loading} setLoading={setLoading} onClick={createGame} text="Add"/></div>
          </FormSection>  
        </TitleBox>
        
        <Paper>
          {
            loadingGames 
            ? <LoadingWrapper>
                <LoadingItem />
              </LoadingWrapper>
            : (
              games.length
              ? <DataGrid 
                  autoHeight
                  onRowClick={(param: GridRowParams) => handleClick(param.row)}
                  rows={games} 
                  columns={columns} 
                  pageSize={10} 
                /> 
              : <TitleBox title='Games'>
                  <TextWrapper>
                    <Text>You have no games, create a new one to begin</Text>
                  </TextWrapper>
                </TitleBox>
            )
          }
        </Paper>
    </Container>
  )
}

export default GamesScreen;