import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";
import defaultBusiness from '../../config/defaultBusiness';
import { getAllBusinesses, createNewBusiness } from "../../Firebase/businesses";

import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import TitleBox from "../../Components/Containers/TitleBox";
import Input from "../../Components/Forms/Input";
import LoadingButton from "../../Components/Buttons/LoadingButton";

import { Paper } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowParams } from '@material-ui/data-grid';
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import Breadcrumbs from "../../Components/Navigation/Breadcrumbs";
import LoadingItem from "../../Components/Loading/LoadingItem";
import "../../Styles/Tables.css";
import useCurrentUser from "../../Hooks/useCurrentUser";
import useAdminOnlyRoute from "../../Hooks/useAdminOnlyRoute";
import { MEDIA_QUERIES } from "../../config/constants";


const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

interface Business {
  id: string;
  name: string;
}

// create a business account
// add a user to this account
// export all at once
// tally by business of total sales ever, year to date, month to date
// Download csv of each credit
// Business, date, game, user email, userId,  
const FormRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
  }
`;

const BusinessesScreen: React.FC = () => {
  const flash = useFlashMessage();
  const history = useHistory();

  useAdminOnlyRoute();

  const [newBusinessName, setNewBusinessName] = useState("");
  const [businesses, setBusinesses] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", width: 200 },
    { field: 'created', headerName: 'Created', width: 200, valueFormatter: (params) => formatDate(params.value) },
  ];

  useEffect(() => {
    async function getBusinesses(){
      const res = await getAllBusinesses();
      setBusinesses(res);
      setLoading(false);
    }

    getBusinesses();
  }, []);

  const formatDate = (date: any): string => {
    if(!date) return "";
    const d = new Date(date.seconds*1000);
    return d.toLocaleDateString();
  }

  const handleClick = (param: GridRowParams) => {
    const business = param.row;
    history.push(`businesses/${business.id}`);
  }

  const createBusiness = async () => {
    setLoading(true);
    const res = await createNewBusiness({
      ...defaultBusiness,
      name: newBusinessName
    });
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      flash.showFlashMessage(res.message, false);
      setBusinesses([...businesses, res.payload]);
    }
    setLoading(false);
  }

  return (
    
    
    //   { flash.flashVisible && <FlashMessage { ...flash }/> }
    //   <Header title="Businesses" />
    //   <Body>
    <Container>
        <TitleBox title="Create new business">
          <FormSection>
            <FormExplanation title="Create new business" text="Create a new business" />
            <FormRow>
              <Input value={newBusinessName} onChange={setNewBusinessName} placeholder="Business Name" />
              <ButtonWrapper>
                <LoadingButton 
                  style={{ height: 50.4 }}
                  loading={loading} 
                  setLoading={setLoading} 
                  onClick={createBusiness} 
                  text="Create"
                />
              </ButtonWrapper>
            </FormRow>
          </FormSection>  
        </TitleBox>
        <Paper>
          { 
            loading
            ? <div style={{ width: '100%', height: 100 }}><LoadingItem /></div>
            : businesses && 
              <DataGrid 
                autoHeight
                onRowClick={(param: GridRowParams) => handleClick(param)}
                rows={businesses} 
                columns={columns} 
                pageSize={10} 
              /> 
          }
        </Paper>
        </Container>
    //   </Body>
    // </Container>
  )
}

export default BusinessesScreen;