import React from 'react';
import styled from 'styled-components';
import { round } from '../../helpers/round';
import toTimeString from '../../helpers/toTimeString';
import { Game, Play } from '../../Types/games';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const Item = styled.div`
  min-width: 200px;
`;

const Title = styled.p`
  font-weight: bold;
  color: #4b4b4b;
  font-size: 0.7rem;
  margin: 0;
`;

const Data = styled.p`
  margin: 0;
`;

interface Props {
  // play: Play;
  // game: Game;
  play: any;
  game: any;
  data: any;
}

const TimeLineOverview: React.FC<Props> = ({
  play,
  game,
  data
}) => {

  const ratings = data.filter((d: any) => d.rating !== null);
  const averageRating = ratings.length > 0 ? round(ratings.reduce((acc: number, d: any) => acc + d.rating, 0) / ratings.length, 1) : '-';

  return (
    <Container>
      <Row>
        <Item>
          <Title>Game</Title>
          <Data>{ game && game.name }</Data>
        </Item>
        <Item>
          <Title>Location</Title>
          <Data>{ game && game.location }</Data>
        </Item>
        <Item>
          <Title>Team Name</Title>
          <Data>{ play.teamname }</Data>
        </Item>
      </Row>
      <Row>
        <Item>
          <Title>Start</Title>
          <Data>{ play.start ? new Date(play.start).toLocaleTimeString() : '-' }</Data>
        </Item>
        <Item>
          <Title>End</Title>
          <Data>{ play.end != null ? new Date(play.end).toLocaleTimeString() : '-' }</Data>
        </Item>
        {
          play.finished &&
          <Item>
            <Title>Time</Title>
            <Data>{ toTimeString(play.end - play.start, true) }</Data>
          </Item>
        }
      </Row>
      <Row>
        <Item>
          <Title>Rating</Title>
          <Data>{ averageRating }</Data>
        </Item>
      </Row>
    </Container>
  )
}

export default TimeLineOverview;