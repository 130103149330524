import React, { useState } from "react";
import styled from "styled-components";
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Container = styled.div`
  border: 1px solid rgb(198, 190, 207);
  box-shadow: rgb(37 11 54 / 4%) 0px 2px 0px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid rgb(198, 190, 207);
  background: #faf9fb;
  border-radius: 4px 4px 0 0;
`;

const Text = styled.p`
  text-transform: uppercase;
  color: #776589;
  font-weight: bold;
  margin: 0;
`;

const Body = styled.div<{ isOpen: boolean }>`
  min-height: 100px;
  flex: 1;
  display: ${props => props.isOpen ? 'block' : 'none'}
`;

interface Props {
  children: React.ReactChild | ( React.ReactChild | false )[];
  title: string;
  expandable?: boolean;
}

const TitleBox: React.FC<Props> = ({ children, title, expandable=false }) => {
  const [isOpen, setIsOpen] = useState(!expandable);

  function handleClick(){
    if(expandable){
      setIsOpen(!isOpen);
    }
  }

  return (
    <Container>
      <Title onClick={handleClick}>
        <Text>{ title }</Text>
        {
          expandable &&
          (isOpen
          ? <FiChevronUp size={24} color='#776589'/>
          : <FiChevronDown size={24} color='#776589'/>)
        }
      </Title>
      <Body isOpen={isOpen}>
        { children }
      </Body>
    </Container>
  )
}

export default TitleBox;