import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Context as AuthContext } from '../../../Context/authContext';
// import IconButton from "../../Buttons/IconButton/IconButton";
import {
  HeaderElementWrapper,
  HeaderImage,
  HeaderText,
  HeaderWrapper,
  Logo,
  SideNavContainer,
  SideNavHeader,
  SideNavListTitle,
  SideNavWrapper,
} from "./SideNav.styled";
import NavigationButton from "./NavigationButton";
import { logout } from "../../../Firebase/auth";
import { ContextType } from "../../../Context/authContext";
import useIsMobile from "../../../Hooks/useIsMobile";
import { adminButtons, defaultButtons } from './buttons';
import Icon from "../../Icons/Icon";
// import useIsAdmin from "../../../Hooks/useIsAdmin";
// import logoImage from '../../../../'

const SideNav = () => {
  const { state } = useContext(AuthContext) as ContextType;
  const { user } = state;
  const isAdmin = user.role === 2;

  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    setIsVisible(isMobile ? false : true);
  }, [isMobile]);

  const handleClick = (link: string) => () => {
    if (isMobile) setIsVisible(false);
    history.push(link);
  }

  const handleLogout = async () => {
    await logout();
  }

  const toggleNav = () => {
    setIsVisible(!isVisible);
  }

  return (
    <SideNavWrapper isMobile={isMobile}>
      {
        isMobile &&
        <SideNavHeader>
          <HeaderElementWrapper>
            <Logo src="/assets/images/icon-green.png" />
          </HeaderElementWrapper>
          <HeaderElementWrapper>
            <Icon name="menu" onClick={toggleNav} size={24}/>
          </HeaderElementWrapper>
        </SideNavHeader>
      }
      {
        isVisible &&
        <SideNavContainer>
          <HeaderWrapper>
            <HeaderImage src='/assets/images/icon-green.png' />
            <HeaderText>ROAMR</HeaderText>
          </HeaderWrapper>
          <SideNavListTitle>navigation</SideNavListTitle>
          {
            defaultButtons.map(button => (
              <NavigationButton
                key={button.text}
                title={button.text}
                icon={button.icon}
                onClick={handleClick(`/businesses/${user.business.id}/${button.to}`)} 
                isMobile={isMobile}
              />
            ))
          }
          {
            isAdmin && <>
            <SideNavListTitle>admin pages</SideNavListTitle>
              {
                adminButtons.map(button => (
                  <NavigationButton
                    key={button.text}
                    title={button.text}
                    icon={button.icon}
                    onClick={handleClick(button.to.replace('{id}', user.business.id))} 
                    isMobile={isMobile}
                  />
                ))
              }
            </>
          }
          <SideNavListTitle>other</SideNavListTitle>
          <NavigationButton 
            title='logout'
            icon='logout'
            onClick={handleLogout}
            isMobile={isMobile}
          />
        </SideNavContainer>
      }
    </SideNavWrapper>
  )
};

export default SideNav;
