import React from "react";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

interface Props {
  onClick: () => void;
}

const AddButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} color="secondary" component="span">
      <RemoveCircleIcon />
    </IconButton>
  )
}

export default AddButton;