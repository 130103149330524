import React from 'react';
import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';
import { MEDIA_QUERIES } from '../../config/constants';

const Container = styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 200px;
    margin-right: 10px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
`;

const SubTitle = styled.p`
  color: #4b4b4b;
  margin: 0;
  font-size: 0.8rem;
`;

interface Props {
  title: string;
  subtitle: string;
  data: number[];
  colors: string[];
}

const DoughnutInfoBox: React.FC<Props> = ({
  title,
  subtitle,
  data,
  colors
}) => {
  
  const configuredData = {
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderColor: ['transparent']
      }
    ]
  };

  return (
    <Container>
      <ChartWrapper>
        <Doughnut 
          // type='doughnut' 
          data={configuredData} 
          width={60}
          height={60}
          options={{ maintainAspectRatio: false }}
        />
      </ChartWrapper>
      <InfoWrapper>
        <Title>{ title }</Title>
        <SubTitle>{ subtitle }</SubTitle>
      </InfoWrapper>
    </Container>
  
  )
}

export default DoughnutInfoBox;