import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loading from "../../Components/Loading/Loading";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RedirectScreen: React.FC = () => {
  const history = useHistory();
  const redirectUrl = "exp://192.168.1.68:19000/--/finishSignUp";

  useEffect(() => {
    // history.push();
    window.location.href = redirectUrl;
  }, []);

  return (
    <Container>
      <Loading />
    </Container>
  )
}

export default RedirectScreen;