import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import createVoucherCode from "../helpers/createVoucherCode";

const channel = {
  shopify: 0,
  roamrAdmin: 1,
  appleIAP: 2,
  androidIAP: 3
}

export const getCreditsByBusiness = async (id: string) => {
  try {
    const db = firebase.firestore();
    const ref = db.collection("credits")
    .where("business", "==", id)
    // .where("created", ">", 0)
    // .orderBy("created", "desc");

    const snapshot = await ref.get();
    return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }));
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const getCreditsWhere = async (key: string, value: string): Promise<any> => {
  try {
    const db = firebase.firestore();
    const ref = db.collection("credits").where(key, "==", value);
    const snapshot = await ref.get();
    return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() }));
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const createNewCredit = async (business: string, game: string, email: string, reference: string) => {
  // this also needs to be done as a http endpoint
  try {
    const db = firebase.firestore();
    const ref = db.collection("credits");
    const data = {
      business,
      game,
      email,
      reference,
      voucher: createVoucherCode(),
      expired: false,
      shouldExpire: true,
      emailSend: false,
      redeemed: false,
      channel: channel.roamrAdmin,
      created: Date.now()
    }

    await ref.add(data);
    
    return {
      success: true,
      message: "Credit created",
      payload: data
    }

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const createCredit = async (business: string, game: string, user: string) => {
  try {
    const db = firebase.firestore();
    const ref = db.collection("credits");
    await ref.add({
      business,
      game,
      user,
      voucher: createVoucherCode(),
      expired: false,
      channel: channel.roamrAdmin,
      created: Date.now()
    });
    return {
      success: true,
      message: "Credit created"
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const createMultipleVouchers = async (business: string, game: string, reference: string, amount: number): Promise<{ error: boolean, message: string, payload?: any[] }> => {

  try {
    const db = firebase.firestore();
    const batch = db.batch();
    const ref = db.collection("credits");

    const data = {
      batch: createVoucherCode(),
      business,
      game,
      reference,
      expired: false,
      shouldExpire: true,
      emailSend: false,
      redeemed: false,
      channel: channel.roamrAdmin,
      created: Date.now()
    }

    for(let i=0; i<amount; i++){
      batch.set(ref.doc(), { ...data, voucher: createVoucherCode() });
    }

    await batch.commit();
    const snapshot = await db.collection("credits").where("batch", "==", data.batch).get();
    const csv: any[] = [];
    if(!snapshot.empty){
      snapshot.docs.map((credit: any) => {
        const d = credit.data();
        csv.push([
          d.voucher,
          d.game,
          d.reference
        ])
      })
    }

    return {
      error: false,
      message: "Created vouchers",
      payload: csv
    }


  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const refundVoucher = async (id: string) => {
  try {
    const db = firebase.firestore();
    const ref = db.collection("credits").doc(id);
    const doc = await ref.get();
    const data = doc.data();
    if(data && !data.expired && !data.redeemed){
      await ref.set({
        expired: true, 
        reason: "refunded", 
        expiredDate: Date.now()
      }, { merge: true });
      return {
        success: true,
        message: "Successfully refunded"
      }
    }
    return {
      error: true,
      message: "Error in trying to remove voucher, please refresh"
    }

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export const expireCredit = async (id: string, reason: string) => {
  const db = firebase.firestore();
  const ref = db.collection("credits").doc(id);
  await ref.set(
    { expired: true, reason }, 
    { merge: true }
  );
}

export const createUsableCredit = async (
  business: string,
  game: string, 
  user: string, 
  email: string
) => {
  const db = firebase.firestore();
  const ref = db.collection("credits");
  const data = {
    business,
    game,
    user,
    email,
    voucher: createVoucherCode(),
    shouldExpire: true,
    expired: false,
    redeemed: true,
    channel: channel.roamrAdmin,
    created: Date.now()
  }

  try {
    await ref.add(data);
    return { error: false, message: `Successfully added game to ${email}` };
  } catch(err) {
    return { error: true, message: 'Couldn\'t add voucher' };
  }
}

export const createCreditOnUser = async (business: string, game: string, user: string, email: string) => {
   try {
    const db = firebase.firestore();
    const ref = db.collection("credits");
    const data = {
      business,
      game,
      user,
      email,
      voucher: createVoucherCode(),
      shouldExpire: false, // should the credit expire on game complete
      expired: false,
      redeemed: true,
      channel: channel.roamrAdmin,
      created: Date.now()
    }

    await ref.add(data);
    
    return {
      success: true,
      message: "Credit created",
      payload: data
    }

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}