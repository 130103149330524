import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { updateGameById, deleteGameById } from "../../Firebase/games";
import { Game } from "../../Types/games";
import { game as defaultGame } from "../../config/defaultGame";
import { addToStorage } from "../../Firebase/helpers";

import TitleBox from "../../Components/Containers/TitleBox";

import BottomBar from "../../Components/Forms/BottomBar";
import CheckBox from "../../Components/Forms/CheckBox";
import Select from "../../Components/Forms/Select";
import DeleteItem from "../../Components/Forms/DeleteItem";
import TextArea from "../../Components/Forms/TextArea";

import Input from "../../Components/Forms/Input";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import FileDrop from "../../Components/Forms/FileDrop";
import VideoDrop from "../../Components/Forms/VideoDrop";
import LocationPicker from "../../Components/Maps/LocationPicker";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";

const Container = styled.div`
  padding: 20px;
`;

interface LooseObject {
  [key: string]: any
}

interface Props {
  game: Game;
  dispatch: React.Dispatch<any>;
}

const EditInfoScreen: React.FC<Props> = ({ game, dispatch }) => {
  const history = useHistory();
  const { params } = useRouteMatch() as any;
  const flash = useFlashMessage();

  const [loading, setLoading] = useState(false);
  const [showTime, setShowTime] = useState(game.showTime);
  const [unlockOnEntry, setUnlockOnEntry] = useState(game.unlockOnEntry);

  async function submit(){
    const data = { showTime, unlockOnEntry };
    const res = await updateGameById(game.id, data);
    if(res.error){
      flash.showFlashMessage("Something went wrong, please try again", true);
    } else {
      flash.showFlashMessage("Game settings successfully updated", false);
      dispatch({ type: "UPDATE_GAME", payload: data });
    }
    setLoading(false);
  }

  async function deleteGame(){
    await deleteGameById(game.id);
    const flashMessage = { text: "Game successfully deleted", error: false };
    history.push(`/businesses/${params.businessId}/games`, { flashMessage });
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash }/> }
      <TitleBox title="configuration">
        <FormSection>
          <FormExplanation title="Show Time" text="Check this box if you would like the players under a time limit."/>
          <CheckBox checked={showTime} onChange={setShowTime} />
        </FormSection>
        {/* <FormSection>
          <FormExplanation title="Unlock on Entry" text="Check this box if you want clues to be locked until the user gets close" />
          <CheckBox checked={unlockOnEntry} onChange={setUnlockOnEntry} />
        </FormSection> */}
      </TitleBox>

      <TitleBox title="Delete Game">
        <FormSection>
          <FormExplanation title="Delete Game" text={`If you wish to delete the game and all its information write "${game.name}" in the input and press delete. This cannot be undone.`}/>
          <DeleteItem item={game.name} onClick={deleteGame}/>
        </FormSection>
      </TitleBox>
      <div style={{ height: 64 }}></div>
      <BottomBar loading={loading} onClick={submit} />
    </Container>
  )
}

export default EditInfoScreen;
