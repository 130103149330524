import { useHistory } from "react-router-dom";
import useCurrentUser from "./useCurrentUser";

const useAdminOnlyRoute = () => {
  const user = useCurrentUser();
  const history = useHistory();
  if(user.role < 2){
    history.replace('/forbidden');
  }
}

export default useAdminOnlyRoute;