import React from 'react';
import styled from 'styled-components';
import { Modal as MUIModal, Button } from '@material-ui/core';
import { Channel, Credit } from '../../Types/credits';
import { useHistory } from 'react-router';
import IconButton from '../Buttons/IconButton';

const Modal = styled(MUIModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  min-width: 800px;
  flex-direction: column;
  padding: 20px;
  background: white;
  border-radius: 5px;
`;

const Row = styled.div<{ border?: boolean }>`
  display: flex;
  border-bottom: ${props => props.border ? '1px solid #ccc' : 'none'};
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 0.8rem;
  margin: 0;
  padding-right: 10px;
  min-width: 60px;
`;

const Text = styled.p`
  margin: 0;
`;

interface Props {
  modalActive: boolean;
  handleClose: () => void;
  voucher: Credit;
}

const VoucherModal: React.FC<Props> = ({ 
  modalActive, 
  handleClose, 
  voucher, 
}) => {
  const history = useHistory();

  if (!modalActive) return null;

  return (
    <Modal
      open={modalActive}
      onClose={handleClose}
    >
      <Container>
        <Row border={true}>
          <Item>
            <Title>Voucher</Title>
            <Text>{ voucher.voucher }</Text>
          </Item>
          <Item>
            <Title>Date</Title>
            <Text>{ new Date(voucher.created).toLocaleDateString() }</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Title>Email</Title>
            <Text>{ voucher.email }</Text>
          </Item>
          <Item>
            <Title>Reference</Title>
            <Text>{ voucher.reference || '-' }</Text>
          </Item>
        </Row>
        <Row border={true}>
          <Item>
            <Title>Game</Title>
            <Text>{ voucher.game }</Text>
          </Item>
          <Item>
            <Title>Business</Title>
            <Text>{ voucher.business || '-' }</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Title>Channel</Title>
            <Text>{ Channel[voucher.channel] }</Text>
          </Item>
          <Item>
            <Title>Price</Title>
            <Text>{ voucher.price != null ? voucher.price : '-' }</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Title>Redeemed</Title>
            <Text>{ voucher.redeemed.toString() }</Text>
          </Item>
          <Item>
            <Title>Expired</Title>
            <Text>{ voucher.expired.toString() }</Text>
          </Item>
        </Row>
        <Row>
          <IconButton 
            icon='FaEnvelope' 
            text='Resend Email' 
            onClick={() => {}}
          />
          <IconButton 
            icon='FaUserPlus' 
            text='Add this voucher to this user' 
            onClick={() => {}}
          />
        </Row>
      </Container>
    </Modal>
  )
}

export default VoucherModal;