import React from "react";
import styled from "styled-components";
import Email from "@material-ui/icons/Email";

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: white;
  border: 1px solid #ccc;
  margin: 0 10px;  
`;

const Container = styled.div<{ active: boolean }>`
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.active ? props.theme.colors.info.tint : null};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Title = styled.p<{ active: boolean }>`
  font-weight: ${props => props.active ? "bold" : "normal"};
  color: ${props => props.active ? "#1967d2" : "#000000de"}
`;

interface Props {
  onClick: () => void;
  title: string;
  active: boolean;
}

const IconList: React.FC<Props> = ({ onClick, title, active }) => {
  return (
    <Container onClick={onClick} active={active}>
      <IconWrapper>
        <Email style={{ color: "#0000008a" }}/>
      </IconWrapper>
      <Title active={active}>
        { title }
      </Title>
    </Container>
  )
}

export default IconList;