import React, { useState } from "react";
import styled from "styled-components";
import Input from "./Input";
import { Button } from "@material-ui/core";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const buttonStyle = { 
  height: "50.4px"
};

const buttonActiveStyle = { 
  height: "50.4px",
  background: "#ed553b", 
  color: "white" 
};

interface Props {
  item: string;
  onClick: () => void;
}

const DeleteItem: React.FC<Props> = ({ item, onClick }) => {
  const [value, setValue] = useState("");

  const handleClick = () => {
    if(value === item){
      onClick();
    }
  };

  return (
    <Container>
      <Input value={value} onChange={setValue} />
      <ButtonWrapper>
        <Button 
          variant="contained" 
          style={value === item ? buttonActiveStyle : buttonStyle} 
          onClick={handleClick}
          disabled={value !== item}
        >Delete</Button>
      </ButtonWrapper>
    </Container>
  )
}

export default DeleteItem;