import React, { useState } from 'react';
import styled from 'styled-components';

import { 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  TableHead 
} from '@material-ui/core';
import VoucherModal from '../Modals/VoucherModal';
import Pill from '../Icons/Pill';

const Row = styled(TableRow)`
  &:hover {
    cursor: pointer;
    background: #f8f8f8;
  }
`;

const Title = styled(TableCell)`
  && {
    font-weight: bold;
  }
`;

interface Props {
  vouchers: any[];
  showingData: string;
}

const VoucherTable: React.FC<Props> = ({
  vouchers,
  showingData
}) => {

  const [modalActive, setModalActive] = useState(false);
  const [currentVoucher, setCurrentVoucher] = useState<null | any>();

  function getHead(){
    switch(showingData){
      case 'Vouchers':
        return (
          <>
            <Title>Voucher</Title>
            <Title>Status</Title>
          </>             
        )
      case 'Sales':
        return (
          <>
            <Title>Price</Title>
            <Title>Method</Title>
          </>
        )
    }
  }

  function getBody(voucher: any){
    const status = voucher.expired 
    ? { text: 'expired', color: '#f44336' }
    : (voucher.redeemed 
      ? { text: 'redeemed', color: '#4caf50' }
      : { text: 'new', color: '#2196f3' });

    const channel = [
      { text: 'Shopify', color: '#2196f3' },
      { text: 'Roamr', color: '#35AC8C' },
      { text: 'Apple', color: '#555' },
      { text: 'Android', color: '#3DDC84' }
    ][voucher.channel];

    switch(showingData){
      case 'Vouchers':
        return (
          <>
            <TableCell>{ voucher.voucher }</TableCell>
            <TableCell>
              <Pill 
                text={status.text} 
                background={status.color} 
                color='white'
              />
            </TableCell>
          </>             
        )
      case 'Sales':
        return (
          <>
            <TableCell>{ voucher.price == null ? '-' : voucher.price }</TableCell>
            <TableCell>
              <Pill 
                text={channel.text} 
                background={channel.color} 
                color='white'
              />
            </TableCell>
          </>
        )
    }
  }

  const handleClick = (voucher: any) => {
    setCurrentVoucher(voucher);
    setModalActive(true);
  }

  const handleClose = () => {
    setModalActive(false);
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Title>Date</Title>
            <Title>Email/Reference</Title>
            <Title>Game</Title>
            { getHead() }
          </TableRow>
        </TableHead>
        <TableBody>
        {
          vouchers.map((voucher: any) => {
            return (
              <Row onClick={() => handleClick(voucher)}>
                <TableCell>{ new Date(voucher.created).toLocaleDateString() }</TableCell>
                <TableCell style={{ maxWidth: 200 }}>{ voucher.email || voucher.reference || '-' }</TableCell>
                <TableCell>{ voucher.game }</TableCell>
                { getBody(voucher) }
              </Row>
            )
          })
        }
        </TableBody>
      </Table>
      {
        currentVoucher &&
        <VoucherModal 
          handleClose={handleClose}
          modalActive={modalActive}
          voucher={currentVoucher}
        />
      }
    </>
  )
}

export default VoucherTable;