import React from "react";
import styled from "styled-components";
import useDimensions from "../../Hooks/useDimensions";
import { Theme } from "../../Types/games";
import TitleBox from "../Containers/TitleBox";

interface ThemeProps {
  backgroundColor: string;
  color: string;
  size: number;
}

const ThemeDot = styled.div<ThemeProps>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 50px;
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  margin-right: 20px;
`;

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const ThemeBox: React.FC<{ theme: Theme }> = ({ theme }) => {
  const [ref, { width }] = useDimensions<HTMLDivElement>();
  const size = width / 6;

  return (
    <TitleBox title="Your Theme">
      <Container ref={ref}>
        <ThemeDot backgroundColor={theme.primary.color} color={theme.primary.font} size={size} />
        <ThemeDot backgroundColor={theme.secondary.color} color={theme.secondary.font} size={size} />
        <ThemeDot backgroundColor={theme.tertiary.color} color={theme.tertiary.font} size={size} />
        <ThemeDot backgroundColor={theme.icon.color} color="#fff" size={size} />
      </Container>
    </TitleBox>
  )
}

export default ThemeBox;