import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled(Link)`
  text-decoration: none;
`;

interface ButtonProps {
  children: React.ReactNode;
  to: string;
}

const NavigationButton: React.FC<ButtonProps> = ({ children, to }) => {
  return (
    <Container to={to}>
      { children }
    </Container>
  )
}

export default NavigationButton;