import { Analytic, Clue, Game, Play } from "../Types/games";
import { round } from "./round";

const filterTypes = (analytic: Analytic) => {
  const _types = ['correct', 'wrong', 'hint'];
  return _types.indexOf(analytic.type) > -1;
}

const addDataFromAnalytics = (id: string, analytics: Analytic[]) => {
  const data = analytics
  .filter(analytic => analytic.clue === id)
  .filter(analytic => filterTypes(analytic))
  .map(analytic => {
    switch(analytic.type){
      case "correct":
        return {
          type: "correct",
          guess: analytic.guess,
          date: analytic.date,
          rating: analytic.rating,
          color: '#4caf50'
        }
      case "wrong":
        return {
          type: "wrong",
          guess: analytic.guess,
          date: analytic.date,
          color: '#f44336'
        }
      case "hint":
        return {
          type: "hint",
          number: 1, // do this
          date: analytic.date,
          color: '#2196f3'
        }
      default:
        return {}
    }
  }) as any[];

  return data;
}

const getClueData = (id: string, analytics: Analytic[], startTime: number) => {
  const dataPoints = addDataFromAnalytics(id, analytics);
  const ratings = dataPoints
  .filter(data => data.type === "correct" && data.rating !== undefined);

  const correct = dataPoints.filter(data => data.type === 'correct');

  const clueTime = correct.length > 0 ? correct[0].date : null;
  // const time = clueTime ? clueTime - startTime : null;

  // REMOVE DUPLICATE CORRECT ANSWERS

  if(ratings.length){
    const rating = ratings.reduce((acc, data) => acc + data.rating!, 0);
    return {
      rating: round(rating / ratings.length, 2),
      time: clueTime,
      dataPoints
    }
  }
  return {
    rating: null,
    time: clueTime,
    dataPoints
  }
}

const configureTimelineData = (
  game: Game,
  play: Play,
  analytics: Analytic[]
) => {
  const start = play.start;
  const data = game.clues
  .sort((a, b) => a.order - b.order)
  .map((clue: Clue) => {
    const clueData = getClueData(clue.id, analytics, start);
    return {
      id: clue.id,
      name: clue.title,
      start,
      ...clueData
    };
  });

  return data;
}

export default configureTimelineData;