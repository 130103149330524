import React from "react";
import styled from "styled-components";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  padding-right: 50px;
  margin: auto 0;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 350px;
  }
`;

const Title = styled.h2`
  color: #776589; 
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;

const Text = styled.p`
  color: #666;
  opacity: 0.9;
  font-size: 14px;
  margin: 16px 0;
`;

interface Props {
  title: string;
  text?: string;
}

const FormExplanation: React.FC<Props> = ({ title, text }) => {
  return (
    <Container>
      <Title>{ title }</Title>
      {
        text &&
        <Text>{ text }</Text>
      }
    </Container>
  )
}

export default FormExplanation;