import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 10px;
`;

const LoadingItem = () => {
  return <Container>
    <Skeleton 
      animation='wave'
      variant='rect' 
      width='100%' 
      height='100%' 
    />
  </Container>
}

export default LoadingItem;