import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import PlayScreen from "../Screens/Plays/Timeline";
import PlaysScreen from "../Screens/Plays/PlaysScreen";

const GamesNavigator: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <PlaysScreen />
      </Route>
      <Route path={`${path}/new`}>
        
      </Route>
      <Route path={`${path}/:id`}>
        <PlayScreen />
      </Route>
    </Switch>
  )
}

export default GamesNavigator;