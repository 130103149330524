import styled, { css } from 'styled-components';
import { HEADER_HEIGHT, MEDIA_QUERIES, SIDE_NAV_WIDTH } from '../../../config/constants';

export const SideNavWrapper = styled.nav<{ isMobile: boolean }>`
  ${props => props.isMobile ? css`
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background: white;
  ` : css`
    display: flex;
    flex-direction: column;
    width: ${SIDE_NAV_WIDTH}px;
    flex: 0 0 auto;
    height: 100vh;
    border-right: 1px solid #05110e40;
    box-shadow: #1111110a 2px 0px 0px;
    background: #fbfbfb;
  `}
`;

export const SideNavHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid rgb(198,190,207);
`;

export const SideNavContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  max-height: 100%;
  overflow-y: scroll;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

export const HeaderWrapper = styled.div`
  display: none;
  align-items: center;
  margin: 16px;
  margin-bottom: 0;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
  }
`;

export const HeaderImage = styled.img`
  width: 42px;
  height: 42px;
  margin-right: 16px;
`;

export const HeaderText = styled.h1`
  margin: 0;
  color: #34ac8c;
  font-size: 32px;
  font-family: ALoveOfThunder;
  letter-spacing: 1px;
`;

export const SideNavListItem = styled.li`
  list-style: none;
  margin: 0 8px 8px;
`;

export const SideNavButtonWrapper = styled.a<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s;

  /* ${props => props.isMobile && css`
    justify-content: center;
  `} */

  &:hover,
  &:focus,
  &:active {
    background: #d8f3ec;
    color: #34ac8c;
  }
`;

export const SideNavButtonText = styled.p`
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  margin: 0;
`;

export const SideNavIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
`;

export const SideNavListTitle = styled.h3`
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 24px 24px 16px;
`;

export const HeaderElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
`;

export const Logo = styled.img`
  width: 24px;
  height: 24px;
`;
