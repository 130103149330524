import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { finishSignUp } from "../../Firebase/auth";

import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import Input from "../../Components/Forms/Input";
import SubmitButton from "../../Components/Buttons/SubmitButton";
import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

const FinishSignUpScreen: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const [showEmailProvider, setShowEmailProvider] = useState(false);
  const [text, setText] = useState("");
  const [email, setEmail] = useState<null | string>(null);

  useEffect(() => {
    const email = window.localStorage.getItem('emailForSignIn');
    if(!email){
      setShowEmailProvider(true);
    } else {
      setEmail(email);
    }
  }, []);

  useEffect(() => {
    async function signin(){
      if(email){
        const res = await finishSignUp(email, () => history.push("/home"));
        if(res.error){
          setError(res.message);
        }
      }
    }
    
    signin()
  }, [email]);

  return (
    <Container>
      <Header title="Authenticating" />
      <Body>
        {
          showEmailProvider && 
          <>
            <FormSection>
              <FormExplanation title="Email" text="Who are we authenticating?" />
              <Input value={text} onChange={setText} />
            </FormSection>
            <ButtonWrapper>
              <SubmitButton loading={loading} setLoading={setLoading} onPress={() => setEmail(text)} />
            </ButtonWrapper>
          </>
        }
        {
          error && <p>{ error }</p>
        }
      </Body>
    </Container>
  )
}

export default FinishSignUpScreen;