import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import useProtectedRoute from "../Hooks/useProtectedRoute";
import NewUserScreen from "../Screens/Users/NewUserScreen";
import UserScreen from "../Screens/Users/UserScreen";
import UsersScreen from "../Screens/Users/UsersScreen/UsersScreen";

const GamesNavigator: React.FC = () => {
  const { path } = useRouteMatch();

  const authenticated = useProtectedRoute();

  if(!authenticated){
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        <UsersScreen />
      </Route>
      <Route path={`${path}/new`}>
        <NewUserScreen />
      </Route>
      <Route path={`${path}/:id`}>
        <UserScreen />
      </Route>
    </Switch>
  )
}

export default GamesNavigator;