import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Popover from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import Select from "../Select";
import Input from "../Input";

import { getGamesWhere } from "../../../Firebase/games";
import { createCredit, getCreditsWhere, createCreditOnUser } from "../../../Firebase/credits";
import Loading from "../../Loading/Loading";
import LoadingButton from "../../Buttons/LoadingButton";
import TitleBox from "../../Containers/TitleBox";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 400px;
  background: white;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 520px;
  margin: 20px;
`;

const Title = styled.p`
  margin: 0;
  font-weight: bold;
`;

const FormItem = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-weight: bold;
  font-size: 0.8rem;
`;

interface Props {
  user: any;
  business: any;
  visible: boolean;
  showFlash: (text: string, error: boolean) => void;
  setVisible: (bool: boolean) => void;
}

const AddCreditModal: React.FC<Props> = ({
  user, // 
  business, // id
  visible,
  showFlash,
  setVisible
}) => {
  const [loading, setLoading] = useState(false);
  const [games, setGames] = useState<any|null>();
  const [game, setGame] = useState<string|null>();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getGames = async () => {
      const res = await getGamesWhere("business", business.id);
      if(res.error){
        console.log(res.message);
      } else {
        setGames(res);
      }
    } 

    getGames();
  }, []);

  const submit = async () => {
    if(game){
      // const res = await createCredit(business.id, game, user.id);
      const res = await createCreditOnUser(business.id, game, user.id, user.email);
      if(res.error){
        showFlash(res.message, true);
      } else {
        showFlash(res.message, false);
        setVisible(false);
      }
    }
    setLoading(false);
  }

  return (
    <Popover style={{ display: "flex", alignItems: "center", justifyContent: "center" }} open={visible} onClose={() => setVisible(false)}>
      <Container>
        <div>
          <FormItem>
            <Title>Add Games</Title>
            <Text>{ user.email }</Text>
          </FormItem>
          <FormItem>
            <Text>Game</Text>
            { 
              games && 
              <Select 
                value={game} 
                onChange={setGame} 
                options={games.map((g: any) => ({ name: g.name, value: g.id }))} 
              /> 
            }
          </FormItem>
          <LoadingButton
            loading={loading}
            setLoading={setLoading}
            text="Submit"
            onClick={submit}
          />
        </div>
      </Container>
    </Popover>
  )
}

export default AddCreditModal;