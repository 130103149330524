import React from "react";
import styled from "styled-components";
import { CircularProgress, Button } from "@material-ui/core";

const Text = styled.p`
  margin: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface Props {
  text?: string;
  style?: object;
  loading: boolean;
  variant?: "text" | "contained" | "outlined" | undefined;
  setLoading: (val: boolean) => void;
  onClick: () => void;
}

const LoadingButton: React.FC<Props> = ({ loading, setLoading, onClick, style, text="submit", variant="contained" }) => {

  const handleClick = () => {
    if(!loading){
      setLoading(true);
      onClick()
    }
  }

  return (
    <Button 
      style={style}
      variant={variant}
      color="primary"
      onClick={handleClick}
    >
      <Text style={{ visibility: loading ? "hidden" : "visible" }}>{ text }</Text>
      { 
        loading && 
        <IconWrapper>
          <CircularProgress 
            style={{ color: variant === "contained" ? "white" : undefined }} 
            color="secondary" 
            size={20} 
          /> 
        </IconWrapper>
      }
    </Button>
  )
}

export default LoadingButton;