import React from "react";
import styled from "styled-components";

import configureClueAnalytics from "../../helpers/configureClueAnalytics";
import DoughnutInfoBox from "../../Components/Statistics/DoughnutInfoBox";
import BarChartInfoBox from "../../Components/Statistics/BarChartInfoBox";
import WrongGuessTable from '../../Components/Statistics/WrongGuessTable';


const Container = styled.div`
  padding: 20px;
`;

const StatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

interface Props {
  game: any;
  data: any;
  clue: string;
}

const StatisticsScreen: React.FC<Props> = ({
  game,
  data,
  clue
}) => {
  // const clueData = data.map((play: any) => {
  //   return play.analytics.filter((analytic: any) => analytic.clue === clue)
  // }).flat();
  // const sortedData = configureClueAnalytics(clueData);

  const sortedData = configureClueAnalytics(data, clue);

  const guesses = Math.round(sortedData.guesses.count / sortedData.guesses.total * 100);
  const averageRatings = sortedData.rating.total > 0 
    ? Math.round(sortedData.rating.count / sortedData.rating.total * 10) / 10
    : 0;
  const wrongGuesses = [];
  for(let guess in sortedData.wrongGuesses){
    wrongGuesses.push([guess, sortedData.wrongGuesses[guess]]);
  }
  wrongGuesses.sort((a,b) => b[1] - a[1]);
  const hints = sortedData.complete.count > 0 ? sortedData.hints.count / sortedData.complete.count : 0;
  const averageHints = Math.round(hints * 10) / 10;

  return (
    <Container>
      <StatsRow>
        <DoughnutInfoBox 
          title={averageRatings.toString()}
          subtitle='Average Rating'
          data={[averageRatings, 5-averageRatings]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={guesses.toString()}
          subtitle='Percentage Correct'
          data={[guesses, 100-guesses]} 
          colors={['#35ac8c','#ddd']}
        />
        <DoughnutInfoBox 
          title={averageHints.toString()}
          subtitle='Average Hints'
          data={[averageHints, 3-averageHints]} 
          colors={['#35ac8c','#ddd']}
        />
      </StatsRow>
      <StatsRow>
        <BarChartInfoBox
          title='Ratings'
          subtitle='This graph shows the ratings for all puzzles within completed plays.'
          colors={['#35ac8c']}
          data={sortedData.rating.values}
        />
      </StatsRow>
      <StatsRow>
        <WrongGuessTable data={wrongGuesses}/>
      </StatsRow>
    </Container>
  )
}

export default StatisticsScreen;