import React, { useEffect } from "react";

import SwitchConfig from "./SwitchConfig";
import PadlockConfig from "./PadlockConfig";
import DecoderConfig from "./DecoderConfig";

interface ConfigProps {
  value: string | null;
  setValue: (e: string) => void;
}

const DefaultConfig: React.FC<ConfigProps> = ({ value, setValue }) => {
  useEffect(() => {
    if(value === null){
      setValue(JSON.stringify({ start: [] }));
    }
  }, [value]);
  return null;
}

interface LooseObject {
  [key: string]: any
}

const config: LooseObject = {
  "input": DefaultConfig,
  "keypad": DefaultConfig,
  "switch": SwitchConfig,
  "safelock": DefaultConfig,
  "padlock": PadlockConfig,
  "compass": DefaultConfig,
  "morsecode": DefaultConfig,
  "decoder": DecoderConfig,
  "telephone": DefaultConfig
}

interface Props {
  type: string;
  value: string | null;
  setValue: (value: string) => void;
}

const GadgetConfig: React.FC<Props> = ({ 
  type, 
  value, 
  setValue 
}) => {

  const Config = config[type];
  return <Config value={value} setValue={setValue} />;
}

export default GadgetConfig;