import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import { getBusinessById } from "../../Firebase/businesses";
import { createNewBusinessAdmin, resetPassword } from "../../Firebase/auth";
import useUserRole from "../../Hooks/useUserRole";
import { getUsersWhere } from "../../Firebase/users";

import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import TitleBox from "../../Components/Containers/TitleBox";
import Input from "../../Components/Forms/Input";
import LoadingButton from "../../Components/Buttons/LoadingButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";

import AddCreditModal from "../../Components/Forms/Businesses/AddCreditModal";
import Loading from "../../Components/Loading/Loading";
import useCurrentUser from "../../Hooks/useCurrentUser";
import useAdminOnlyRoute from "../../Hooks/useAdminOnlyRoute";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  padding: 20px;
`;

const FormRow = styled.div`
  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    display: flex;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    margin-top: 0;
    margin-left: 10px;
  }
`;

const TableRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgb(198,190,207);
  padding: 16px;

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
`;

const Text = styled.p`
  margin: 0;
  color: black;
`;

// Add a user (They can't do this)
// table of sales ever, year to date, month to date

const BusinessScreen: React.FC = () => {
  const user = useCurrentUser();

  const params = useParams<{ businessId: string }>();
  const flash = useFlashMessage();

  useAdminOnlyRoute();
  
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [loadingNewUser, setLoadingNewUser] = useState(false);
  const [email, setEmail] = useState("");
  const [business, setBusiness] = useState<null|any>(null);
  const [users, setUsers] = useState<null|any>(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalUser, setModalUser] = useState<string | null>(null);

  async function getBusinessData(){
    const res = await getBusinessById(params.businessId);
    setBusiness(res);
  }

  async function getUserData(){
    const res = await getUsersWhere("business", params.businessId);
    setUsers(res);
  }

  useEffect(() => {
    getBusinessData();
    getUserData();
  }, []);

  const createUser = async () => {
    if(email != ""){ // need to check email
      const res = await createNewBusinessAdmin(email, params.businessId);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        flash.showFlashMessage(res.message, false);
      }
    }
    setEmail("");
    setLoadingNewUser(false);
  }

  const sendResetPasswordEmail = async (email: string) => {
    const res = await resetPassword(email);
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      flash.showFlashMessage(res.message, false);
    }
    setPasswordResetLoading(false);
  } 

  const openModal = (user: any) => {
    setModalVisible(true);
    setModalUser(user);
  }
  
  return (
    <Container>

        { flash.flashVisible && <FlashMessage {...flash} /> }
        {
          user && user.role === 2 &&
          <TitleBox title="New User">
            <FormSection>
              <FormExplanation title="Create new user" text={"This user will have full access to the " + business?.name + " account, including the ability to create and edit games."} />
              <FormRow>
                <Input value={email} onChange={setEmail} placeholder="Email" />
                <ButtonWrapper>
                  <LoadingButton 
                    style={{ height: 50.4 }}
                    loading={loadingNewUser} 
                    setLoading={setLoadingNewUser} 
                    onClick={createUser} 
                    text="Create"
                  />
                </ButtonWrapper>
              </FormRow>
            </FormSection>  
         </TitleBox>
        }
        <TitleBox title="Users">
          {
            users && users.length 
            ? users.map((user: any) => {
              return <TableRow key={user.email}>
                <Text>{ user.email }</Text>
                <div>
                  <Button onClick={() => sendResetPasswordEmail(user.email)}>Reset Password</Button>
                  <Button onClick={() => openModal(user)}>Add Credit</Button>
                </div>
              </TableRow>
            })
            : <TextWrapper>
                <Text>No Users</Text>
              </TextWrapper>
          }
        </TitleBox>

        { 
          modalVisible && modalUser && 
          <AddCreditModal 
            user={modalUser} 
            business={business}
            visible={modalVisible} 
            setVisible={setModalVisible}
            showFlash={flash.showFlashMessage}
          /> 
        }

    </Container>
  )
}

export default BusinessScreen;