import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import Header from "../../Components/Header/Header";
import TitleBox from "../../Components/Containers/TitleBox";
import Body from '../../Components/Body/Body';
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";

const Container = styled.div`
  height: 100vh;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const AccountDetailsScreen: React.FC = () => {
  const flash = useFlashMessage();

  useEffect(() => {
    // get business
  }, []);

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Body>
        <TitleBox title='Business Details'>
          <FormSection border={false}>
            
            
          </FormSection>
          <FormSection border={false}>
            
          </FormSection>
        </TitleBox>
      </Body>
    </Container>
  )
}

export default AccountDetailsScreen;