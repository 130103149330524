import React, { useState } from "react";
import styled from "styled-components";
import { createNewGame } from "../../Firebase/games";
import { useHistory } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import TitleBox from "../../Components/Containers/TitleBox";
import FormSection from "../../Components/Forms/FormSection";
import FormExplanation from "../../Components/Forms/FormExplanation";
import Input from "../../Components/Forms/Input";
import SubmitButton from "../../Components/Buttons/SubmitButton";
import Header from "../../Components/Header/Header";
import Body from "../../Components/Body/Body";
import { Button } from "@material-ui/core";


import { game, theme } from "../../config/defaultGame";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;  
  padding: 16px;
`;

const NewGameScreen: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(game.name);
  const [location, setLocation] = useState(game.location);

  async function submit(){
    if(!loading){
      setLoading(true);
      await createNewGame({
        ...game,
        theme: theme,
        name,
        location
      });
      setLoading(false);
      history.push("/games");
    }
  }

  return (
    <Container>
      <Header title="New Game" />
      <Body>
        <TitleBox title="New Game">
          <FormSection>
            <FormExplanation title="Name" text="What is the name of the game." />
            <Input value={name} onChange={setName} />
          </FormSection>
          <FormSection>
            <FormExplanation title="Location" text="What city is the game to take place." />
            <Input value={location} onChange={setLocation} />
          </FormSection>
          <ButtonWrapper>
            <Button 
              variant="contained"
              color="primary"
              onClick={submit}
            >
              {
                loading
                ? <CircularProgress style={{ color: "white" }} color="secondary" size={20} />
                : "Submit"
              }
            </Button>
          </ButtonWrapper>
        </TitleBox>
      </Body>
    </Container>
  )
}

export default NewGameScreen;