import React from "react";
import styled, { keyframes } from "styled-components";
import SettingsIcon from "@material-ui/icons/Settings";

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface P {
  transform?: any;
  clockwise?: boolean;
}

const IconWrapper = styled.div<P>`
  position: absolute;
  animation: ${rotate} 5s linear infinite;
  animation-direction: ${props => props.clockwise ? "normal" : "reverse"};
  // transform: translateX(${props => props.transform ? props.transform.x : 0}) translateY(${props => props.transform ? props.transform.y : 0});
`;

interface Props {

}

const LoadingScreen:React.FC<Props> = () => {
  return null;
  
  return (
    <Container>
      <IconWrapper>
        <SettingsIcon style={{ color: "red", fontSize: 36 }} />
      </IconWrapper>
      <IconWrapper transform={{ x: 20, y: 30 }} clockwise>
        <SettingsIcon style={{ color: "red", fontSize: 36 }} />
      </IconWrapper>
      <IconWrapper>
        <SettingsIcon style={{ color: "red", fontSize: 36 }} />
      </IconWrapper>
    </Container>
  )
}

export default LoadingScreen;