import React, { useState } from "react";
import styled from "styled-components";
import Loading from "../Loading/Loading";

interface StyleProps {
  color: string;
  background: string;
}

interface Props {
  color?: string;
  background?: string;
  title?: string;
  onPress: () => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

const Container = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px 16px;
  width: 82px;
  height: 40px;
  color: ${props => props.color};
  background: ${props => props.background};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 20px;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
  }
`;

const Text = styled.p`
  margin: 0;
  font-weight: bold;
`;

const SubmitButton: React.FC<Props> = ({
  color="white",
  background="orange",
  title="submit",
  loading,
  setLoading,
  onPress
}) => {

  const handleClick = () => {
    if(!loading){
      setLoading(true);
      onPress();
    }
  }

  return (
    <Container
      color={color}
      background={background}
      onClick={handleClick}
    >
      {
        loading
        ? <Loading />
        : <Text>{ title }</Text>
      }
    </Container>
  )
}

export default SubmitButton;