import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { stringify } from "querystring";
import generatePassword from "../helpers/generatePassword";

const role = {
  player: 0,
  business: 1,
  admin: 2
}

export async function createNewBusinessAdmin(email: string, business: string){
  const functions = firebase.app().functions('us-central1');
  const createUser = functions.httpsCallable('http-createUser');
  const sendMail = functions.httpsCallable('helpers-sendMail');
  
  try { 
    const db = firebase.firestore();
    const snapshot = await db.collection("users").where("email", "==", email).get();
    if(snapshot.empty){

      console.log('Email does not exist, creating a new account');

      const password = generatePassword();
      const { data } = await createUser({ email, password }) as any;
      
      const userRef = db.collection("users").doc(data.uid);
      await userRef.set({
        email,
        role: role.business,
        created: Date.now(),
        loginEmailSent: false,
        passwordChanged: false,
        business: business
      });

      // await sendMail({
      //   dest: email,
      //   subject: "Welcome",
      //   content: `<p>Welcome to <strong>Roamr</strong></p>
      //   <p>You can login with the following:</p>
      //   <p>Email: <strong>${email}</strong></p>
      //   <p>Password: <strong>${password}</strong></p>`
      // })

      return {
        success: true,
        message: "New user successfully created",
        payload: password,
      };
    
    } else {
      console.log('Email exists, altering user...');

      const id = snapshot.docs[0].id;
      await db.collection("users").doc(id).set({
        business,
        role: role.business
      }, { merge: true });
      return {
        success: true,
        message: "User successfully added"
      }
    }
    
  } catch(err: any){
    console.log(err);
    return {
      error: true,
      message: err.message
    }
  }
}


export async function createNewUser(email: string): Promise<any> {
  const createUser = firebase.functions().httpsCallable('auth-createUser');
  const sendMail = firebase.functions().httpsCallable('helpers-sendMail');
  
  try { 

    const password = generatePassword();
    const res = await createUser({ email, password }) as any;
    const db = firebase.firestore();
    const userRef = db.collection("users").doc(res.uid)
    await userRef.set({
      email,
      role: role.player,
      created: Date.now(),
      loginEmailSent: false,
      passwordChanged: false,
      businessLinks: [] // add businessId here
    });

    await sendMail({
      dest: email,
      subject: "Welcome",
      content: `<p>Welcome to <strong>City Escape Games</strong></p>
      <p>You can login with the following:</p>
      <p>Email: <strong>${email}</strong></p>
      <p>Password: <strong>${password}</strong></p>`
    })

    return {
      success: true,
      message: "New user successfully created"
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function resetPassword(email: string) {
  try {
    var auth = firebase.auth();
    await auth.sendPasswordResetEmail(email);
    return {
      success: true,
      message: "Password reset email sent"
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
  
}

// export async function signUp(email: string, password: string): Promise<void> {
//   try {
//     await firebase.auth().createUserWithEmailAndPassword(email, password);
//     const currentUser = firebase.auth().currentUser;
//     console.log("New user: " + email)
//     const db = firebase.firestore();
//     db.collection("users")
//       .doc(currentUser.uid)
//       .set({
//         email: currentUser.email,
//         // lastName: lastName,
//         // firstName: firstName,
//       });
//   } catch (err) {
//     console.log(err.message);
//   }
// }

export async function finishSignUp(email: string, callback: () => void): Promise<any>{
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    firebase.auth().signInWithEmailLink(email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        console.log(result.user);
        return callback();
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((err) => {
        console.log(err);
        return { error: true, message: err.message };
      });
  } else {
    return { error: true, message: "Please use link from email" }
  }
}

export async function sendEmailLink(email: string): Promise<any> {

  const actionCodeSettings = {
    url: 'https://city-escape-games.web.app/finishSignUp',
    handleCodeInApp: true
  };

  try {
    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
    return { success: true, message: "Success! A link has been sent to the email provided." }
  } catch(err: any){
    console.log(err);
    return { error: true, message: err.message }
  }
}

export async function signIn(email: string, password: string): Promise<any> {
  try {
   await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return {
      success: true,
      message: "Successfully signed in"
    }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function logout() {
  try {
    await firebase.auth().signOut();
    return {
      success: true,
      message: "Successfully logged out"
    }
  } catch (err: any) {
    return {
      error: true,
      message: err.message
    }
  }
}

export const getVerificationLink = async (email: string) => {
  const getLink = firebase.functions().httpsCallable('http-getVerificationLink');
  try {
    return await getLink(email) as any;
  } catch (err) {
    console.log(err);
  }
};
