import firebase from "firebase/app";
import "firebase/firestore";
import { User } from "../Types/users";

interface Error {
  error: boolean;
  message: string;
}

export async function getUserById(id: string): Promise<User|Error|any> { // fix this
  try {
    const db = firebase.firestore();
    const doc = await db.collection("users").doc(id).get();
    if(doc.exists){
      const data = doc.data();
      if(data?.business){
        const business = await db.collection('businesses').doc(data.business).get();
        if(business.exists){
          return {
            id: doc.id,
            ...doc.data(),
            business: {
              id: business.id,
              ...business.data()
            }
          }
        }
      }
      return {
        id: doc.id,
        ...doc.data()
      }
    }
    return {
      error: true,
      message: "Couldn't find user"
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getUsersWhere(key: string, value: any): Promise<any> {
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("users").where(key, "==", value).get();

    return snapshot.docs.map((doc: any) => {
      return {
        id: doc.id,
        ...doc.data()
      }
    })

  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}

export async function getAllUsers(): Promise<any>{
  try {
    const db = firebase.firestore();
    const snapshot = await db.collection("users").get();

    if(snapshot.empty){
      console.log("No Results");
    }

    return snapshot.docs.map((doc: any) => {
      return {
        id: doc.id,
        ...doc.data()
      }
    })

  } catch (err) {
    return {
      error: true,
      message: "Something went wrong"
    }
  }
}

export async function updateUserById(id: string, update: object): Promise<any> {
  try {
    const db = firebase.firestore();
    const userRef = db.collection('users').doc(id);
    await userRef.update(update);
    return {
      error: false,
      message: 'User successfully updated'
    }
  } catch(err: any){
    return {
      error: true,
      message: err.message
    }
  }
}