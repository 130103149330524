import React, { useState } from "react";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import Input from "../../Components/Forms/Input";
import LoadingButton from "../../Components/Buttons/LoadingButton";
import { signIn } from "../../Firebase/auth";
import "../../index.css";
import { Container, FormRow, LeftSide, Logo, LogoTitle, RightSide, SignInForm, Text, Title } from './SignInScreen.styled';


const HomeScreen: React.FC = () => {
  const flash = useFlashMessage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    const res = await signIn(email, password);
    if(res.error){
      flash.showFlashMessage(res.message, true);
    }
    setPassword("");
    setLoading(false);
  }

  const handleClick = (e: React.KeyboardEvent) => {
    if(e.key === "Enter"){
      setLoading(true);
      submit();
    }
  }

  return (
    <Container onKeyPress={handleClick}>
      { flash.flashVisible && <FlashMessage { ...flash } /> }
      <LeftSide>
        <SignInForm>
          <Title>Sign In</Title>
          <FormRow>
            <Input value={email} onChange={setEmail} placeholder="email"/>
          </FormRow>
          <FormRow>
            <Input value={password} onChange={setPassword} type="password" placeholder="password"/>
          </FormRow>
          <FormRow>
            <Text to="/reset-password">Forgot Password?</Text>
            <LoadingButton
              text="Log In"
              style={{ background: "#34ac8c" }}
              onClick={submit}
              loading={loading}
              setLoading={setLoading}
            />
          </FormRow>
        </SignInForm>
      </LeftSide>
      <RightSide>
        <Logo src="/logo-white-speckle.png" />
        <LogoTitle>ROAMR</LogoTitle>
      </RightSide>
    </Container>
  )
}

export default HomeScreen;