import { Game } from "../Types/games";

export default (state: Game, action: { type: string, payload: any }) => {
  switch(action.type){
    case "SET_GAME":
      return action.payload;  
    case "UPDATE_GAME":
      return { ...state, ...action.payload };
    case "ADD_CLUE":
      return { ...state, clues: [...state.clues, action.payload] }
    case "UPDATE_CLUE":
      const clues = state.clues.map(clue => {
        if(clue.id === action.payload.id){
          return action.payload;
        }
        return clue;
      })
      return { ...state, clues }
    case "DELETE_CLUE":
      const newClues = state.clues.filter(clue => clue.id !== action.payload);
      return { ...state, clues: newClues }

    default:
      return state;
  }
}