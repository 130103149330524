import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Button, IconButton, Popover } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import styled from 'styled-components';
import { Clue, Game } from '../../Types/games';
import Business from '../../Types/business';
import Select from '../Forms/Select';
import { getAllBusinesses } from '../../Firebase/businesses';
import { addNewClue, createNewGame } from '../../Firebase/games';
import useIsMounted from '../../Hooks/useIsMounted';
import LoadingButton from './LoadingButton';

const Container = styled.div`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 400px;
  background: white;
  border-radius: 5px;
  padding: 20px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const Title = styled.h3`
  text-align: center;
  color: #222;
  font-weight: bold;
`;

const SubTitle = styled.p`
  color: #4b4b4b;
  text-align: center;
`;

const Span = styled.span`
  font-size: 1.2rem;
  color: #34ac8c;
`;

// const MUIModal = styled(Modal)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

interface Props {
  game: Game
}

const CopyGame: React.FC<Props> = ({ game }) => {
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [business, setBusiness] = useState<null | string>();
  const [businesses, setBusinesses] = useState<null | Business[]>();
  const mounted = useIsMounted();

  const history = useHistory();
  const params = useParams() as { businessId: string, id: string };  

  useEffect(() => {
    const getBusinesses = async () => {
      const res = await getAllBusinesses() as any;
      if(!res.error && mounted){
        setBusinesses(res);
      }
    }

    getBusinesses();
  }, []);

  useEffect(() => {
    if(businesses && businesses.length){
      setBusiness(businesses[0].id);
    }
  }, [businesses])

  const handleClick = async () => {
    if(business && businesses){
      const busi = businesses.find(b => b.id === business);
      
      if (busi){
        const data = {
          ...game,
          business: busi.id,
          businessName: busi.name,
          id: '',
          clues: []
        };

        const res = await createNewGame(data);
        if(!res.error){
          await Promise.all(game.clues.map(async (clue: Clue) => {
            await addNewClue(res.game.id, clue);
          }));

          setDialogOpen(false);
          alert('success');
          // history.push(`/businesses/${business}/games/${res.game.id}/main-information`);
        }

        if(mounted){
          setLoading(false);
        }
      }
    }

  }

  return (
    <>
      <IconButton
        onClick={() => setDialogOpen(true)}
      >
        <FileCopy style={{ color: '#34ac8c' }}/>
      </IconButton>
      {
        dialogOpen &&
        <Popover 
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          <Container>
            <Body>
              <Title>Copy a Game</Title>
              <SubTitle>Select the business to copy the game <Span>{ game.name }</Span> over to, this will create a brand new version that you can edit as much as you like!</SubTitle>
              {
                businesses && businesses.length > 0 &&
                <Select
                  value={business}
                  onChange={setBusiness}
                  options={businesses.map((business: Business) => ({ value: business.id, name: business.name }))}
                />
              }
            </Body>
            <LoadingButton
              text='Copy'
              onClick={handleClick}
              loading={loading}
              setLoading={setLoading}
            />
            {/* <Button
             variant='contained'
             style={{ background: '#34ac8c', color: 'white', fontWeight: 'bold', alignSelf: 'flex-end' }}
            >
              Copy
            </Button> */}
          </Container>
        </Popover>
      }
    </>
  )
}

export default CopyGame;