import React from "react";
import styled from "styled-components";
import Icon from "../Icons/Icon";
import { Checkbox } from "@material-ui/core";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: white;

  &:hover {
    cursor: pointer;
    background: #f8f8f8;
  }
`;

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckBox: React.FC<Props> = ({ checked, onChange }) => {
  const iconStyle = {
    display: "flex",
    alignItems: "center"
  }

  return (
    <div>
      <Checkbox 
        onClick={() => onChange(!checked)} 
        color="primary"
        checked={checked} 
      />
    </div>
    // <Container onClick={() => onChange(!checked)}>
    //   { checked ? <Icon style={iconStyle} name="check" size={20} color="#007bff" /> : null }
    // </Container>
  )
}

export default CheckBox;