import { useContext } from "react";
import { useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { Context as AuthContext, ContextType } from '../Context/authContext';

const useProtectedRoute = () => {
  // Only allow users that own this business, or are admin users
  const params = useParams() as { businessId: string };
  const { state: { user } } = useContext(AuthContext) as ContextType;
  return (params.businessId == user.business.id || user.role > 1);
}

export default useProtectedRoute;