import React from "react";
import styled from "styled-components";
import Input from "./Input";
import AddButton from "../Buttons/AddButton";
import RemoveButton from "../Buttons/RemoveButton";

const Container = styled.div``;

const InputRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

interface Props {
  value: any[];
  onChange: (value: any) => void;
}

const MultiInput: React.FC<Props> = ({
  value,
  onChange
}) => {
  const handleChange = (index: number, val: any): void => {
    onChange([
      ...value.slice(0, index),
      val,
      ...value.slice(index+1)
    ]);
  }

  const remove = (index: number): void => {
    onChange([
      ...value.slice(0, index),
      ...value.slice(index+1)
    ]);
  }

  const addNew = () => {
    onChange([...value, ""]);
  }

  return (
    <Container>
      {
        value.map((v,i) => {
          return (
            <InputRow key={i}>
              <Input value={v} onChange={val => handleChange(i,val)} />
              <RemoveButton onClick={() => remove(i)} />
            </InputRow>
          )
        })
      }
      <AddButton onClick={addNew} />
    </Container>
  )
}

export default MultiInput;