import React, { useState } from "react";
import styled from "styled-components";
import FlashMessage, { useFlashMessage } from "../../Components/FlashMessage/FlashMessage";
import Input from "../../Components/Forms/Input";
import LoadingButton from "../../Components/Buttons/LoadingButton";
import { resetPassword } from "../../Firebase/auth";
import { Link, useHistory } from "react-router-dom";
import { MEDIA_QUERIES } from "../../config/constants";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #5d6e1e;
  background: linear-gradient(135deg, #3b5284, #5ba8a0);
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: white;
  box-shadow: 2px 0 10px rgba(0,0,0,0.2);

  @media (min-width: ${MEDIA_QUERIES.SMALL}px) {
    width: 500px;
  }
`;

const SignInForm = styled.div``;

const Text = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 0.8rem;
  font-style: italic;
`;

const Title = styled.p`
  text-align: left;
  font-weight: bold;
  font-size: 1.2rem;
`;

const FormRow = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HomeScreen: React.FC = () => {
  const history = useHistory();
  const flash = useFlashMessage();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    const res = await resetPassword(email);
    if(res.error){
      flash.showFlashMessage(res.message, true);
    } else {
      const flashMessage = {
        error: false,
        text: "Reset email has been sent to " + email
      }
      history.push("/", { flashMessage })
    }
    setLoading(false);
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage { ...flash } /> }
      <LeftSide>
        <SignInForm>
          <Title>Reset Password</Title>
          <FormRow>
            <Input value={email} onChange={setEmail} placeholder="email"/>
          </FormRow>
          <FormRow>
            <Text to="/">Sign In</Text>
            <LoadingButton
              text='submit'
              style={{ background: "#34ac8c" }}
              onClick={submit}
              loading={loading}
              setLoading={setLoading}
            />
          </FormRow>
        </SignInForm>
      </LeftSide>
    </Container>
  )
}

export default HomeScreen;