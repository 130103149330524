import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import GamesScreen from "../Screens/Games/GamesScreen";
import GameScreen from "../Screens/Games/GameScreen";
import NewGameScreen from "../Screens/Games/NewGameScreen";
import useProtectedRoute from "../Hooks/useProtectedRoute";

const GamesNavigator: React.FC = () => {
  const { path } = useRouteMatch();
  const authenticated = useProtectedRoute();
  
  if(!authenticated){
    return <Redirect to="/" />
  }

  return (
    <Switch>
      <Route exact path={path}>
        <GamesScreen />
      </Route>
      <Route path={`${path}/new`}>
        <NewGameScreen />
      </Route>
      <Route path={`${path}/:id`}>
        <GameScreen />
      </Route>
    </Switch>
  )
}

export default GamesNavigator;
