import React from "react";
import styled from "styled-components";
import { withStyles, Theme, createStyles, Select as RNSelect, MenuItem, FormControl, InputBase } from "@material-ui/core";


type Option = {
  name: string | number;
  value: string | number;
}

interface InputTypes {
  value: any;
  onChange: (value: any)  => void;
  options: Option[];
}

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ddd',
      fontSize: 16,
      padding: "15px 20px",
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      outline: "none",
      '&:focus': {
        borderRadius: 4,
        borderColor: '#776589',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }),
)(InputBase);

const Select: React.FC<InputTypes> = ({ 
  value, 
  onChange, 
  options
}) => {

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as any);
  };

  return (
    <FormControl>
      <RNSelect
        style={{ width: 250 }}
        value={value}
        input={<BootstrapInput />}
        onChange={handleChange}
      >
        {
          options.map(option => {
            return <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
          })
        }
      </RNSelect>
    </FormControl>
  )

}

// const Select: React.FC<InputTypes> = ({ 
//   value, 
//   onChange, 
//   options
// }) => {
//   const [open, setOpen] = useState(false);
//   const container = useRef<HTMLDivElement>(null);

//   const onClick = (event: React.MouseEvent, option: string | number): void => {
//     event?.stopPropagation();
//     onChange(option);
//     setOpen(false);
//   }

//   // const handleClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
//   //   event.stopPropagation();
//   //   if(container.current && !container.current.contains(event.target)){
//   //     setOpen(false);
//   //   }
//   // }

//   const currentOption = options.find(o => o.value === value);
  

//   return (
//     <div>
//     <Container ref={container} onClick={() => setOpen(true)}>
//       <Value>{ currentOption && currentOption.name }</Value>
//       <List open={open}>
//         {
//           options.map(option => <ListItem key={option.value} onClick={event => onClick(event, option.value)}>{ option.name }</ListItem>)
//         }
//       </List>
//     </Container>
//     </div>
//   )
// }

export default Select;