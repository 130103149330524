import createContext from "./createContext";
import { User } from "../Types/users";
import { logout as FBLogout } from "../Firebase/auth";
import Business from "../Types/business";
import { getBusinessById } from "../Firebase/businesses";

type Dispatch = ({ type, payload } : { type: string, payload?: any }) => void;

type State = {
  userId: string | null;
  user: User | null;
  business: Business | null;
}

const defaultState = {
  userId: null,
  user: null,
  business: null
}

const gameReducer = (state: State, action: any): State => {
  switch(action.type){   
    case "LOGOUT":
      return defaultState;
    case "ADD_USER_ID":
      return { ...state, userId: action.payload } ;
    case "ADD_USER":
      return { ...state, user: action.payload };
    case "ADD_BUSINESS":
      return { ...state, business: action.payload };
    default:
      return state;
  }
}

const addBusiness = (dispatch: Dispatch) => async (id: string) => {
  const res = await getBusinessById(id);
  if(!res.error){
    dispatch({ type: "ADD_BUSINESS", payload: res });
  }
}

const addUserId = (dispatch: Dispatch) => (id: string) => {
  dispatch({ type: "ADD_USER_ID", payload: id });
}

const addUser = (dispatch: Dispatch) => (user: User) => {
  dispatch({ type: "ADD_USER", payload: user });
}

const removeUser = (dispatch: Dispatch) => async () => {
  dispatch({ type: "LOGOUT" });
}

const actions = {
  addBusiness,
  addUserId,
  addUser,
  removeUser
}

export const { Provider, Context } = createContext(gameReducer, actions, defaultState);

export type ContextType = {
  state: any;
  addBusiness: (id: string) => void;
  addUserId: (id: string|null) => void;
  addUser: (user: User|null) => void;
  removeUser: () => void;
}